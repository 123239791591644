import React from "react";

export const DotStatus = ({color, size}) => {
    return (
        <span 
            className={`dot-status`} 
            style={{ width: size, height: size, backgroundColor: color }}
        >
        </span>
    );
}

export default DotStatus;