import React, { useState } from "react";

export const BoxShrinkable = ({className, title, tools, children}) => {
    const [show, setShow] = useState(true);
    
    return (
        <div className={`box-shrinkable ${className ?? ''} ${show ? 'open' : 'closed'}`}>
            {(title || tools) &&
                <div className="header">
                    <span>{title}</span>
                    <button onClick={() => setShow(!show)}>
                        <img src={ require('../../assets/images/svgs/arrow-dropdown.svg').default } />
                    </button>
                </div>
            }
            <div className="body">
                {children}
            </div>
        </div>
    );
}

export default BoxShrinkable;