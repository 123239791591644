import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Box from "../../components/Box";
import Button from "../../components/Button";
import InputTextarea from "../../components/InputTextarea";
import Table from "../../components/Table";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";
import PanelTemplate from "../../templates/Panel";
import ModalProductVariable from "./ModalProductVariable";

const globalVariables = {
    header: ['Nome', 'Tipo'],
    rows: [
        {
            data: [
                'Imposto', 'Porcentagem'
            ],
            to: '/company/list'
        }
    ]
};

const Calculation = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({header: [], rows: []});
    const [params, setParams] = useState([]);
    const [paramToEdit, setParamToEdit] = useState(null);
    const [createProductVariable, setCreateProductVariable] = useState(false);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    const [formulaPrice, setFormulaPrice] = useState('');
    const [formulaPriceId, setFormulaPriceId] = useState('');

    const [formulaCommission, setFormulaCommission] = useState('');
    const [formulaCommissionId, setFormulaCommissionId] = useState('');

    useEffect(() => {
        getParams();
        getUsers();
        getRoles();
    }, []);

    useEffect(() => {
        generateData();
    }, [params]);

    const getUsers = () => {
        api.get(`/list`).then(res => {
            setUsers(res.data.resources);
        })
    }

    const getRoles = () => {
        api.get(`/role`).then(res => {
            setRoles(res.data.resources);
        })
    }

    const getParams = () => {
        api.get('/param').then(res => {
            let toParams = [];
            res.data.resources.map(resource => {
                if(resource.type === 'price_calculation'){
                    setFormulaPrice(resource.value);
                    setFormulaPriceId(resource.id);
                    return;
                }
                if(resource.type === 'commission_calculation'){
                    setFormulaCommission(resource.value);
                    setFormulaCommissionId(resource.id);
                    return;
                }
                toParams.push(resource);
            })
            setParams(toParams);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Origem', 'Posição'];
        const rows = [];

        rows.push({
            data: [
                'Quantidade',
                'Sistema',
                ''
            ]
        });
        rows.push({
            data: [
                'Custo unitário',
                'Sistema',
                ''
            ]
        });
        rows.push({
            data: [
                'Gravação',
                'Sistema',
                ''
            ]
        });
        
        
        params.map(param => {
            rows.push({
                data: [
                    param.name,
                    'Personalizada',
                    param.position
                ],
                action: () => setParamToEdit(param)
            })
        });

        

        setData({header, rows});
    }

    const storeFormulaPrice = () => {
        setLoading(true);

        api.put(`/param/${formulaPriceId}`, {
            name: 'Cálculo do preço de venda',
            type: 'price_calculation',
            value: formulaPrice
        }).then(res => {
            toast.success('Fórmula do preço de venda salvo com sucesso');
            getParams();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const storeFormulaCommission = () => {
        setLoading(true);

        api.put(`/param/${formulaCommissionId}`, {
            name: 'Cálculo da comissão',
            type: 'commission_calculation',
            value: formulaCommission
        }).then(res => {
            toast.success('Fórmula da comissão salva com sucesso');
            getParams();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }
        

    return (
        <>
            {(paramToEdit !== null || createProductVariable) &&    
                <ModalProductVariable
                    close={() => {
                        setCreateProductVariable(false);
                        setParamToEdit(null);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    params={params}
                    getParams={getParams}
                    paramToEdit={paramToEdit}
                    users={users}
                    roles={roles}
                />
            }
            <PanelTemplate id="calculation">
                <div className="row">
                    <div className="col-6">
                        <Box
                            title={`Cálculo do preço de venda`}
                        >
                            <h2>Instruções</h2>
                            <p>Use apenas os seguintes caraceteres: +, -, /, *, (, ) <br />
                            Para utilizar variáveis, coloque-as entre colchetes. Exemplo: [Custo unitário]
                            </p>
                            <InputTextarea
                                label={`Fórmula`} 
                                value={formulaPrice} 
                                change={setFormulaPrice} 
                                rows={6}
                            />
                        </Box>
                        <Button type={`primary`} size={`small`} svg={`save-white`} loading={loading} text={`Salvar cálculo`} action={storeFormulaPrice} />
                        <Box
                            title={`Cálculo da comissão`}
                        >
                            <h2>Instruções</h2>
                            <p>Use apenas os seguintes caraceteres: +, -, /, *, (, ) <br />
                            Para utilizar variáveis, coloque-as entre colchetes. Exemplo: [Custo unitário] <br />
                            Você pode usar a variável [Preço de venda]</p>
                            <InputTextarea 
                                label={`Fórmula`}
                                value={formulaCommission} 
                                change={setFormulaCommission} 
                                rows={6} 
                            />
                        </Box>
                        <Button type={`primary`} size={`small`} svg={`save-white`} loading={loading} text={`Salvar cálculo`} action={storeFormulaCommission} />
                    </div>
                    <div className="col-6">
                        <Box
                            title={`Variáveis globais`}
                            className={`no-padding`}
                            // tools={<Button type={`secondary`} text={`Cadastrar variável`} size={`small`} svg={`plus-blue`} />}
                        >
                            <Table header={globalVariables.header} rows={globalVariables.rows} />
                        </Box>
                        <Box
                            title={`Variáveis do produto`}
                            className={`no-padding`}
                            tools={
                                <Button
                                    type={`secondary`}
                                    text={`Cadastrar variável`}
                                    size={`small`}
                                    svg={`plus-blue`}
                                    action={() => setCreateProductVariable(true)}
                                />
                            }
                        >
                            <Table header={data.header} rows={data.rows} loading={loading} />
                        </Box>
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default Calculation;