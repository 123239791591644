import React from "react";

export const InputRadio = ({label, options, className, value, change}) => {

    return (
        <div className={`input-radio-group ${className}`}>
            {label &&
                <label>{label}</label>
            }
            <div className="options">
                {options && options.map((option, index) => (
                    <button 
                        key={index}
                        className={`option${option.value === value ? ' active' : ''}`}
                        onClick={() => change(option.value)}
                    >
                        <div></div> {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default InputRadio;