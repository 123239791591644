import React, { useEffect, useState } from "react";
import InputColor from 'react-input-color';

export const InputColorComponent = ({label, change, value}) => {
    const [color, setColor] = useState({});

    useEffect(() => {
        if(color.hex){
            change(color.hex);
        }
    }, [color])

    return (
        <div className="input-group">
            {label &&
                <label>{label}</label>
            }
            <div className="input-color-content">
                <InputColor
                    initialValue={value ? value : '#000'}
                    onChange={setColor}
                    placement="right"
                />
            </div>
        </div>
    );
}

export default InputColorComponent;