import React from "react";
import InputCheckbox from "../InputCheckbox";

export const ButtonCheckbox = ({checked, onClick}) => {
    return (
        <button
            className="button-checkbox"
            onClick={onClick}
        >
            <InputCheckbox active={checked} />
        </button>
    );
}

export default ButtonCheckbox;