import React from "react";

import AuthTemplate from "../../templates/Auth";

import Input from "../../components/Input";
import Button from "../../components/Button";

export const PasswordRecovery = () => {
    return (
        <AuthTemplate>
            <Input />
            <Button />
            <Button />
        </AuthTemplate>
    );
}

export default PasswordRecovery;