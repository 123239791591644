import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";

export const Proposal = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [proposal, setProposal] = useState(null);

    useEffect(() => {
        getProposal();
    }, []);

    const getProposal = () => {
        setLoading(true);

        api.get(`/budget/proposal/${id}`).then(res => {
            getPdf(res.data.resource);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getPdf = (proposal) => {

        api.get(`/budget/proposal/${id}/pdf`, {
            responseType: 'blob'
        }).then(res => {
            const file = new Blob([res.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `Proposta ${proposal.budget.code} - ${proposal?.company?.trade_name}.pdf`;
            link.click();
            setTimeout(() => {
                navigate(-1);
            }, 200);
        }).catch(error => {
            console.log(error);
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            {proposal === null &&
                <Loading show={true} />
            }
        </>
    );
}

export default Proposal;