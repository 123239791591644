import React from "react";

export const PageFooter = ({children, spaceBetween}) => {
    return (
        <div className={`page-footer ${spaceBetween ? `space-between` : ''}`}>
            {children}
        </div>
    );
}

export default PageFooter;