import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import InputSelect from "../../components/InputSelect";
import { toast } from "react-toastify";
import BoxTabs from "../../components/BoxTabs";
import { ModalDelete } from "../../components/ModalDelete";


const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const typeOptions = [
    { label: 'Slider', value: 'slider' },
    { label: 'Pós slider', value: 'after_slider' },
    { label: 'Pós destaques', value: 'after_featured' },
    { label: 'Lançamentos', value: 'recents' }
];

const targetOptions = [
    { label: 'Não se aplica', value: null },
    { label: 'Nova aba', value: '_blank' },
    { label: 'Mesma aba', value: '_self' }
];

const positionOptions = [
    { label: '1ª posição', value: 1 },
    { label: '2ª posição', value: 2 },
    { label: '3ª posição', value: 3 },
    { label: '4ª posição', value: 4 },
    { label: '5ª posição', value: 5 }
];

const LogoList = () => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [logos, setLogos] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');

    const [imageBase64, setImageBase64] = useState('');
    const [imageName, setImageName] = useState('');

    const [logoToDelete, setLogoToDelete] = useState(null);

    useEffect(() => {
        getLogos();
    }, []);

    useEffect(() => {
        generateData();
    }, [logos]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getLogos = () => {
        api.get('/logo').then(res => {
            setLogos(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Imagem', 'Nome'];
        const rows = [];
        
        logos.map(logo => {
            const image = <img src={logo.image_url.small} />;
            rows.push({
                data: [
                    image,
                    logo.name,
                ],
                action: () => editLogo(logo)
            });
        });

        setData({header, rows});
    }

    const storeLogo = () => {
        setLoading(true);

        api.post(`/logo`, {
            name,
            image: imageBase64
        }).then(res => {
            getLogos();
            setShowCreate(false);
            toast.success('Logo cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editLogo = logo => {
        setId(logo.id);
        setName(logo.name);
        setImageBase64('');
        setImageName(logo.image_url.small);
        setShowEdit(true);
    }

    const updateLogo = () => {
        setLoading(true);

        api.put(`/logo/${id}`, {
            name,
            image: imageBase64
        }).then(res => {
            getLogos();
            setShowEdit(false);
            toast.success('Logo atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
        setImageBase64('');
        setImageName('');
    }

    const deleteLogo = () => {
        setLoading(true);

        api.delete(`/logo/${logoToDelete}`).then(res => {
            setLogoToDelete(null);
            setShowEdit(false);
            getLogos();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} logo`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setLogoToDelete(id)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storeLogo() : updateLogo()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} value={name} change={setName} />
                        </div>
                        <div className="col-6">
                            <InputFile 
                                label={`Imagem`} 
                                base64={imageBase64}
                                changeBase64={setImageBase64}
                                name={imageName}
                                changeName={setImageName}
                                description={`Tamanho ideal: 400px por 400px`}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={logoToDelete !== null}
                action={deleteLogo}
                close={() => setLogoToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="logo-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar logo`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default LogoList;