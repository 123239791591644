export const calculateCustomizationTotal = (quantity, cost, calculationPer, unitOrInterval) => {
    let thousands = quantity / 1000;
    thousands = Math.ceil(thousands);

    let total = 0;

    if(calculationPer === 'thousand'){
        total = cost * thousands;
    } else {
        if(unitOrInterval === 'interval'){
            total = cost;
        } else {
            total = cost * quantity;
        }
    }

    return total;
}