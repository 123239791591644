import React from "react";
import Box from "../../Box";

export const BoxMoney = ({text, value}) => {
    return (
        <Box>
          <div className="box-count">
            <span className="value">{value}</span>
            <span className="description">{text}</span>
          </div>
        </Box>
    )
}

export default BoxMoney;