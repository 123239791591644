import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";

const MultipleValueWithQuantity = ({param, productParams, setProductParams}) => {
    const [index, setIndex] = useState(null);

    useEffect(() => {
        let toIndex = getIndexFromObjectArray(productParams, 'id', param.id);
        setIndex(toIndex);
    }, [productParams]);

    const handleParamChange = (value, i) => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].values[i] = value;
            return toProductParams;
        });
    }

    return (
        <>
            {index !== null &&
                <>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>{param.name}</h2>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row">
                            {param.options.map((option, i) => (

                                <div key={i} className="col-3">
                                    <Input 
                                        label={option.description}
                                        change={value => handleParamChange(value, i)}
                                        value={productParams[index].values[i] ?? 0}
                                        mask={`convertToInt`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default MultipleValueWithQuantity;