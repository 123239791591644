import React, { useEffect, useState } from "react";
import Select from 'react-select';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
  
import Box from "../../Box";
  
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
  
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false
    },
  },
};
  
const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const selectOptions = [
    { value: 'approved', label: 'Aprovado' },
    { value: 'wating', label: 'Aguardando' },
    { value: 'pending', label: 'Pendente' }
];

export const BoxVerticalBarChart = ({totalByMonth, title}) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    getChartData();
  }, [totalByMonth]);

  const getChartData = () => {
    let labels = [];
    let data = [];
    totalByMonth.map(total => {
      labels.push(`${months[total.month - 1]} ${total.year}`);
      data.push(total.count);
    });

    let toChartData = {
      labels,
      datasets: [
        {
          label: '',
          data,
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    setChartData(toChartData);
  }
    return (
        <Box
          title={title}
          // tools={<Select options={selectOptions} />}
        >
          {chartData !== null &&
            <Bar options={options} data={chartData} />
          }
        </Box>
    );
}

export default BoxVerticalBarChart;