import React from "react";

export const DotColor = ({color, size, type, image}) => {
    type = type ?? 'hexadecimal';
    let background = image && type === 'image' ? `url(${image})` : color;
    return (
        <span 
            className={`dot-color`} 
            style={{ width: size, height: size, background }}
        >
        </span>
    );
}

export default DotColor;