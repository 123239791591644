import React, { useEffect, useState } from "react";
import moment from 'moment';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import axios from "axios";

import { ModalDelete } from "../../components/ModalDelete";
import { getToken, hasPermission } from "../../services/auth";
import Pagination from "../../components/Pagination";
import { getSubdomain } from "../../helpers/domain";
import { ModalCreateCompany } from "../../components/ModalCreateCompany";

export const CompanyList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');

    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateCompany, setShowCreateCompany] = useState(false);

    const [companyToDelete, setCompanyToDelete] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getCompanies();
    }, [currentPage,filters]);

    useEffect(() => {
        generateData();
    }, [companies]);

    const generateData = () => {
        let header = ['Código', 'Nome', 'CNPJ', 'Onde conheceu', 'Responsável', 'Cadastrado em', 'Atualizado em'];
        const rows = [];

        if(hasPermission('delete_company')){
            header.push('');
        }

        companies.map(company => {
            let buttons = [];
            let canEdit = true;

            if(company.type.includes('billing') && !hasPermission('company_billing')){
                canEdit = false;
            }
            if(company.type.includes('product') && !hasPermission('company_supplier')){
                canEdit = false;
            }
            if(company.type.includes('customization') && !hasPermission('company_supplier')){
                canEdit = false;
            }

            let canDelete = hasPermission('delete_company') && canEdit && company.company_integration === null;

            if(canDelete){
                buttons.push(
                    <Button type={`delete`} svg={`delete`} action={() => setCompanyToDelete(company.id)} />
                );
            }

            rows.push({
                data: [
                    company.code,
                    company.trade_name,
                    company.cnpj,
                    company.origin,
                    company?.admin?.name,
                    company.created_at ? moment(company.created_at).format('DD/MM/YYYY HH:mm') : '',
                    company.created_at ? moment(company.updated_at).format('DD/MM/YYYY HH:mm') : ''
                ],
                buttons,
                to: canEdit ? `/company/edit/${company.id}` : '#',
                id: canDelete ? company.id : null,
                disable: !canEdit
            })
        });

        setData({header, rows});
    }

    const getCompanies = () => {
        setCompanies([]);
        setLoading(true);

        if (typeof cancelToken != typeof undefined) {
            window.cancelToken.cancel()
        }
      
        window.cancelToken = axios.CancelToken.source();

        api.get(`/company/paginate?${filters}&page=${currentPage}`, {
            cancelToken: window.cancelToken.token
        }).then(res => {
            setCompanies(res.data.resources.data);
            setCurrentPage(res.data.resources.pagination.current_page);
            setLastPage(res.data.resources.pagination.last_page)
            setPerPage(res.data.resources.pagination.per_page)
            setTotalResults(res.data.resources.pagination.total)
            setHasNextPage(res.data.resources.pagination.next_page_url !== null);
            setHasPrevPage(res.data.resources.pagination.prev_page_url !== null);
        }).catch(error => {
            
        }).then(() => setLoading(false));
    }

    const deleteCompany = () => {
        setLoading(true);

        api.delete(`/company/${companyToDelete}`).then(res => {
            setCompanyToDelete(null);
            getCompanies();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteMultipleCompanies = () => {
        setLoading(true);

        api.delete(`/company/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            setSelectedIds([]);
            getCompanies();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        setCurrentPage(1);
        setFilters(value);
    }

    const exportCompanies = () => {
        window.open(`${process.env.REACT_APP_URL}/company/export?_token=${getToken()}&_subdomain=${getSubdomain()}`);
    }

    return (
        <>
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'empresas' : 'empresa'}</strong>?</p>}
                action={deleteMultipleCompanies}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <ModalDelete
                show={companyToDelete !== null}
                action={deleteCompany}
                loading={loading}
                close={() => setCompanyToDelete(null)}
            />
            <ModalCreateCompany
                show={showCreateCompany}
                close={() => setShowCreateCompany(false)}
                companyStored={company => navigate(`/company/edit/${company.id}`)}
            />
            <PanelTemplate id="company-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome da empresa', key: '[trade_name]', type: 'text' },
                                {name: 'Razão social', key: '[company_name]', type: 'text' },
                                {name: 'CNPJ', key: '[cnpj]', mask: 'convertToCnpj', type: 'text' },
                                {name: 'Código', key: '[code]', type: 'text' },
                                {name: 'Onde nos conheceu', key: '[origin]', type: 'text'}
                            ]}
                            filter={handleSetFilter}
                        />
                    }

                    right={
                        <>
                            {hasPermission('delete_company') && selectedIds.length > 0 &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                svg={`delete`}
                                text={`Excluir ${selectedIds.length} selecionados`} 
                                action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            {hasPermission('export_company') &&
                                <Button type={`secondary`} size={`small`} text={`Exportar empresas`} action={exportCompanies}  />
                            }
                            {hasPermission('create_company') &&
                                <Button type={`primary`} size={`small`} text={`Cadastrar empresa`} svg="plus-white" action={() => setShowCreateCompany(true)}  />
                            }
                        </>
                    }
                />
                
                <Box className={`no-padding with-pagination`}>
                    <Table 
                        header={data.header} 
                        rows={data.rows} 
                        loading={loading} 
                        checks={true} 
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                    />
                </Box>
                {!loading &&
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        totalResults={totalResults}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </PanelTemplate>
        </>
    );
}

export default CompanyList;