import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import BoxMoney from "../../components/dashboard/BoxMoney";
import BoxDoughnutChart from "../../components/dashboard/BoxDoughnutChart";
import BoxCount from "../../components/dashboard/BoxCount";
import { Filter } from "../../components/dashboard/Filter";
import Table from "../../components/Table";
import Box from "../../components/Box";
import BoxVerticalBarChart from "../../components/dashboard/BoxVerticalBarChart";
import Modal from "../../components/Modal";
import api from "../../services/api";
import { convertIntToMoney } from "../../helpers/conversions";
import moment from "moment";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { hasPermission } from "../../services/auth";
import InputDate from "../../components/InputDate";
import Button from "../../components/Button";
import InputCheckboxes from "../../components/InputCheckboxes";
import { createOptions } from "../../helpers/selects";

const options = [
    { value: 'approved', label: 'Aprovado' },
    { value: 'wating', label: 'Aguardando' },
    { value: 'pending', label: 'Pendente' }
];

export const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [loadingStatuses, setLoadingStatuses] = useState(true);

    const [type, setType] = useState('budget');

    const date = new Date();
    const sDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const fDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const [startDate, setStartDate] = useState(moment(sDate).format('YYYY-MM-DD'));
    const [finalDate, setFinalDate] = useState(moment(fDate).format('YYYY-MM-DD'));

    const [startChartDate, setStartChartDate] = useState(sDate);
    const [finalChartDate, setFinalChartDate] = useState(fDate);
    const [selectedChartStatuses, setSelectedChartStatuses] = useState([]);

    const [amountBudgets, setAmountBudgets] = useState(0);
    const [averageTicket, setAverageTicket] = useState(0);
    const [averageItems, setAverageItems] = useState(0);
    const [totalProposalsToday, setTotalProposalsToday] = useState(0);
    const [averageTimeToday, setAverageTimeToday] = useState(0);
    const [averageTimeMonth, setAverageTimeMonth] = useState(0);

    const [totalByStatus, setTotalByStatus] = useState([]);
    const [totalByAdmin, setTotalByAdmin] = useState([]);
    const [totalByMonth, setTotalByMonth] = useState([]);

    const [adminData, setAdminData] = useState({header: [], rows: []});
    const [items, setItems] = useState([]);

    const [orderStatuses, setOrderStatuses] = useState([]);
    const [budgetStatuses, setBudgetStatuses] = useState([]);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        getStatuses();
    }, []);

    const getStatuses = () => {
        api.get(`/status`).then(res => {
            setOrderStatuses(res.data.resources.filter(status => status.type === 'order'));
            setBudgetStatuses(res.data.resources.filter(status => status.type === 'budget'));
        });
    }

    useEffect(() => {
        if(orderStatuses.length > 0 && budgetStatuses.length > 0){
            handleTypeChange();
        }
    }, [type, orderStatuses, budgetStatuses]);

    const handleTypeChange = () => {
      let toStatuses = [];
      let toSelectedStatuses = [];

      if(type === 'budget'){
        toStatuses = budgetStatuses;
      } else {
        toStatuses = orderStatuses;
      }

      toStatuses.map(status => toSelectedStatuses.push(status.id));
      
      setSelectedChartStatuses(toSelectedStatuses);
      setStatuses(toStatuses);
      getData(true);   
    }

    useEffect(() => {
        getAdminData();
    }, [totalByAdmin]);

    const getData = (typeChange = false) => {
        setLoading(true);

        api.get(`/dashboard`, {
            params: {
                start_date: startDate,
                final_date: finalDate,
                start_chart_date: moment(startChartDate).format('YYYY-MM-DD'),
                final_chart_date: moment(finalChartDate).format('YYYY-MM-DD'),
                chart_statuses: typeChange ? [] : selectedChartStatuses,
                type: type
            }
        }).then(res => {
            const { data } = res;
            console.log('data', data);
            setAmountBudgets(data[type].amount_resources);
            setAverageTicket(data[type].average_ticket);
            setAverageItems(data[type].average_items);

            setTotalByStatus(data[type].resources_by_status);
            setTotalByAdmin(data[type].resources_by_admin);
            setTotalByMonth(data[type].resources_by_month);

            
            setItems(generateData(data[type].items));

            if(type === 'budget'){
                setTotalProposalsToday(data[type].total_proposals_today);
                setAverageTimeToday(data[type].average_time_today);
                setAverageTimeMonth(data[type].average_time_month);
            }

            setLoading(false);
        });
    }

    const generateData = items => {
        let rows = [];
        items.map(item => rows.push({ data: [`${item.sku} - ${item.name}`, item.count] }));
        return rows;
    }

    const getAdminData = () => {
        let header = ['Nome do vendedor', `Num. de ${type === 'budget' ? 'orçamentos' : 'pedidos'}`, 'Total'];
        if(type === 'order' && hasPermission('view_margem_order')){
            header.push('Margem real');
        }
        const rows = [];
        
        totalByAdmin.map(admin => {
            let data = [
                admin.admin.name,
                admin.total_resources,
                `R$ ${convertIntToMoney(admin.amount_resources)}`
            ];
            if(type === 'order' && hasPermission('view_margem_order')){
                data.push(`R$ ${convertIntToMoney(admin.amount_margem_real)}`);
            }
            rows.push({
                data,
                to: `/${type === 'budget' ? 'quote' : 'order'}/list`
            })
        });

        setAdminData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="dashboard">
                <ul className="filter-tab">
                    <li><a href="#" className={type === 'budget' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setType('budget');
                    }}>Orçamentos</a></li>
                    <li><a href="#" className={type === 'order' ? 'active' : ''} onClick={e => {
                        e.preventDefault();
                        setType('order');
                    }}>Pedidos</a></li>
                </ul>
                <div className="row">
                    <div className="col-6">
                        <Box>
                            <div className="row">
                                <div className="col-6">
                                    <InputDate label={`Data inicial`} change={setStartChartDate} value={startChartDate} />
                                    <InputDate label={`Data final`} change={setFinalChartDate} value={finalChartDate} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <InputCheckboxes hideBorder={true} options={createOptions(statuses, 'name', 'id')} selecteds={selectedChartStatuses} setSelecteds={setSelectedChartStatuses} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Button text={`Atualizar dashboard`} loading={loading} action={getData} />
                                </div>
                            </div>
                        </Box>
                    </div>
                    <div className="col-6">
                        {statuses.length > 0 &&
                            <BoxDoughnutChart 
                                type={type}
                                loading={loading}
                                getData={getData}
                                totalByStatus={totalByStatus}
                                startChartDate={startChartDate}
                                setStartChartDate={setStartChartDate}
                                finalChartDate={finalChartDate}
                                setFinalChartDate={setFinalChartDate}
                                selectedChartStatuses={selectedChartStatuses}
                                setSelectedChartStatuses={setSelectedChartStatuses}
                                statuses={statuses}
                            />
                        }
                    </div>
                    {!loading && type === 'budget' &&
                        <div className="row">
                            <div className="col-4">
                                <BoxCount
                                    text={<>Orçamentos enviados hoje<br />sem filtro de status</>}
                                    value={totalProposalsToday}
                                />
                            </div>
                            <div className="col-4">
                                <BoxCount
                                    text={<>Tempo médio de resposta hoje<br />sem filtro de status</>}
                                    value={`${averageTimeToday} min.`}
                                />
                            </div>
                            <div className="col-4">
                                <BoxCount
                                    text={<>Tempo médio de resposta no período<br />sem filtro de status</>}
                                    value={`${averageTimeMonth} min.`}
                                />
                            </div>
                            <div className="col-4">
                                <BoxMoney
                                    text={`${type === 'budget' ? 'Orçamentos' : 'Pedidos'} realizados no período`}
                                    value={amountBudgets}
                                />
                            </div>
                            <div className="col-4">
                                <BoxMoney
                                    text={`Ticket médio dos ${type === 'budget' ? 'orçamentos' : 'pedidos'} do período`}
                                    value={averageTicket}
                                />
                            </div>
                            <div className="col-4">
                                <BoxCount
                                    text={`Número médio de itens nos ${type === 'budget' ? 'orçamentos' : 'pedidos'}`}
                                    value={averageItems}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col">
                        <Box 
                            className={`no-padding`}
                            title={`Produtos mais ${type === 'budget' ? 'orçados' : 'pedidos'} no período`}
                            // tools={<Select options={options} />}
                        >
                            <Table loading={loading} header={['Produto', `Quant. de ${type === 'budget' ? 'orçamentos' : 'pedidos'}`]} rows={items} />
                        </Box>
                    </div>
                    <div className="col">
                        <Box 
                            className={`no-padding`}
                            title={`Desempenho dos vendedores no período`}
                            // tools={<Select options={options} />}
                        >
                            <Table loading={loading} header={adminData.header} rows={adminData.rows} />
                        </Box>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <BoxVerticalBarChart totalByMonth={totalByMonth} title={`${type === 'budget' ? 'Orçamentos' : 'Pedidos'} nos últimos meses`}  />
                    </div>
                </div>
            </PanelTemplate>
        </>
    );
}

export default Dashboard;