import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createStatusOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectStatus = ({selectedStatus, setSelectedStatus, type, className, updating}) => {
    const [loading, setLoading] = useState(true);

    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        getStatuses();
    }, []);

    const getStatuses = () => {
        setLoading(true);

        let filter = type ? `?filters[0][type][equal]=${type}` : '';

        api.get(`/status${filter}`).then(res => {
            setStatuses(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <div className={className ?? ''}>
            <InputSelect
                loading={loading || updating === true}
                label={`Status`}
                options={createStatusOptions(statuses)} 
                value={selectedStatus} 
                change={setSelectedStatus}
            />
        </div>
    );
}

export default SelectStatus;