import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import InputSelect from "../../components/InputSelect";
import { toast } from "react-toastify";
import BoxTabs from "../../components/BoxTabs";
import InputTextarea from "../../components/InputTextarea";
import InputCheckbox from "../../components/InputCheckbox";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import { ModalDelete } from "../../components/ModalDelete";
import { createOptions } from "../../helpers/selects";

const RoleList = () => {
    const navigate = useNavigate();
    
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [data, setData] = useState({header: [], rows: []});
    const [permissionGroups, setPermissionGroups] = useState([]);

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [idToDelete, setIdToDelete] = useState(null);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    useEffect(() => {
        getRoles();
        getPermissions();
    }, []);

    useEffect(() => {
        generateData();
    }, [roles]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getRoles = () => {
        setLoading(true);

        
        api.get('/role').then(res => {
            setRoles(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getPermissions = () => {
        setLoadingPermissions(true);

        api.get('/permission').then(res => {
            setPermissionGroups(groupPermissions(res.data.resources));
        }).catch(error => {
            renderError(error);
        }).then(() => setLoadingPermissions(false));
    }

    const generateData = () => {
        const header = ['Nome'];
        const rows = [];
        
        roles.map(role => {
            rows.push({
                data: [
                    role.name
                    // `${role.permissions.length} permissões`
                ],
                action: () => editRole(role)
            });
        });

        setData({header, rows});
    }

    const storeRole = () => {
        setLoading(true);

        api.post(`/role`, {
            name,
            permissions: selectedPermissions
        }).then(res => {
            getRoles();
            setShowCreate(false);
            toast.success('Nível de acesso cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editRole = role => {
        setId(role.id);
        setName(role.name);
        let toSelectedPermissions = [];
        role.permissions.map(permission => toSelectedPermissions.push(permission.id));
        setSelectedPermissions(toSelectedPermissions);
        setShowEdit(true);
    }

    const updateRole = () => {
        setLoading(true);

        api.put(`/role/${id}`, {
            name,
            permissions: selectedPermissions
        }).then(res => {
            getRoles();
            setShowEdit(false);
            toast.success('Nível de acesso atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const confirmDeleteRole = () => {
        setIdToDelete(id);
        setShowEdit(false);
    }

    const deleteRole = () => {
        setLoading(true);

        api.delete(`/role/${idToDelete}`).then(res => {
            setIdToDelete(null);
            getRoles();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
    }

    const groupPermissions = permissions => {
        let groups = [];
        permissions.map(permission => {
            let index = groups.findIndex(group => group.name === permission.group);
            if(index === -1){
                groups.push({
                    name: permission.group,
                    permissions: [permission]
                });
            } else {
                groups[index].permissions.push(permission);
            }
        });

        return groups;
    }

    const handleChangePermission = permissionId => {
        let toSelectedPermissions = [];
        if(selectedPermissions.includes(permissionId)){
            toSelectedPermissions = selectedPermissions.filter(selectedPermission => selectedPermission !== permissionId);
        } else {
            toSelectedPermissions = [...selectedPermissions, permissionId];
        }

        setSelectedPermissions(toSelectedPermissions);
    }

    return (
        <>
            <ModalDelete
                close={() => setIdToDelete(null)}
                show={idToDelete !== null}
                action={deleteRole}
                loading={loading}
            />
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} nível de acesso`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDeleteRole()}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storeRole() : updateRole()}
                            loading={loading} 
                        />
                        
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} value={name} change={setName} autoComplete={`new-password`} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {loadingPermissions &&
                                <p>Carregando permissões...</p>
                            }
                            {permissionGroups.map((group, indexGroup) => (
                                <div key={indexGroup} className="permission-group">
                                    <h2>{group.name}</h2>
                                    {group.permissions.map((permission, indexPerm) => (
                                        <button key={indexPerm} onClick={() => handleChangePermission(permission.id)}>
                                            <InputCheckbox active={selectedPermissions.includes(permission.id)}/>
                                            {permission.label}
                                        </button>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
            <PanelTemplate id="role-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar nível de acesso`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default RoleList;