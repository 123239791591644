import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import Box from "../../components/Box";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import { renderError } from "../../helpers/errors";
import InputEditor from "../../components/InputEditor";
import { Loading } from "../../components/Loading";
import InputTextarea from "../../components/InputTextarea";
import { toast } from "react-toastify";

export const PageEdit = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(null);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');

    useEffect(() => {
        getPage();
    }, []);

    const getPage = () => {
        setLoading(true);

        api.get(`/page/${id}`).then(res => {
            let resource = res.data.resource;

            setPage(resource);
            setTitle(resource.title);
            setText(resource.text);
            setSlug(resource.slug);
            setMetaTitle(resource.meta_title);
            setMetaDescription(resource.meta_description);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updatePage = () => {
        setLoading(true);

        api.put(`/page/${id}`, {
            title,
            text,
            slug,
            meta_title: metaTitle,
            meta_description: metaDescription
        }).then(res => {
            getPage();
            toast.success('Página atualizada com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <PanelTemplate id="page-edit">
                <Box>
                    {!loading &&
                        <>
                            <Input label={`Título`} value={title} change={setTitle} />
                            <Input label={`Slug`} value={slug} disabled="true" />
                            <Input label={`Meta title`} value={metaTitle} change={setMetaTitle} />
                            <InputTextarea label={`Meta description`} value={metaDescription} change={setMetaDescription} rows={4} />
                            <h2 className="section-title">Conteúdo da página</h2>
                            <InputEditor value={text} change={setText} />
                        </>
                    }
                    {loading &&
                        <Loading />
                    }
                </Box>
                <PageFooter>
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Salvar alterações`}
                        action={updatePage}
                        loading={loading}
                    />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default PageEdit;