import React from "react";
import InputRadio from "../../../../components/InputRadio";
import Extra from "./Extra";
import MultiplePercentage from "./MultiplePercentage";
import MultipleValue from "./MultipleValueSection";
import MultipleValueWithQuantity from "./MultipleValueWithQuantity";
import Percentage from "./Percentage";
import QuantityAndPrice from "./QuantityAndPrice";

const fotolitos =  [
    {value: 1, label: '1%'},
    {value: 1, label: '1%'},
    {value: 1, label: '1%'},
];

const ParamSection = ({param, productParams, setProductParams}) => {
    return (
        <>
            {param.type === 'multiple_value' &&
                <MultipleValue
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                />
            }

            {param.type === 'multiple_percentage' &&
                <MultiplePercentage
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                />
            }

            {param.type === 'percentage' &&
                <Percentage
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                />
            }

            {param.type === 'multiple_value_with_quantity' &&
                <MultipleValueWithQuantity
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                />
            }

            {param.type === 'quantity_and_price' &&
                <QuantityAndPrice
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                />
            }

            {param.type === 'extra' &&
                <Extra
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                />
            }
        </>
    );
}

export default ParamSection;