import React, { useEffect, useState } from "react";
import InputCheckbox from "../InputCheckbox";

export const InputCheckboxes = ({options, selecteds, setSelecteds, hideBorder}) => {
    const handleChange = option => {
        if(selecteds.includes(option.value)){
            setSelecteds(prev => prev.filter(v => v !== option.value));
        } else {
            setSelecteds(prev => [...prev, option.value]);
        }
    }

    useEffect(() => {
        console.log('selecteds: ', selecteds)
    }, [selecteds])

    return (
        <div className={`input-checkboxes-group ${hideBorder ? '' : 'bordered'}`}>
            <div className="options">
                {options.map((option, index) => (
                    <button 
                        key={index}
                        className="option"
                        onClick={() => handleChange(option)}
                    >
                        {selecteds.includes &&
                        <InputCheckbox 
                        active={selecteds.includes(option.value)} 
                        />
                    }
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default InputCheckboxes;