import React from "react";
import IconClose from '../../assets/images/svgs/close.svg';
import Button from "../Button";

export const Modal = ({className, title, show, close, children, footer, size}) => {
    return (
        <div className={`modal ${show === true ? 'show' : 'hide'} ${className ? className : ''}`}>
            <div className={`content  ${size ?  size : 'regular'}`}>
                <div className="header">
                    <span>{title}</span>
                    <Button type="transparent" svg={`close`} action={() => close()}/>
                </div>
                <div className="body">
                    {children}
                </div>
                {footer &&
                    <div className="footer">
                        {footer}
                    </div>
                }
            </div>
        </div>
    );
}

export default Modal;