import React, { useEffect, useState } from "react";
import PanelTemplate from "../../../templates/Panel";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { renderError } from "../../../helpers/errors";
import Button from "../../../components/Button";
import BoxShrinkable from "../../../components/BoxShrinkable";
import Input from "../../../components/Input";
import InputSelect from "../../../components/InputSelect";
import temperatureOptions from "../../../seeds/crm/temperatures";
import BoxTabs from "../../../components/BoxTabs";
import { toast } from "react-toastify";
import { getLabelFromOptions } from "../../../helpers/selects";
import moment from "moment";
import { convertDateToRelativeTime, convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import InputDate from "../../../components/InputDate";
import origins from "../../../seeds/origins";
import segmentsOptions from "../../../seeds/segments";
import employeeRangesOptions from "../../../seeds/employeeRanges";
import SelectCompany from "../../../components/selects/SelectCompany";
import SelectCustomer from "../../../components/selects/SelectCustomer";
import positionsOptions from "../../../seeds/positions";
import departmentsOptions from "../../../seeds/departments";
import CRMTimeline from "./Timeline";
import CRMTasks from "./Tasks";
import statusOptions from "../../../seeds/crm/status";
import { ModalDelete } from "../../../components/ModalDelete";

export const CRMCard = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const [gettingCard, setGettingCard] = useState(true);
    const [updatingCard, setUpdatingCard] = useState(false);
    const [card, setCard] = useState(undefined);
    
    const [gettingCompany, setGettingCompany] = useState(true);
    const [company, setCompany] = useState(undefined);

    const [gettingCustomer, setGettingCustomer] = useState(true);
    const [customer, setCustomer] = useState(undefined);

    const [confirmDelete, setConfirmDelete] = useState(false);
    

    useEffect(() => {
        if(id){
            getCard();
        }
    }, [id]);

    useEffect(() => {
        if(card){
            getCompany();
        }
    }, [card?.company?.id]);

    useEffect(() => {
        if(card){
            getCustomer();
        }
    }, [card?.customer?.id]);

    const getCustomer = () => {
        if(card?.customer?.id){
            setGettingCustomer(true);
            api.get(`/customer/${card.customer.id}`).then(res => {
                setCustomer(res.data.resource);
                setGettingCustomer(false);
            });
        } else {
            setCustomer(null);
            setGettingCustomer(false);
        }
    }

    const getCompany = () => {
        if(card?.company?.id){
            setGettingCompany(true);
            api.get(`/company/${card.company.id}`).then(res => {
                setCompany(res.data.resource);
                setGettingCompany(false);
            });
        } else {
            setCompany(null);
            setGettingCompany(false);
        }
    }

    const getCard = () => {
        setGettingCard(true);

        api.get(`/pipeline-stage-card/${id}`).then(res => {
            setCard(res.data.resource);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingCard(false));
    }

    const updateCardParam = async (param, value) => {
        setUpdatingCard(true);

        if(param === 'amount'){
            value = convertMoenyToInt(value);
        }

        let updatedCard = {
            ...card,
            [param]: value
        };

        return api.put(`/pipeline-stage-card/${card.id}`, updatedCard).then(res => {
            setCard(res.data.resource);
            return true;
        }).catch(error => {
            renderError(error);
            return false;
        }).then(result => {
            setUpdatingCard(false);
            return result;
        });
    }

    const updateCompanyParam = async (param, value) => {
        let updatedCompany = {
            ...company,
            [param]: value
        };

        return api.put(`/company/${company.id}`, updatedCompany).then(res => {
            setCompany(res.data.resource);
            return true;
        }).catch(error => {
            renderError(error);
            return false;
        });
    }

    const updateCustomerParam = async (param, value) => {
        let updatedCustomer = {
            ...customer,
            [param]: value
        };

        return api.put(`/customer/${customer.id}`, updatedCustomer).then(res => {
            setCustomer(res.data.resource);
            return true;
        }).catch(error => {
            renderError(error);
            return false;
        });
    }

    const deleteCard = () => {
        setUpdatingCard(true);

        api.delete(`/pipeline-stage-card/${card.id}`).then(res => {
            navigate('/crm');
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdatingCard(false))
    }

    return (
        <PanelTemplate id="crm-card">
            <ModalDelete
                show={confirmDelete}
                action={deleteCard}
                close={() => setConfirmDelete(false)}
                loading={updatingCard}
            />
            {card &&
                <div className="header">
                    <div className="stages">
                        {card.stages.map((stage, index) => (
                            <button 
                                key={index} 
                                className={`stage${stage.is_current ? ' active' : ''}`}
                                onClick={() => updateCardParam('pipeline_stage_id', stage.id)}
                            >
                                <div className="stage-info">
                                    <span>{stage.name}</span>
                                    <span>{stage.days_in_stage} dia{stage.days_in_stage === 1 ? '' : 's'}</span>
                                </div>
                            </button>
                        ))}
                    </div>
                    <div className="stage-infos">
                        {card.status !== 'open' &&
                            <div className="card-status">
                                Status do card <span className={card.status}>{getLabelFromOptions(statusOptions, card.status)}</span>
                            </div>
                        }
                        {card.status === 'open' &&
                            <div className="temperature-info">
                                {/* Oportunidade de conclusão da venda <span className={card.temperature}>{getLabelFromOptions(temperatureOptions, card.temperature)}</span> */}
                            </div>
                        }
                        <div className="card-actions">
                            {updatingCard &&
                                <span>Carregando...</span>
                            }
                            {!updatingCard &&
                                <Button 
                                    type={`link`} 
                                    size={`small`}
                                    svg={`delete`} 
                                    loading={updatingCard} 
                                    action={() => setConfirmDelete(true)}
                                />
                            }
                            {!updatingCard && card.status === 'open' &&
                                <>
                                    <Button
                                        type={`primary`}
                                        text={`Ganho`}
                                        size={`small`}
                                        style={{ backgroundColor: 'green'}}
                                        action={() => updateCardParam('status', 'won')}
                                        loading={updatingCard}
                                    />
                                    <Button
                                        type={`primary`}
                                        text={`Perdido`}
                                        size={`small`}
                                        style={{ backgroundColor: '#a1a1a9'}}
                                        action={() => updateCardParam('status', 'lost')}
                                        loading={updatingCard}
                                    />
                                    {/* <Button
                                        type={`primary`}
                                        text={`Congelado`}
                                        size={`small`}
                                        style={{ backgroundColor: '#3367B7'}}
                                        action={() => updateCardParam('status', 'frozen')}
                                        loading={updatingCard}
                                    /> */}
                                </>
                            }
                            {!updatingCard && card.status !== 'open' &&
                                <Button
                                    type={`primary`}
                                    text={`Reabrir card`}
                                    size={`small`}
                                    action={() => updateCardParam('status', 'open')}
                                />
                            }
                        </div>
                    </div>
                </div>
            }
            {card &&
                <div className="body">
                    <div className="row">
                        <div className="col-4">
                            <BoxShrinkable title={`Detalhes da oportunidade`}>
                                <InputPreview 
                                    label={`Código da oportunidade`} 
                                    previewValue={card.code}
                                />
                                {/* <InputPreview 
                                    type="select"
                                    label={`Temperatura`} 
                                    previewValue={getLabelFromOptions(temperatureOptions, card.temperature)}
                                    value={card.temperature}
                                    change={async value => updateCardParam('temperature', value)}
                                    options={temperatureOptions}
                                /> */}
                                {card.budget &&
                                    <InputPreview 
                                        label={'Orçamento'} 
                                        previewValue={card.budget.code}
                                        link={`/quote/edit/${card.budget.id}`}
                                    />
                                }
                                <InputPreview 
                                    label={card.budget ? `Total do orçamento` : `Previsão do preço final`} 
                                    previewValue={convertIntToMoney(card.amount, true)}
                                    link={card.budget ? `/quote/edit/${card.budget.id}` : null}
                                    value={card.budget ? null : card.amount}
                                    change={card.budget ? null : async value => updateCardParam('amount', value)}
                                    mask={card.budget ? null : 'convertToMoney'}
                                />
                                <InputPreview 
                                    type="date"
                                    label={`Previsão de fechamento`} 
                                    previewValue={card.closing_date ? convertDateToRelativeTime(card.closing_date) : 'Não informada'}
                                    value={card.closing_date}
                                    change={async value => updateCardParam('closing_date', value)}
                                />
                                
                                <InputPreview 
                                    label={`Data de cadastro`} 
                                    previewValue={convertDateToRelativeTime(card.created_at, true)}
                                />
                                <InputPreview 
                                    label={`Última atualização`} 
                                    previewValue={convertDateToRelativeTime(card.updated_at, true)}
                                />
                                {/* <InputPreview 
                                    label={`Origem`} 
                                    previewValue={getLabelFromOptions(origins, card.origin)}
                                />
                                 */}
                                {/* <InputSelect options={temperatureOptions} value={card.temperature} isDisabled={true} /> */}
                            </BoxShrinkable>

                            <BoxShrinkable title={<>Empresa {company && <small>{company.filled_percentage}</small>}</>}>
                                <SelectCompany 
                                    key={company?.updated_at ?? 'company'}
                                    selectedCompany={card?.company?.id ?? null} 
                                    setSelectedCompany={value => updateCardParam('company_id', value)} 
                                />
                                {(gettingCompany || gettingCard) &&
                                    <p>Carregando dados...</p>
                                }
                                {!gettingCompany && !gettingCard && company &&
                                    <>
                                        <InputPreview 
                                            label={`Nome da empresa`}
                                            previewValue={company.trade_name}
                                            value={company.trade_name}
                                            change={async value => updateCompanyParam('trade_name', value)} 
                                        />
                                        <InputPreview 
                                            label={`Razão social`}
                                            previewValue={company.company_name}
                                            value={company.company_name}
                                            change={async value => updateCompanyParam('company_name', value)} 
                                        />
                                        {/* <InputPreview 
                                            type="select"
                                            label={`Segmento`}
                                            previewValue={getLabelFromOptions(segmentsOptions, company.segment)}
                                            value={company.segment}
                                            change={async value => updateCompanyParam('segment', value)} 
                                            options={segmentsOptions}
                                        />
                                        <InputPreview 
                                            type="select"
                                            label={`Número de funcionários`}
                                            previewValue={getLabelFromOptions(employeeRangesOptions, company.employee_range)}
                                            value={company.employee_range}
                                            change={async value => updateCompanyParam('employee_range', value)} 
                                            options={employeeRangesOptions}
                                        />
                                        <InputPreview
                                            label={`Website`}
                                            previewValue={<a href={company.website} target="_blank">{company.website}</a>}
                                            value={company.website}
                                            change={async value => updateCompanyParam('website', value)} 
                                        /> */}
                                        <InputPreview 
                                            label={`Telefone`}
                                            previewValue={company.phone}
                                            value={company.phone}
                                            change={async value => updateCompanyParam('phone', value)} 
                                            mask={`convertToPhone`}
                                        />

                                        <Link style={{ display: "inline-block", marginTop: 15, fontSize: 14}} to={`/company/edit/${company.id}`}>Mais detalhes</Link>
                                    </>
                                }
                            </BoxShrinkable>
                            <BoxShrinkable title={<>Pessoa {customer && <small>{customer.filled_percentage}</small>}</>}>
                                <SelectCustomer 
                                    key={customer?.updated_at ?? 'customer'}
                                    selectedCustomer={card?.customer?.id ?? null} 
                                    setSelectedCustomer={value => updateCardParam('customer_id', value)} 
                                    companyId={null}
                                />
                                {(gettingCustomer || gettingCard) &&
                                    <p>Carregando dados...</p>
                                }
                                {!gettingCustomer && !gettingCard && customer &&
                                    <>
                                        <InputPreview 
                                            label={`Nome`}
                                            previewValue={customer.name}
                                            value={customer.name}
                                            change={async value => updateCustomerParam('name', value)} 
                                        />
                                        {/* <InputPreview 
                                            type={'select'}
                                            label={`Cargo`}
                                            previewValue={getLabelFromOptions(positionsOptions, customer.position)}
                                            value={customer.position}
                                            change={async value => updateCustomerParam('position', value)} 
                                            options={positionsOptions}
                                        />
                                        <InputPreview 
                                            type={'select'}
                                            label={`Departamento`}
                                            previewValue={getLabelFromOptions(departmentsOptions, customer.department)}
                                            value={customer.department}
                                            change={async value => updateCustomerParam('department', value)} 
                                            options={departmentsOptions}
                                        /> */}
                                        <InputPreview 
                                            label={`E-mail`}
                                            previewValue={customer.email}
                                            value={customer.email}
                                            change={async value => updateCustomerParam('customer', value)} 
                                        />
                                        
                                        <InputPreview 
                                            label={`Telefone`}
                                            previewValue={customer.phone}
                                            value={customer.phone}
                                            change={async value => updateCustomerParam('phone', value)} 
                                            mask={`convertToPhone`}
                                        />

                                        <Link style={{ display: "inline-block", marginTop: 15, fontSize: 14}} to={`/customer/edit/${customer.id}`}>Mais detalhes</Link>
                                    </>
                                }
                            </BoxShrinkable>
                        </div>
                        <div className="col-8">
                            <BoxTabs
                                titles={[
                                    'Timeline',
                                    'Tarefas',
                                    // 'E-mails',
                                    // 'Ligações',
                                    // 'Whatsapp'
                                ]}
                                contents={[
                                    <CRMTimeline cardId={card.id} />,
                                    <CRMTasks cardId={card.id} />,
                                    // <p style={{ textAlign: 'center', marginTop: 25, marginBottom: 15}}>Nenhum registro encontrado.</p>,
                                    // <p style={{ textAlign: 'center', marginTop: 25, marginBottom: 15}}>Nenhum registro encontrado.</p>,
                                    // <p style={{ textAlign: 'center', marginTop: 25, marginBottom: 15}}>Nenhum registro encontrado.</p>
                                ]}
                            />
                        </div>
                    </div>
                </div>
            }
        </PanelTemplate>
    );
}

export default CRMCard;

const InputPreview = ({type, label, previewValue, value, change, options, mask, link}) => {
    const navigate = useNavigate();

    const [edit, setEdit] = useState(false);
    const [prevValue, setPrevValue] = useState(value);
    const [updating, setUpdating] = useState(false);

    const copyContent = async () => {
        try {
            await navigator.clipboard.writeText(previewValue);
            toast.success("Texto copiado", { autoClose: 1500});
        } catch (err) {
            toast.error("Falha ao copiar o texto: ", err);
        }
    }

    const save = async () => {
        setEdit(false);
        setUpdating(true);
        let result = await change(prevValue);
        if(result){
            setUpdating(false);
            console.log('re', result);
        } else {
            setEdit(true);
            setUpdating(false);
            console.log('re2', result);
        }
    }

    return (
        <div className="input-preview">
            {edit &&
                <div className="box-edit">
                    {type === 'select' &&
                        <InputSelect options={options} value={prevValue} change={setPrevValue} />
                    }
                    {(type === 'text' || !type) &&
                        <Input label={label} value={prevValue} change={setPrevValue} mask={mask ?? null} />
                    }
                    {type === 'date' &&
                        <InputDate 
                            format={`YYYY-MM-DD`} 
                            value={prevValue} 
                            change={setPrevValue} 
                            label={label} 
                        />
                    }
                    <div className="actions">
                        <Button
                            type={`secondary`}
                            text={`Cancelar`}
                            size={`small`}
                            action={() => setEdit(false)}
                        />
                        <Button
                            type={`primary`}
                            text={`Salvar`}
                            size={`small`}
                            svg={`save-white`}
                            action={save}
                        />
                    </div>
                </div>
            }
            <div className="content">
                <label>{label}</label>
                <p>{updating ? 'Salvando...' : previewValue}</p>
            </div>
            <div className="actions">
                <button onClick={copyContent}><img src={ require('../../../assets/images/svgs/input-preview-cp.svg').default } /></button>
                {typeof change === 'function' &&
                    <button onClick={() => setEdit(true)}><img src={ require('../../../assets/images/svgs/input-preview-edit.svg').default } /></button>
                }
                {link &&
                    <button onClick={() => navigate(link)}><img src={ require('../../../assets/images/svgs/input-preview-edit.svg').default } /></button>
                }
            </div>
        </div>
    );
}