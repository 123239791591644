import React, { useEffect } from "react";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";

const MultipleValueOptions = ({options, setOptions}) => {
    const base = {
        description: '',
        value: 'R$ 0,00'
    };

    useEffect(() => {
        if(options.length === 0){
            setOptions([base]);
        }
    }, []);

    const addOptions = () => {
        let toOptions = [...options];
        toOptions.push(base);
        setOptions(toOptions);
    }

    const deleteOption = index => {
        let toOptions = [];
        options.map((option, i) => {
            if(index !== i) toOptions.push(option);
        });
        setOptions(toOptions);
    }

    return (
        <>
            {options.map((option, index) => (
                <div key={index} className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                type={`text`} 
                                label={`Descrição`} 
                                value={option.description}
                                change={value => setOptions(prev => {
                                    prev[index].description = value;
                                    return [...prev];
                                })}
                            />
                        </div>
                        <div className="col-5">
                            <Input 
                                type={`text`}
                                label={`Valor`} 
                                value={option.value} 
                                change={value => setOptions(prev => {
                                    prev[index].value = value;
                                    return [...prev];
                                })}
                                mask={`convertToMoney`} 
                            />
                        </div>
                        <div className="col-1">
                            <Button type={`delete`} svg={`delete-small`} action={() => deleteOption(index)} />
                        </div>
                    </div>
                </div>
            ))}

            <Button 
                type={`link`}
                size={`small`}
                text={`Cadastrar mais opções`}
                full={true}
                action={addOptions}
            />
        </>
    );
}

export default MultipleValueOptions;