import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputCheckboxUnique from "../../../components/InputCheckboxUnique";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import { convertIntToMoney, convertIntToPercentage, convertMoenyToInt, convertPercentageToFloat } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import { convertToMoney } from "../../../helpers/masks";
import api from "../../../services/api";
import { hasPermission } from "../../../services/auth";
import { countModalProductPendingConferences } from "../helpers/conference";


const ModalProductDetails = ({item, close, getOrder}) => {
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState(item.params ? JSON.parse(item.params)  : []);
    const [customizations, setCustomizations] = useState(item.order_item_customizations);
    const [totalRealCost, setTotalRealCost] = useState(item.total_real_cost ? convertIntToMoney(item.total_real_cost) : 'R$ 0,00');
    const [totalRealCostConference, setTotalRealCostConference] = useState(item.total_real_cost_conference ?? false);
    const [companyConference, setCompanyConference] = useState(item.company_conference ?? false);
    const [companyTradeNameReal, setCompanyTradeNameReal] = useState(item.company_trade_name_real ?? '');

    const [pendingConferences, setPendingConferences] = useState(0);

    useEffect(() => {
        setPendingConferences( countModalProductPendingConferences(params,totalRealCostConference, companyConference, customizations) );
    }, [params,totalRealCostConference, companyConference, customizations]);

    const handleRealValueChange = (value, index, indexOption = null) => {
        let toParams = [];
        params.map((param, i) => {
            if(i === index){
                if(indexOption !== null) {
                    let toReal = param.real ? [...param.real] : [];
                    toReal[indexOption] = value;
                    toParams.push({
                        ...param,
                        real: toReal
                    }); 
                } else {
                    toParams.push({
                        ...param,
                        real: value
                    });   
                }
            } else {
                toParams.push({...param});
            }
        });
        setParams(toParams);
    }

    const handleComissaoPagaChange = (value, index) => {
        let toParams = [];
        params.map((param, i) => {
            if(i === index){
                toParams.push({
                    ...param,
                    comissao_paga: value
                });
            } else {
                toParams.push({...param});
            }
        });
        setParams(toParams);
    }

    const handleRealValueConferenceChange = (value, index, indexOption = null) => {
        let toParams = [];
        params.map((param, i) => {
            if(i === index){
                if(indexOption !== null) {
                    let toConference = param.conference ? [...param.conference] : [];
                    toConference[indexOption] = value;
                    toParams.push({
                        ...param,
                        conference: toConference
                    }); 
                } else {
                    toParams.push({
                        ...param,
                        conference: value
                    });   
                }
            } else {
                toParams.push({...param});
            }
        });
        setParams(toParams);
    }


    const handleCustomizationRealValueChange = (value, index) => {
        let toCustomizations = [];
        customizations.map((customization, i) => {
            if(i === index){
                toCustomizations.push({
                    ...customization,
                    total_real_cost: value
                });
            } else {
                toCustomizations.push({...customization});
            }
        });
        setCustomizations(toCustomizations);
    }

    const handleCustomizationRealConferenceChange = (value, index) => {
        let toCustomizations = [];
        customizations.map((customization, i) => {
            if(i === index){
                toCustomizations.push({
                    ...customization,
                    total_real_cost_conference: value
                });
            } else {
                toCustomizations.push({...customization});
            }
        });
        setCustomizations(toCustomizations);
    }

    const handleCustomizationCompanyRealChange = (value, index) => {
        let toCustomizations = [];
        customizations.map((customization, i) => {
            if(i === index){
                toCustomizations.push({
                    ...customization,
                    company_trade_name_real: value
                });
            } else {
                toCustomizations.push({...customization});
            }
        });
        setCustomizations(toCustomizations);
    }

    const handleCustomizationCompanyConferenceChange = (value, index) => {
        let toCustomizations = [];
        customizations.map((customization, i) => {
            if(i === index){
                toCustomizations.push({
                    ...customization,
                    company_conference: value
                });
            } else {
                toCustomizations.push({...customization});
            }
        });
        setCustomizations(toCustomizations);
    }

    const renderParamValues = (param, index) => {
        switch (param.type) {
            case 'multiple_value':
                return renderMultipleValue(param, index);
                
            case 'multiple_value_with_quantity':
                return renderMultipleValueWithQuantity(param, index);
            
            case 'quantity_and_price':
                return renderQuantityAndPrice(param, index);
                
            case 'extra':
                return renderExtra(param, index);

            case 'percentage':
                return renderPercentage(param, index);

            case 'conditional_percentage':
                return renderConditionalPerentage(param, index);

            case 'multiple_percentage':
                return renderMultiplePercentage(param, index);
        
            default:
                return <></>;
        }
    }

    const renderQuantityAndPrice = (param, index) => {
        if(param.values.length > 0){
            const quantity = param.values[0];
            const total = convertMoenyToInt(param.values[1]) * quantity;
            const totalRealValue = param.real ? convertMoenyToInt(param.real) : 0;
            const totalRealValueConference = param.conference ?? false;

            return (
                <div className="row">
                    <div className="col-4">
                        <Input label={`Quantidade`} disabled={true} value={quantity} />
                    </div>
                    <div className="col-3">
                        <Input label={`Total orçamento`} disabled={true} value={`R$ ${convertIntToMoney(total)}`} />
                    </div>
                    {/* {hasPermission('edit_conference') && */}
                        <div className="col-3">
                            <Input label={`Total real`} disabled={!hasPermission('edit_conference')} change={value => handleRealValueChange( convertToMoney(value) , index)} value={`R$ ${convertIntToMoney(totalRealValue)}`} />
                        </div>
                    {/* } */}
                    <div className="col-2">
                        {hasPermission('edit_conference') &&
                            <InputCheckboxUnique label={'Conferido'} checked={totalRealValueConference} change={value => handleRealValueConferenceChange( value , index)} />
                        }
                    </div>
                </div>  
                // <>
                //     <p><b>Quantidade:</b> {param.values[0]}</p>
                //     <p><b>Value:</b> {param.values[1]}</p>
                // </>
            );
        } else {
            return <p>Não preenchido</p>;
        }
    }

    const renderPercentage = param => {
        return <p><b>Valor:</b> {param.value ? param.value : '0,0000 %'}</p>;
    }
    
    const renderMultiplePercentage = (param, index) => {
        const option = param.options.filter(op => op.value === param.value)[0];
        
        if(!option) return <></>;

        let totalRealValue = param.real ? convertMoenyToInt(param.real) : 0;

        const comissaoPaga = param?.comissao_paga === true;

        if(option){
            return (
                <div className="row">
                    <div className="col-4">
                        <Input label={`Descrição`} disabled={true} value={option.description} />
                    </div>
                    <div className="col-3">
                        <Input label={`Total orçamento`} disabled={true} value={`R$ ${convertIntToMoney(param.conference_value)}`} />
                    </div>
                    {/* {hasPermission('edit_conference') && */}
                    <div className="col-3">
                        <Input label={`Total real`} disabled={true} value={`R$ ${convertIntToMoney(param.real_value)}`} />
                    </div>
                    {/* } */}
                    <div className="col-2">
                        {param.name === 'Comissão' && hasPermission('edit_conference') &&
                            <InputCheckboxUnique label={'Paga'} checked={comissaoPaga} change={value => handleComissaoPagaChange( value , index)} />
                        }
                    </div>
                </div>           
            );
        } else {
            return <p>Não preenchido</p>
        }
    }

    const renderConditionalPerentage = (param, index) => {
        let relatedParam = params.filter(p => p.id === param.param_id)[0];
        if(!relatedParam){
            return <p>Não informado</p>
        }
        let relatedValue = relatedParam.value;
        let option = param.options.filter(op => op.related_percentage === relatedValue)[0];
        const totalRealValue = param.real ? convertMoenyToInt(param.real) : 0;

        return (
            <div className="row">
                {option?.value &&
                    <>
                        <div className="col-4">
                            <Input label={`Porcentagem`} disabled={true} value={option.value} />
                        </div>
                        <div className="col-3">
                            <Input label={`Total orçamento`} disabled={true} value={`R$ ${convertIntToMoney(param.conference_value)}`} />
                        </div>
                        {/* {hasPermission('edit_conference') && */}
                            <div className="col-3">
                                <Input label={`Total real`} disabled={true} value={`R$ ${convertIntToMoney(param.real_value)}`} />
                            </div>
                        {/* } */}
                        <div className="col-2"></div>
                    </>
                }
            </div>           
        );
    }

    const renderMultipleValue = (param, index) => {
        const option = param.options.filter(op => op.value === param.value)[0];
        const totalRealValue = param.real ? convertMoenyToInt(param.real) : 0;

        const totalRealValueConference = param.conference ?? false;

        if(option){
            return (
                <div className="row">
                    <div className="col-4">
                        <Input label={`Descrição`} disabled={true} value={option.description} />
                    </div>
                    <div className="col-3">
                        <Input label={`Total orçamento`} disabled={true} value={option.value} />
                    </div>
                    {/* {hasPermission('edit_conference') && */}
                        <div className="col-3">
                            <Input label={`Total real`} disabled={!hasPermission('edit_conference')} change={value => handleRealValueChange( convertToMoney(value) , index)} value={`R$ ${convertIntToMoney(totalRealValue)}`} />
                        </div>
                    {/* } */}
                    <div className="col-2">
                        {hasPermission('edit_conference') &&
                            <InputCheckboxUnique label={'Conferido'} checked={totalRealValueConference} change={value => handleRealValueConferenceChange( value , index)} />
                        }
                    </div>
                </div>  
                // <p><b>Descrição:</b> {option.description}</p>
                // <p><b>Valor:</b> {option.value}</p> 
            )
        } else {
            return <p>Não preenchido</p>
        }
    }

    const renderMultipleValueWithQuantity = (param, index) => {
        let values = [];

        return (
            <>
                {param.options.map((option, indexOption) => {
                    let unit = convertMoenyToInt(option.value);
                    let quantity = param.values[indexOption] ? param.values[indexOption] : 0;
                    let total = unit * quantity;
                    let paramReal = param?.real ? param.real : [];
                    let totalRealValue = paramReal[indexOption] ? convertMoenyToInt(paramReal[indexOption]) : 0;

                    let paramConference = param?.conference ? param.conference : [];
                    let totalRealValueConference = paramConference[indexOption] ?? false;

                    return (
                        <div key={indexOption} className="row">
                            <div className="col-4">
                                <Input label={`Descrição`} disabled={true} value={option.description} />
                            </div>
                            <div className="col-3">
                                <Input label={`Total orçamento`} disabled={true} value={convertIntToMoney(total)} />
                            </div>
                            {/* {hasPermission('edit_conference') && */}
                                <div className="col-3">
                                    <Input label={`Total real`} disabled={!hasPermission('edit_conference')} change={value => handleRealValueChange( convertToMoney(value) , index, indexOption)} value={`R$ ${convertIntToMoney(totalRealValue)}`} />
                                </div>
                            {/* } */}
                            <div className="col-2">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={totalRealValueConference} change={value => handleRealValueConferenceChange( value , index, indexOption)} />
                                }
                            </div>
                        </div>   
                    );
                    // <div key={index}>
                    //     {index === 0 ? <></> : <br />}
                    //     <p><b>Descrição: </b>{option.description}</p>
                    //     <p><b>Valor: </b>{option.value}</p>
                    //     <p><b>Quantidade: </b>{param.values[index] ? param.values[index] : 0}</p>
                    // </div>
                })}
            </>
        )
    }

    const renderExtra = (param, index) => {
        let values = [];

        return (
            <>
            {/* <div key={index}>
                        {index === 0 ? <></> : <br />}
                        <p><b>Descrição: </b>{option.description}</p>
                        <p><b>Valor: </b>{param.values[index] ? param.values[index] : 'R$ 0,00'}</p>
                    </div> */}
                {param.options.map((option, indexOption) => {
                    let paramReal = param?.real ? param.real : [];
                    let totalRealValue = paramReal[indexOption] ? convertMoenyToInt(paramReal[indexOption]) : 0;
                    
                    let paramConference = param?.conference ? param.conference : [];
                    let totalRealValueConference = paramConference[indexOption] ?? false;
                    
                    return (
                        <div key={indexOption} className="row">
                            <div className="col-4">
                                <Input label={`Descrição`} disabled={true} value={option.description} />
                            </div>
                            <div className="col-3">
                                <Input label={`Total orçamento`} disabled={true} value={param.values[indexOption] ? param.values[indexOption] : 'R$ 0,00'} />
                            </div>
                            {/* {hasPermission('edit_conference') && */}
                                <div className="col-3">
                                    <Input label={`Total real`} disabled={!hasPermission('edit_conference')} change={value => handleRealValueChange( convertToMoney(value) , index, indexOption)} value={`R$ ${convertIntToMoney(totalRealValue)}`} />
                                </div>
                            {/* } */}
                            <div className="col-2">
                                {hasPermission('edit_conference') &&
                                    <InputCheckboxUnique label={'Conferido'} checked={totalRealValueConference} change={value => handleRealValueConferenceChange( value , index, indexOption)} />
                                }
                            </div>
                        </div>   
                    );
                })}
            </>
        )
    }

    const update = () => {
        setLoading(true);

        const data = {
            total_real_cost: convertMoenyToInt(totalRealCost),
            total_real_cost_conference: totalRealCostConference,
            company_conference: companyConference,
            company_trade_name_real: companyTradeNameReal,
            customizations: getCustomizationsCost(),
            params: JSON.stringify(params)
        };

        api.put(`/order/item/${item.id}`, data).then(res => {
            toast.success('Alterações salvas com sucesso');
            getOrder();
            close();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getCustomizationsCost = () => {
        let toCustomizations = [];
        customizations.map(customization => {
            toCustomizations.push({
                ...customization,
                total_real_cost: customization.total_real_cost ? convertMoenyToInt(customization.total_real_cost) : 0
            })
        });
        return toCustomizations;
    }

    const fillCompanyTradeNameReal = e => {
        e.preventDefault();
        setCompanyTradeNameReal(item.company_trade_name ?? '');
    }

    const fillCustomerCompanyTradeNameReal = (e, index) => {
        e.preventDefault();
        let value = customizations[index].company_trade_name;
        handleCustomizationCompanyRealChange(value, index);
    }

    return (
        <>
            <Modal 
                className={`product-details`}
                size={`big`}
                show={true}
                close={close}
                title={`Detalhes do produto`}
                footer={
                    <>
                        <p><b>{pendingConferences} {pendingConferences === 1 ? 'valor' : 'valores'}</b> sem conferência</p>
                        {hasPermission('edit_conference') &&
                            <Button svg={`save-white`} text={`Salvar alterações`} loading={loading} action={update} />
                        }
                    </>
                }
            >
                <div className="modal-section-title">
                    <h2>Produto</h2>
                </div>
                <div className="section" style={{ paddingBottom: 15 }}>
                    <p><b>Nome:</b> {item.product_name}</p>
                    <p><b>Quantidade:</b> {item.quantity}</p>
                    <p><b>Preço de venda unit.:</b> R${convertIntToMoney(item.price)}</p>
                    <p><b>Preço de venda total:</b> R${convertIntToMoney(item.price * item.quantity)}</p>
                </div>

                <div className="modal-section-title">
                    <h2>Custo do produto</h2>
                </div>
                <div className="section">
                    <div className="row">
                        <div className="col-md-2" style={{ justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 10 }}>
                            <p><b>Cálculo:</b><br /> {item.calculation_type === 'manual' ? 'Manual' : 'Automático'}</p>
                        </div>
                        <div className="col-md-2" style={{ justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 10 }}>
                            <p><b>Custo unitário:</b><br /> R${convertIntToMoney(item.cost)}</p>
                        </div>
                        <div className="col-md-3">
                            <Input label={`Total orçamento`} disabled={true} value={` R$ ${convertIntToMoney(item.cost * item.quantity)}`} />
                        </div>
                        {/* {hasPermission('edit_conference') && */}
                            <div className="col-3">
                                <Input label={`Total real`} disabled={!hasPermission('edit_conference')} change={value => setTotalRealCost( convertToMoney(value) )} value={totalRealCost} />
                            </div>
                        {/* } */}
                        <div className="col-md-2">
                            {hasPermission('edit_conference') &&
                                <InputCheckboxUnique label={'Conferido'} checked={totalRealCostConference} change={setTotalRealCostConference} />
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-5">
                            <Input label={`Fornecedor orçamento`} disabled={true} value={item.company_trade_name} />
                        </div>
                        <div className="col-md-5">
                            <div className="input-group-with-link">
                                <Input label={`Fornecedor real`} disabled={!hasPermission('edit_conference')} value={companyTradeNameReal} change={setCompanyTradeNameReal} />
                                <a href="#" onClick={fillCompanyTradeNameReal}>Mesmo fornecedor do orçamento</a>
                            </div>
                        </div>
                        <div className="col-md-2">
                            {hasPermission('edit_conference') &&
                                <InputCheckboxUnique label={'Conferido'} checked={companyConference} change={setCompanyConference} />
                            }
                        </div>
                    </div>
                </div>


                <div className="modal-section-title">
                    <h2>Gravações</h2>
                </div>
                {customizations.length === 0 &&
                    <div className="section">
                        <p>Nenhuma gravação</p>
                    </div>
                }
                {customizations.map((customization, index) => {

                    let totalRealValue = customization.total_real_cost ? customization.total_real_cost : 0;
                    totalRealValue = Number.isInteger(totalRealValue) ? totalRealValue : convertMoenyToInt(totalRealValue);

                    let totalRealConference = customization.total_real_cost_conference ?? false;
                    let customizationCompanyTradeName = customization.company_trade_name ?? '';
                    let customizationCompanyTradeNameReal = customization.company_trade_name_real ?? '';
                    let customizationCompanyConference = customization.company_conference ?? false;

                    return (
                        <div key={index} className="section">
                            <div className="row">
                                <div className="col-md-4" style={{ justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: 10 }}>
                                    <div>
                                        <p><b>Nome:</b> {customization.customization_name}</p>
                                        <p><b>Quantidade de cores:</b> {customization.quantity_colors}</p>
                                        {customization.customization_multiply_colors === 1 &&
                                            <p><b>Custo por cor:</b> R${convertIntToMoney(customization.cost / customization.quantity_colors)}</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <Input label={`Total orçamento`} disabled={true} value={` R$ ${convertIntToMoney(customization.total_cost)}`} />
                                </div>
                                {/* {hasPermission('edit_conference') && */}
                                    <div className="col-md-3">
                                        <Input label={`Total real`} disabled={!hasPermission('edit_conference')} change={value => handleCustomizationRealValueChange( convertToMoney(value) , index)} value={`R$ ${convertIntToMoney(totalRealValue)}`} />
                                    </div>
                                {/* } */}
                                <div className="col-md-2">
                                    {hasPermission('edit_conference') &&
                                        <InputCheckboxUnique label={'Conferido'} checked={totalRealConference} change={value => handleCustomizationRealConferenceChange(value, index)} />
                                    }
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-5">
                                    <Input label={`Fornecedor orçamento`} disabled={true} value={customizationCompanyTradeName} />
                                </div>
                                <div className="col-md-5">
                                    <div className="input-group-with-link">
                                        <Input label={`Fornecedor real`} value={customizationCompanyTradeNameReal} change={value => handleCustomizationCompanyRealChange(value, index)} />
                                        <a href="#" onClick={e => fillCustomerCompanyTradeNameReal(e, index)}>Mesmo fornecedor do orçamento</a>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    {hasPermission('edit_conference') &&
                                        <InputCheckboxUnique label={'Conferido'} checked={customizationCompanyConference} change={value => handleCustomizationCompanyConferenceChange(value, index)} />
                                    }
                                </div>
                            </div>
                        </div>
                    );
                })}

                {params.map((param, index) => (
                    <div key={index}>
                    {param.name === 'Margem' &&
                        <p>
                            <br />
                            Para ver os valores abaixo atualizados, <b>salve as alterações.</b>
                            <br />
                        </p>
                    }
                    
                    {((param.name === 'Margem' && hasPermission('view_margem_order')) || param.name !== 'Margem') &&
                        <Param index={index} param={param} renderParamValues={renderParamValues} />
                    }
                    
                    </div>
                ))}

                {hasPermission('view_imposto_order') &&
                    <div>                
                        <div className="modal-section-title" style={{ marginTop: 12 }}>
                            <h2>Imposto</h2>
                        </div>
                        <div className="section">
                            <div className="row">
                                <div className="col-4">
                                    <Input label={`Descrição`} disabled={true} value={convertIntToPercentage(item.order.billing_company_tax_percentage)} />
                                </div>
                                <div className="col-3">
                                    <Input label={`Total orçamento`} disabled={true} value={`R$ ${convertIntToMoney((item.order.billing_company_tax_percentage/1000000) * item.total)}`} />
                                </div>
                                {/* {hasPermission('edit_conference') && */}
                                    <div className="col-3">
                                        <Input label={`Total real`} disabled={true} value={`R$ ${convertIntToMoney((item.order.billing_company_tax_percentage/1000000) * item.total)}`} />
                                    </div>
                                {/* } */}
                                <div className="col-md-2">
                                    
                                </div>
                            </div>   
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}

const Param = ({index, param, renderParamValues}) => (
    <div>                
        <div className="modal-section-title" style={{ marginTop: 12 }}>
            <h2>{param.name}</h2>
        </div>
        <div className="section">
            {renderParamValues(param, index)}
        </div>
    </div>
)

export default ModalProductDetails;