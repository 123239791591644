import React, { useEffect, useRef, useState } from "react";
import {Editor, EditorState, RichUtils, convert} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import 'draft-js/dist/Draft.css';

export const InputEditor = ({change, value}) => {
    const [editorState, setEditorState] = useState(EditorState.createWithContent( stateFromHTML(value) ));

    let ref = null;

    const handleStyle = style => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }

    useEffect(() => {
        const html = stateToHTML( editorState.getCurrentContent() );
        change(html);
    }, [editorState]);

    const focusEditor = () => {
        if(ref){
            ref.focus();
        }
    }

    const setRef = r => {
        ref = r;
    }

    return (
        <div className="input-editor-group">
            <div className="header">
                <button className="bold" onClick={() => handleStyle('BOLD')}>B</button>
                <button className="italic" onClick={() => handleStyle('ITALIC')}>I</button>
                <button className="underline" onClick={() => handleStyle('UNDERLINE')}>U</button>
            </div>
            <div className="body" onClick={focusEditor}>
                <Editor ref={setRef} placeholder="Escreva descrição aqui" editorState={editorState} onChange={setEditorState} />
            </div>
        </div>
    );
}

export default InputEditor;