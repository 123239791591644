import React, { useEffect, useState } from "react";
import InputCheckbox from "../InputCheckbox";

export const InputCheckboxesSublevels = ({options, selecteds, setSelecteds}) => {
    const handleChange = option => {
        if(selecteds.includes(option.value)){
            let toSelecteds = [];
            let valuesToRemove = [];

            if(option?.options){
                option.options.map(o => {
                    valuesToRemove.push(o.value);
                });
            }
            valuesToRemove.push(option.value);
            setSelecteds(prev => prev.filter(v => !valuesToRemove.includes(v)));
        } else {
            setSelecteds(prev => [...prev, option.value]);
        }
    }

    return (
        <div className="input-checkboxes-sublevels-group">
            <div className="options">
                {options.map((option, index) => (
                    <div key={index}>
                        <button
                            className="option"
                            onClick={() => handleChange(option)}
                        >
                            <InputCheckbox 
                                active={selecteds.includes(option.value)} 
                            />
                            {option.label}
                        </button>
                        {selecteds.includes(option.value) && option?.options && option.options.map((suboption, index) => (
                            <button 
                                key={index}
                                className="option suboption"
                                onClick={() => handleChange(suboption)}
                            >
                                <InputCheckbox 
                                    active={selecteds.includes(suboption.value)} 
                                />
                                {suboption.label}
                            </button>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default InputCheckboxesSublevels;