import React from "react";
import Sidebar from '../../components/Sidebar';

export const PanelTemplate = ({children, id}) => {
    return (
        <main id="panel">
            <Sidebar />
            <section id={id} className="wrapper">
                {children}
            </section>
        </main>
    );
}

export default PanelTemplate;