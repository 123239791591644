import React, {useEffect, useRef, useState } from "react";
import tinymce from 'tinymce';

const InputEditorTiny = ({change, value, label, readonly}) => {
    const [editor, setEditor] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const editorRef = useRef(null);
    
    useEffect(() => {
        initEditor();
    }, [editorRef]);

    useEffect(() => {
        if(value && value.length > 0 && !loaded && editor){
            editor.setContent(value);
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
            setLoaded(true);
        }
    }, [value, editor]);

    const handleContentChange = content => change(content)

    const initEditor = () => {
        if(!editorRef.current);
        const el = editorRef.current;
        let tinyEditor = tinymce.init({
            height: 175,
            width: '100%',
            content_style: 'body { color: #000; font-size: 14px; font-family: "Roboto", sans-serif; line-height: 12px; }',
            statusbar: false,
            menubar: false,
            promotion: false,
            branding: false,
            target: el,
            readonly: readonly === true,
            toolbar: 'styleselect bold italic underline strikethrough | forecolor backcolor',
            setup: function (editor) {
                editor.on('Paste Change input Undo Redo', function(e) {
                    handleContentChange(editor.getContent());
                });
            }
        });
        tinyEditor.then(editors => {
            setEditor(editors[0]);
        });
    }

    return (
        <div className="tinymce-container">
            {label &&
                <label>{label}</label>
            }
            <textarea readOnly ref={editorRef}></textarea>
        </div>
    );
}

export default InputEditorTiny;