import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";
import { hasPermission } from "../../services/auth";
import { ModalDelete } from "../../components/ModalDelete";
import Pagination from "../../components/Pagination";

const types = [
    {label: 'Milheiro', value: 'thousand'},
    {label: 'Unidade/Intervalo', value: 'unit'}
];

const multiplyColorsOptions = [
    {label: 'Não', value: false},
    {label: 'Sim', value: true}
];

export const CustomizationList = () => {
    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState('');

    const [customizations, setCustomizations] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateCustomization, setShowCreateCustomization] = useState(false);

    const [customizationName, setCustomizationName] =  useState('');
    const [customizationCalculationPer, setCustomizationCalculationPer] = useState(null);
    const [customizationMultiplyColors, setCustomizationMultiplyColors] = useState(null);

    const [customizationToDelete, setCustomizationToDelete] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getCustomizations();
    }, [currentPage,filters]);

    useEffect(() => {
        generateData();
    }, [customizations]);

    useEffect(() => {
        if(!showCreateCustomization) clean();
    }, [showCreateCustomization]);

    const getCustomizations = () => {
        setCustomizations([]);
        setLoading(true);

        api.get(`/customization/paginate?${filters}&page=${currentPage}`).then(res => {
            setCustomizations(res.data.resources.data);
            setCurrentPage(res.data.resources.current_page);
            setLastPage(res.data.resources.last_page)
            setPerPage(res.data.resources.per_page)
            setTotalResults(res.data.resources.total)
            setHasNextPage(res.data.resources.next_page_url !== null);
            setHasPrevPage(res.data.resources.prev_page_url !== null);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        let header = ['Nome', 'Cálculo por', 'Última atualização', 'Cadastro'];

        if(hasPermission('delete_customization')){
            header.push('');
        }
        
        const rows = [];

        customizations.map(customization => {
            let buttons = [];

            if(hasPermission('delete_customization')){
                buttons.push(
                    <Button type={`delete`} svg={`delete`} action={() => setCustomizationToDelete(customization.id)} />
                );
            }
            rows.push({
                data: [
                    customization.name,
                    customization.calculation_per === 'thousand' ? 'Milheiro' : 'Unidade/Intervalo' ,
                    moment(customization.updated_at).format('DD/MM/YYYY HH:mm'),
                    moment(customization.created_at).format('DD/MM/YYYY HH:mm'),
                ],
                to: `/customization/edit/${customization.id}`,
                buttons,
                id: customization.id
            })
        });

        setData({header, rows});
    }

    const storeCustomization = () => {
        setLoading(true);

        api.post(`/customization`, {
            name: customizationName,
            calculation_per: customizationCalculationPer,
            multiply_colors: customizationMultiplyColors
        }).then(res => {
            toast.success('Gravação cadastrada com sucesso');
            navigate(`/customization/edit/${res.data.resource.id}`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCustomizationName('');
        setCustomizationCalculationPer(null);
    }

    const deleteCustomization = () => {
        setLoading(true);

        api.delete(`/customization/${customizationToDelete}`).then(res => {
            setCustomizationToDelete(null);
            getCustomizations();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteMultipleCustomizations = () => {
        setLoading(true);

        api.delete(`/customization/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            setSelectedIds([]);
            getCustomizations();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        setCurrentPage(1);
        setFilters(value);
    }

    return (
        <>
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'gravações' : 'gravação'}</strong>?</p>}
                action={deleteMultipleCustomizations}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <ModalDelete
                show={customizationToDelete !== null}
                action={deleteCustomization}
                loading={loading}
                close={() => setCustomizationToDelete(null)}
            />
            <Modal
                title={`Cadastrar gravação`}
                size={`big`}
                show={showCreateCustomization}
                close={() => setShowCreateCustomization(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCustomization}
                        loading={loading} 
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-4">
                            <Input type={`text`} label={`Nome *`} value={customizationName} change={setCustomizationName} />
                        </div>
                        <div className="col-4">
                            <InputSelect label={`Cálculo por *`} value={customizationCalculationPer} change={setCustomizationCalculationPer} options={types} />
                        </div>
                        <div className="col-4">
                            <InputSelect label={`Multiplicar pelas cores *`} value={customizationMultiplyColors} change={setCustomizationMultiplyColors} options={multiplyColorsOptions} />
                        </div>
                    </div>
                </div>
            </Modal>
            <PanelTemplate id="customization-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: '[name]', type: 'text' }
                            ]}
                            filter={handleSetFilter}
                        />
                    }

                    right={
                        <>
                            {hasPermission('delete_customization') && selectedIds.length > 0 &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                svg={`delete`}
                                text={`Excluir ${selectedIds.length} selecionados`} 
                                action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            {hasPermission('create_customization') &&
                                <Button type={`primary`} size={`small`} text={`Cadastrar gravação`} svg="plus-white" action={() => setShowCreateCustomization(true)}  />
                            }
                        </>
                    }
                />
                
                <Box className={`no-padding with-pagination`}>
                    <Table 
                        header={data.header} 
                        rows={data.rows} 
                        loading={loading}
                        checks={true} 
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                    />
                </Box>
                {!loading &&
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        totalResults={totalResults}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </PanelTemplate>
        </>
    );
}

export default CustomizationList;