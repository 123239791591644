import moment from "moment";
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import InputDate from "../../../components/InputDate";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";

export const ModalFilter = ({showFilter, setShowFilter, customFilters, setCustomFilters}) => {
    const [admins, setAdmins] = useState([]);
    const [admin, setAdmin] = useState(null);
    const [initDate, setInitDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);

    useEffect(() => {
        getAdmins();
    }, []);

    const getAdmins = () => {
        api.get(`/list`).then(res => {
            setAdmins(res.data.resources);
        });
    }
    
    const handleClear = () => {
        setCustomFilters('');
        setAdmin(null);
        setInitDate(null);
        setFinalDate(null);
        setShowFilter(false);
    }

    const handleFilter = () => {
        let text = '';

        if(admin !== null){
            text += `&filters[0][admin_id][equal]=${admin}`;
        }
        if(initDate !== null){
            text += `&filters[0][created_at][bigger_or_equal]=${moment(initDate).format('YYYY-MM-DD')} 00:00:00`;
        }
        if(finalDate !== null){
            text += `&filters[0][created_at][less_or_equal]=${moment(finalDate).format('YYYY-MM-DD')} 23:59:59`;
        }

        setCustomFilters(text);
        setShowFilter(false);
    }
    
    return (
        <Modal
            show={showFilter}
            close={() => setShowFilter(false)}
            title={`Filtrar`}
            footer={
                <>
                    <Button
                        type={`secondary`}
                        size={`small`}
                        text={`Limpar filtros`}
                        action={handleClear}
                    />
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Filtrar resultados`}
                        action={handleFilter}
                    />
                    
                </>
            }
        >
            <div className="section">
                <div className="row">
                    <div className="col">
                        <InputSelect label={`Responsável`} options={createOptions(admins, 'name', 'id', true)} value={admin} change={setAdmin} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <InputDate
                            value={initDate}
                            change={setInitDate}
                            label={`Data inicial`}
                        />
                    </div>
                    <div className="col-6">
                        <InputDate
                            value={finalDate}
                            change={setFinalDate}
                            label={`Data final`}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}