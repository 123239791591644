import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Button = ({type, svg, text, action, size, full, loading, link, style, disabled}) => {

    const navigate = useNavigate();

    let className = `button ${type ? type : 'primary'}`;
    className += size ? ` ${size}` : ' regular';

    if(svg){
        className += ' has-svg';
    }

    if(full){
        className += ' full';
    }

    const handleOnClick = event => {
        if(!link){
            action();
            return;
        }

        if (
            event.ctrlKey || 
            event.shiftKey || 
            event.metaKey || // apple
            (event.button && event.button == 1) // middle click, >IE9 + everyone else
        ){
            window.open(link);
        } else {
            navigate(link);
        }
    }

    return (
        <button className={className} style={style ?? null} onClick={handleOnClick} disabled={loading === true || disabled === true}>
            {loading ? 'Carregando...' : text}
            {svg &&
                <img src={ require(`../../assets/images/svgs/${svg}.svg`) } alt="text" />
            }
        </button>
    );
}

export default Button;