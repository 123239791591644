import React from "react";

export const AuthTemplate = ({children}) => {
    return (
        <main id="auth">
            <section className="container">
                {children}
            </section>
            <section className="banner"></section>
        </main>
    );
}

export default AuthTemplate;