import React, { useState, useEffect, useRef } from "react";

import PanelTemplate from "../../templates/Panel";
import BoxTabs from "../../components/BoxTabs";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import InputCheckboxesSublevels from "../../components/InputCheckboxesSublevels";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { createOptions } from "../../helpers/selects";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";
import { convertIntToMoney, getBase64, getBase64Promise } from "../../helpers/conversions";
import { ModalDelete } from "../../components/ModalDelete";
import Modal from "../../components/Modal";
import ProductImagesTab from "./tabs/ImagesTab";
import { Loading } from "../../components/Loading";
import { hasPermission, hasPlanFeature } from "../../services/auth";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import SubproductsTab from "./tabs/SubproductsTab";

const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const featuredOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
];

const ProductDetailsTab = ({
    loading,
    product, 
    status, 
    setStatus, 
    sku, 
    setSku, 
    featured, 
    setFeatured, 
    recent, 
    setRecent, 
    name, 
    setName, 
    categories, 
    setSelectedCategories, 
    selectedCategories, 
    description, 
    setDescription, 
    techDescription, 
    setTechDescription, 
    tags, 
    tag, 
    setTag, 
    minQuantity, 
    setMinQuantity,
    updateApi,
    setUpdateApi,
    companySku,
    companyTradeName,
    setTab
}) => {
    return (
        <div>
            {!loading &&
            <>
                {/* <div className="row" style={{ marginBottom: 15, marginTop: 15 }}>
                    <div className="col">
                        <a href="#">Abrir no site</a>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-6">

                        <h2 className="section-title">Informações gerais</h2>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Status`} options={statusOptions} value={status} change={setStatus} />
                            </div>
                            <div className="col-6">
                                <Input label={`Código SKU`} value={sku} change={setSku} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Destaque`} options={featuredOptions} value={featured} change={setFeatured} />
                            </div>
                            <div className="col-6">
                                <InputSelect label={`Novidade`} options={featuredOptions} value={recent} change={setRecent} />
                            </div>
                        </div>

                        <Input label={`Nome do produto`} value={name} change={setName} />
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Selo`} options={createOptions(tags, 'text', 'id', true)} value={tag} change={setTag} null />
                            </div>
                            <div className="col-6">
                                <Input 
                                    label={`Quant. mínima`}
                                    value={minQuantity}
                                    change={setMinQuantity}
                                    mask={`convertToInt`}
                                />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-6">
                                <Input 
                                    label={`Preço a partir de`}
                                    disabled={true}
                                    value={product?.price ? convertIntToMoney(product.price, true) : 'R$ 0,00'}
                                />
                            </div>
                            {hasPlanFeature('stock') &&
                                <div className="col-6">
                                    <Input 
                                        label={`Estoque total`}
                                        disabled={true}
                                        value={(product?.stock ?? 0).toLocaleString('pt-BR')}
                                    />
                                </div>
                            }
                        </div>


                        <p style={{ fontSize: 14 }}>Mais detalhes de preço e estoque na aba <a href="#" onClick={e => {
                            e.preventDefault();
                            window.scrollTo(0,0);
                            setTab(2);
                        }}>cores, estoque e preço</a></p><br />

                        <h2 className="section-title">Atualização automática</h2>

                        <div className="row">
                            <div className="col-6">
                                <Input label="Fornecedor" disabled={true} value={companyTradeName} />
                            </div>
                            <div className="col-6">
                                <Input label="SKU do fornecedor" disabled={true} value={companySku} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{ paddingTop: 18}}>
                                <InputCheckboxUnique checked={updateApi} change={setUpdateApi} label={`Atualizar automaticamente nome, descrição e SEO`} />
                                <InputCheckboxUnique checked={true} disabled={true} label={`Atualizar automaticamente preço, imagens e variações`} />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">                        
                        {/* <h2 className="section-title">Descrição técnica</h2>
                        <InputEditor value={techDescription ?? ''} change={setTechDescription} /> */}
                        <h2 className="section-title">Descrição do produto</h2>
                        <InputEditor value={description} change={setDescription} />

                        <h2 className="section-title">Categorias</h2>
                        <InputCheckboxesSublevels
                            options={categories}
                            selecteds={selectedCategories}
                            setSelecteds={setSelectedCategories}
                        />
                    </div>
                </div>
            </>
            }
        </div>
    );
}

const KeyWordsTab = ({product, keywords, setKeywords}) => {
    const [showCreate, setShowCreate] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const [keyword, setKeyword] = useState('');

    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) {
            console.log(inputRef.current)
          inputRef.current.focus();
        }
    }, [showCreate]);

    useEffect(() => {
        setKeywords(product.keywords);
    }, [product]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    const deleteKeywords = () => {
        let toKeywords = keywords.filter(k => !selecteds.includes(k));
        setKeywords(toKeywords);
    }

    const storeKeyword = () => {
        if(keywords.includes(keyword)){
            toast.info('Palavra-chave já cadastrada');
        } else {
            let toKeywords = [...keywords];
            toKeywords.push(keyword.trim());
            toKeywords.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            setKeywords(toKeywords);
        }
        clean();
        // setShowCreate(false);
    }

    const clean = () => {
        setKeyword('');
        setSelecteds([]);
    }
    
    return (
        <>
            <Modal
                show={showCreate}
                title={`Cadastrar palavra-chave`}
                close={() => setShowCreate(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Cadastrar`}
                        action={storeKeyword}
                    />  
                }
            >
                <div className="section">
                    <Input 
                        inputRef={inputRef}
                        type={`text`} 
                        label={`Palavra-chave`} 
                        value={keyword} 
                        change={setKeyword}
                        action={storeKeyword}
                    />
                </div>

            </Modal>
            <div className="row">
                <div className="col-6">
                    <PageHeader
                        left={
                            <h2 className="section-title">Palavras-chave</h2>
                        }
                        right={
                            <>
                                <Button type={`primary`} size={`small`} svg={`plus-white`} text={`Cadastrar`} action={() => setShowCreate(true)} /> 
                                <Button type={`secondary`} size={`small`} svg={`delete-small`} text={`Excluir selecionadas`} action={deleteKeywords} />
                            </>
                        }
                    />
                    <InputCheckboxes 
                        options={createOptions(keywords)}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                    />
                </div>
            </div>
        </>
    );
}

const CustomizationsTab = ({selectedCustomizations, setSelectedCustomizations}) => {
    const [customizations, setCustomizations] = useState([]);

    useEffect(() => {
        getCustomizations();
    }, []);

    const getCustomizations = () => {
        api.get(`/customization`).then(res => {
            setCustomizations(res.data.resources);
        })
    }

    return (
        <div className="row">
            <div className="col-6">
                <h2 className="section-title">Gravações</h2>
                <InputCheckboxes 
                    options={createOptions(customizations, 'name', 'id')} 
                    selecteds={selectedCustomizations}
                    setSelecteds={setSelectedCustomizations}
                />
            </div>
        </div>
    );
}

const SeoTab = ({metaTitle, setMetaTitle, metaDescription, setMetaDescription, slug, setSlug}) => {
    return (
        <div className="row">
            <div className="col-6">
            <Input label={`Slug`} value={slug} change={setSlug} />
            <Input label={`Meta title`} value={metaTitle} change={setMetaTitle} />
            <InputTextarea label={`Meta description`} value={metaDescription} change={setMetaDescription} rows={5}/>
            </div>
        </div>
    );
}

export const ProductEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);

    const [tags, setTags] = useState([]);

    const [product, setProduct] = useState(null);
    const [status, setStatus] = useState(false);
    const [sku, setSku] = useState('');
    const [featured, setFeatured] = useState(false);
    const [recent, setRecent] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [techDescription, setTechDescription] = useState('');
    const [tag, setTag] = useState(null);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [minQuantity, setMinQuantity] = useState(1);
    const [updateApi, setUpdateApi] = useState(false);
    const [companySku, setCompanySku] = useState('');
    const [companyTradeName, setCompanyTradeName] = useState('');
    const [hasSubproducts, setHasSubproducts] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [subproducts, setSubproducts] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [selectedCustomizations, setSelectedCustomizations] = useState([]);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [tab, setTab] = useState(0);


    useEffect(() => {
        getCategories();
        getTags();
        getProduct();
    }, []);

    const getProduct = () => {
        setLoading(true);
        api.get(`/product/${id}`).then(res => {
            let resource = res.data.resource;
            let toSelectedCategories = [];
            resource.categories.map(category => toSelectedCategories.push(category.id));
            setProduct(resource);
            setSelectedCategories(toSelectedCategories);
            setSelectedCustomizations(resource.customization_ids);
            setStatus(resource.status);
            setSku(resource.sku);
            setFeatured(resource.featured);
            setRecent(resource.recent);
            setName(resource.name);
            setDescription(resource.description);
            setTechDescription(resource.tech_description);
            setTag(resource.tag_id);
            setMetaTitle(resource.meta_title ?? "");
            setMetaDescription(resource.meta_description ?? "");
            setSlug(resource.slug);
            setMinQuantity(resource.min_quantity);
            setUpdateApi(resource.update_api);
            setCompanySku(resource.company_sku);
            setCompanyTradeName(resource.company_trade_name);
            setSubproducts(resource.subproducts);
            setHasSubproducts(resource.has_subproducts);

        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getTags = () => {
        api.get(`/tag`).then(res => {
            setTags(res.data.resources);
        });
    }

    const getCategories = () => {
        api.get(`/category`).then(res => {
            let toCategories = [];

            res.data.resources.map(category => {
                let options = [];

                category.categories.map(subcategory => {
                    options.push({
                        label: subcategory.name,
                        value: subcategory.id
                    })
                });
                toCategories.push({
                    label: category.name,
                    value: category.id,
                    options
                })
            })
            setCategories(toCategories);
        });
    }

    const updateProduct = () => {
        setLoading(true);

        api.put(`/product/${id}`, {
            status,
            featured,
            recent,
            name,
            sku,
            description,
            tech_description: techDescription,
            categories: selectedCategories,
            keywords,
            customizations: selectedCustomizations,
            tag_id: tag,
            subproducts,
            meta_title: metaTitle,
            meta_description: metaDescription,
            slug,
            min_quantity: minQuantity,
            update_api: updateApi,
            has_subproducts: hasSubproducts
        }).then(res => {
            toast.success('Produto atualizado com sucesso');
            getProduct();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteProduct = () => {
        setLoading(true);

        api.delete(`/product/${id}`).then(res => {
            navigete(`/product/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteProduct}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="product-edit">
                <Loading show={loading} />
                {!loading && product !== null &&
                    <>
                        <BoxTabs
                            titles={[
                                'Detalhes dos produtos',
                                'Imagens',
                                'Cores, estoque e preço',
                                'Palavras-chave', 
                                'Gravações', 
                                'SEO'
                            ]}
                            tab={tab}
                            setTab={setTab}
                            contents={[
                                <ProductDetailsTab
                                    loading={loading}
                                    product={product}
                                    status={status}
                                    setStatus={setStatus}
                                    sku={sku}
                                    setSku={setSku}
                                    featured={featured}
                                    setFeatured={setFeatured}
                                    recent={recent}
                                    setRecent={setRecent}
                                    name={name}
                                    setName={setName}
                                    categories={categories}
                                    setSelectedCategories={setSelectedCategories}
                                    selectedCategories={selectedCategories}
                                    setDescription={setDescription}
                                    description={description}
                                    setTechDescription={setTechDescription}
                                    techDescription={techDescription}
                                    tags={tags}
                                    tag={tag}
                                    setTag={setTag}
                                    minQuantity={minQuantity}
                                    setMinQuantity={setMinQuantity}
                                    updateApi={updateApi}
                                    setUpdateApi={setUpdateApi}
                                    companySku={companySku}
                                    companyTradeName={companyTradeName}
                                    setTab={setTab}
                                />,
                                <ProductImagesTab
                                    product={product}
                                    setProduct={setProduct}
                                />,
                                <SubproductsTab 
                                    product={product}
                                    sku={sku}
                                    subproducts={subproducts}
                                    setSubproducts={setSubproducts}
                                    hasSubproducts={hasSubproducts}
                                    setHasSubproducts={setHasSubproducts}
                                />,
                                <KeyWordsTab
                                    product={product}
                                    keywords={keywords}
                                    setKeywords={setKeywords}
                                />,
                                <CustomizationsTab
                                    product={product}
                                    selectedCustomizations={selectedCustomizations}
                                    setSelectedCustomizations={setSelectedCustomizations}
                                />,
                                <SeoTab
                                    metaTitle={metaTitle}
                                    setMetaTitle={setMetaTitle}
                                    metaDescription={metaDescription}
                                    setMetaDescription={setMetaDescription}
                                    slug={slug}
                                    setSlug={setSlug}
                                />,
                            ]}
                        />
                        <PageFooter spaceBetween={true}>
                            <>
                                {hasPermission('edit_product') &&
                                    <Button
                                    type={`primary`}
                                    size={`small`}
                                    svg={`save-white`}
                                    text={`Salvar alterações`}
                                    action={updateProduct}
                                    loading={loading}
                                    />
                                }
                                {hasPermission('delete_product') &&
                                    <Button
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete-small`}
                                    text={`Excluir`}
                                    action={() => setConfirmDelete(true)}
                                    loading={loading}
                                    />
                                }
                            </>
                        </PageFooter>
                    </>
                }
            </PanelTemplate>
        </>
    );
}

export default ProductEdit;