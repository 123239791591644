import React from "react";
import InputCheckbox from "../InputCheckbox";

export const InputCheckboxUnique = ({checked, label, change, disabled}) => {
    return (
        <button
            className={`input-checkbox-unique${disabled ? ' disabled' : ''}`}
            onClick={() => disabled ? null : change(!checked)}
        >
            <InputCheckbox active={checked} />
            {label}
        </button>
    );
}

export default InputCheckboxUnique;