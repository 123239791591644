import moment from "moment";
import React from "react";
import DatePicker from 'react-date-picker';

export const InputDate = ({label, error, placeholder, type, change, value, disabled, action, format, autoComplete}) => {    
    const onKeyUp = event => {
        if (event.charCode === 13) {
            if(action !== undefined){
                action();
            }
        }
    }

    const handleChange = date => {
        const formattedDate = format && date ? moment(date).format(format) : date;
        change(formattedDate);
    }

    let formattedValue = value ? moment(value, 'YYYY-MM-DD').toDate() : null;

    return (
        <div className={`input-date${error ? ' has-error' : ''}`}>
            {label &&
                <label>{label}</label>
            }
            <DatePicker 
                format="dd/MM/y"
                onChange={handleChange}
                value={formattedValue}
            />
            {error &&
                <span className="error-message">{error}</span>
            }
        </div>
    );
}

export default InputDate;