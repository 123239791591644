export const countOrderPendingConferences = order => {
    let orderCount = 3;
    let productCount = countProductPendingConferences(null);

    return orderCount + productCount;
}

export const countOrderPendingConferencesTag = order => {
    let count = 0;

    count += order.shipping_type_conference === true ? 0 : 1;
    count += order.shipping_fee_conference === true ? 0 : 1;
    count += order.shipping_time_conference === true ? 0 : 1;
    count += order.payment_term_conference === true ? 0 : 1;
    count += order.payment_form_conference === true ? 0 : 1;

    order.items.map(item => {
        count += countProductPendingConferences(item);
    });

    return generateConferenceTag(count);
}

export const checkComissao = item => {
    let status = false;
    let params = JSON.parse(item.params);

    params.map(param => {
        if(param.name === 'Comissão'){
            status = param.comissao_paga === true;
        }
    });

    let cl = status ? 'conferred' : 'pending';
    return <span className={`tag-conference-status ${cl}`}>{status ? 'Paga' : 'Não paga'}</span>;
}

export const generateConferenceTag = count => {
    let cl = count > 0 ? 'pending' : 'conferred';
    let txt = count > 0 ? `${count} ${count === 1 ? 'pendência' : 'pendências'}` : 'Conferido';

    return <span className={`tag-conference-status ${cl}`}>{txt}</span>;
}

export const countProductPendingConferencesTag = item => {
    let count = countProductPendingConferences(item);
    return generateConferenceTag(count);
}

export const countProductPendingConferences = item => {
    let count = 0;
    count += item.total_real_cost_conference ? 0 : 1;
    count += item.company_conference ? 0 : 1;
    item.order_item_customizations.map(customization => {
        count += customization.total_real_cost_conference ? 0 : 1;
        count += customization.company_conference ? 0 : 1;
    });

    
    let params = JSON.parse(item.params);

    params.map(param => {
        if(['multiple_value_with_quantity', 'extra'].includes(param.type)){
            param.options.map((option, index) => {
                let arrConference = param.conference ?? [];
                count += arrConference[index] ? 0 : 1;
            });
        } else if(!['conditional_percentage', 'multiple_percentage'].includes(param.type)) {
            let invalid = param.value === null && param.values.length === 0;
            if(!invalid){
                let toadd = param.conference ? 0 : 1;
                console.log(toadd, param);
                count += param.conference ? 0 : 1;
            }
        }
    });

    return count;
}

export const countModalProductPendingConferences = (params,totalRealCostConference, companyConference, customizations) => {
    let count = 0;
    count += totalRealCostConference ? 0 : 1;
    count += companyConference ? 0 : 1;
    customizations.map(customization => {
        count += customization.total_real_cost_conference ? 0 : 1;
        count += customization.company_conference ? 0 : 1;
    });
    console.log(params);
    params.map(param => {
        
        if(['multiple_value_with_quantity', 'extra'].includes(param.type)){
            param.options.map((option, index) => {
                let arrConference = param.conference ?? [];
                count += arrConference[index] ? 0 : 1;
            });
        } else if(!['conditional_percentage', 'multiple_percentage'].includes(param.type)) {
            let invalid = param.value === null && param.values.length === 0;
            if(!invalid){
                let toadd = param.conference ? 0 : 1;
                console.log(toadd, param);
                count += param.conference ? 0 : 1;
            }
        }
    });

    return count;
}