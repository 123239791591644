import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import InputSelect from "../../components/InputSelect";
import { toast } from "react-toastify";
import BoxTabs from "../../components/BoxTabs";
import InputTextarea from "../../components/InputTextarea";
import { ModalDelete } from "../../components/ModalDelete";

const starOptions = [
    { label: '4 estrelas', value: 4 },
    { label: '5 estrelas', value: 5 }
];

const TestimonialList = () => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [testimonials, setTestimonials] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [stars, setStars] = useState(5);

    const [testimonialToDelete, setTestimonialToDelete] = useState(null);

    useEffect(() => {
        getTestimonials();
    }, []);

    useEffect(() => {
        generateData();
    }, [testimonials]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getTestimonials = () => {
        api.get('/evidence').then(res => {
            setTestimonials(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Estrelas'];
        const rows = [];
        
        testimonials.map(testimonial => {
            rows.push({
                data: [
                    testimonial.name,
                    `${testimonial.stars} estrelas`,
                ],
                action: () => editTestimonial(testimonial)
            });
        });

        setData({header, rows});
    }

    const storeTestimonial = () => {
        setLoading(true);

        api.post(`/evidence`, {
            name,
            text,
            stars
        }).then(res => {
            getTestimonials();
            setShowCreate(false);
            toast.success('Depoimento cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editTestimonial = testimonial => {
        setId(testimonial.id);
        setName(testimonial.name);
        setText(testimonial.text);
        setStars(testimonial.stars);
        setShowEdit(true);
    }

    const updateTestimonial = () => {
        setLoading(true);

        api.put(`/evidence/${id}`, {
            name,
            text,
            stars
        }).then(res => {
            getTestimonials();
            setShowEdit(false);
            toast.success('Depoimento atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
        setText('');
        setStars(5);
    }

    const deleteTestimonial = () => {
        setLoading(true);

        api.delete(`/evidence/${testimonialToDelete}`).then(res => {
            setTestimonialToDelete(null);
            setShowEdit(false);
            getTestimonials();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} depoimento`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setTestimonialToDelete(id)}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storeTestimonial() : updateTestimonial()}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} value={name} change={setName} />
                        </div>
                        <div className="col-6">
                            <InputSelect type={`text`} label={`Estrelas`} options={starOptions} value={stars} change={setStars} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <InputTextarea label={`Texto`} value={text} change={setText} rows={5} />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={testimonialToDelete !== null}
                action={deleteTestimonial}
                close={() => setTestimonialToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="testimonial-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar depoimento`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default TestimonialList;