import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import InputRadio from "../../../../components/InputRadio";
import { convertIntToMoney, convertMoenyToInt } from "../../../../helpers/conversions";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";

const QuantityAndPrice = ({param, productParams, setProductParams}) => {
    const [index, setIndex] = useState(null);
    const [total, setTotal] = useState('R$ 0,00');

    useEffect(() => {
        let toIndex = getIndexFromObjectArray(productParams, 'id', param.id);
        setIndex(toIndex);
        getTotal(toIndex);
    }, [productParams]);

    const handleParamChange = (value, i) => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].values[i] = value;
            return toProductParams;
        });
    }

    const getTotal = (toIndex) => {
        if(toIndex === null) return;
        let toTotal = 0;
        let quantity = productParams[toIndex].values[0] ?? 0;
        let value = productParams[toIndex].values[1] ?? 'R$ 0,00';
        value = convertMoenyToInt(value);
        toTotal = value * quantity;
        setTotal( convertIntToMoney(toTotal) );
    }

    return (
        <>
            {index !== null &&
                <>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>{param.name}</h2>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row">
                            <div className="col-sm-3">
                                <Input 
                                    label={`Quantidade`}
                                    change={value => handleParamChange(value, 0)}
                                    value={productParams[index].values[0] ??  0}
                                    mask={`convertToInt`}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Input 
                                    label={`Valor`}
                                    change={value => handleParamChange(value, 1)}
                                    value={productParams[index].values[1] ??  'R$ 0,00'}
                                    mask={`convertToMoney`}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Input 
                                    label={`Total`}
                                    value={total}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default QuantityAndPrice;