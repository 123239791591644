import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectBillingCompany = ({selectedBillingCompany, setSelectedBillingCompany}) => {
    const [loading, setLoading] = useState(true);

    const [billingCompanies, setBillingCompanies] = useState([]);

    useEffect(() => {
        getBillingCompanies();
    }, []);

    const getBillingCompanies = () => {
        setLoading(true);

        api.get(`/company?filters[0][type][contain]=billing`).then(res => {
            setBillingCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <InputSelect
            loading={loading}
            label={`Empresa do faturamento`}
            options={createOptions(billingCompanies, ['code', 'trade_name'], 'id')} 
            value={selectedBillingCompany} 
            change={setSelectedBillingCompany}
        />
    );
}

export default SelectBillingCompany;