export const isAuthenticated = () => localStorage.getItem('token') !== null;
export const getToken = () => localStorage.getItem('token');
export const setToken = token => localStorage.setItem('token', token);
export const setUser = user => localStorage.setItem('user', JSON.stringify(user) );
export const getUser = () => JSON.parse( localStorage.getItem('user') );
export const getPlan = () => JSON.parse( localStorage.getItem('plan') );
export const setPlan = plan => localStorage.setItem('plan', JSON.stringify(plan) );
export const setTheme = theme => localStorage.setItem('theme', JSON.stringify(theme) );
export const getTheme = () => JSON.parse( localStorage.getItem('theme') );
export const setData = data => localStorage.setItem('data', JSON.stringify(data) );
export const getData = () => JSON.parse( localStorage.getItem('data') );

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};

export const hasPermission = permissionsName => {
    permissionsName = typeof permissionsName === 'string' || permissionsName instanceof String ? [permissionsName] : permissionsName;
    
    let has = false;
    let user = getUser();
    let permissions = user?.role?.permissions ? user.role.permissions : [];
    permissions.map(permission => {
        if(permissionsName.includes(permission.name)){
            has = true;
        }
    });

    return has;
}

export const hasPlanFeature = featureValue => {
    let has = false;
    let plan = getPlan();
    let features = plan?.features ?? [];

    features.map(feature => {
        if(feature.value === featureValue){
            has = true;
        }
    });
    
    return has;
}

export const hasQuoteDeletePermission = quote => {
    if(quote === null) return false;
    if(quote.order !== null) return hasPermission('delete_budget_with_order');
    if(quote.proposals.length > 0) return hasPermission('delete_budget_with_proposal');
    return hasPermission('delete_budget_without_proposal');
}

export default isAuthenticated;