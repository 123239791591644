import React, { useEffect, useState } from "react";
import PanelTemplate from "../../templates/Panel";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";
import Box from "../../components/Box";
import Input from "../../components/Input";
import axios from "axios";
import states from "../../seeds/states";
import InputSelect from "../../components/InputSelect";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";

export const Contact = () => {
    const [loading, setLoading] = useState(true);
    const [variableId, setVariableId] = useState(null);
    const [variableValue, setVariableValue] = useState({});

    const [lastCep, setLastCep] = useState('');

    useEffect(() => {
        getVariable();
    }, []);

    useEffect(() => {
        getAddress();
    }, [variableValue])

    const getVariable = () => {
        setLoading(true);

        api.get(`/variable?filters[0][type][equal]=contact`).then(res => {
            let resources = res.data.resources;
            let resource = resources[0] ?? null;

            if(resource){
                setVariableId(resource.id);
                setVariableValue(resource.value ? JSON.parse(resource.value) : {});
            }
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }


    const getAddress = () => {
        let cep = variableValue?.address_zip_code ?? '';
        cep = cep.replace(/\D/g,'');

        if(cep.length === 8 && cep !== lastCep){
            console.log('pegou');
            axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
                let address = res.data;

                handleVariableChange('address_street', address.logradouro);
                handleVariableChange('address_neigh',address.bairro);
                handleVariableChange('address_city',address.localidade);
                handleVariableChange('address_state',address.uf);
            });
        } 

        setLastCep(cep);
    }

    const updateVariable = () => {
        setLoading(true);

        let data = {
            value: JSON.stringify(variableValue)
        };

        api.put(`/variable/${variableId}`, data).then(res => {
            toast.success('Dados atualizados');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateWhatsappUrl = event => {
        event.preventDefault();
        let number = variableValue.whatsapp_number ?? '';
        number = number.replace(/\D/g,'');
        if(number.length < 10){
            alert('Preencha corretamente o número do WhatsApp antes de clicar pra gerar');
            return;
        }
        let url = `https://wa.me/55${number}`;
        handleVariableChange('whatsapp_url', url);
    }

    const handleVariableChange = (key, value) => {
        setVariableValue(prev => ({
            ...prev,
            [key]: value
        }));
    }

    return (
        <>
            <PanelTemplate>
                <h2 className="section-title">Telefones</h2>
                <Box className={`no-margin-bottom`}>
                    <div className="row">
                        <div className="col-3">
                            <Input 
                                label={`Número de telefone`}
                                placeholder={`(11) 4040-4040`}
                                value={variableValue?.telefone ?? ''}
                                change={value => handleVariableChange('telefone', value)}
                                mask={`convertToPhone`}
                            />
                        </div>
                        <div className="col-3">
                            <Input 
                                label={`Número do WhatsApp`}
                                placeholder={`(11) 98080-8080`}
                                value={variableValue?.whatsapp_number ?? ''}
                                change={value => handleVariableChange('whatsapp_number', value)}
                                mask={`convertToPhone`}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`URL para o WhatsApp`}
                                placeholder={`https://wa.me/5511980808080`}
                                value={variableValue?.whatsapp_url ?? ''}
                                change={value => handleVariableChange('whatsapp_url', value)}
                            />
                             <a href={`#`} onClick={generateWhatsappUrl} className="link-pos-input" target="_blank">Gerar url automaticamente</a>
                        </div>
                    </div>
                </Box>

                <h2 className="section-title">E-mail</h2>
                <Box className={`no-margin-bottom`}>
                    <div className="row">
                        <div className="col-3">
                            <Input 
                                label={`E-mail`}
                                placeholder={``}
                                value={variableValue?.email ?? ''}
                                change={value => handleVariableChange('email', value)}
                            />
                        </div>
                    </div>
                </Box>

                <h2 className="section-title">Endereço</h2>
                <Box className={`no-margin-bottom`}>
                    <div className="row">
                        <div className="col-2">
                            <Input 
                                label={`CEP`}
                                placeholder={`00000-000`}
                                value={variableValue?.address_zip_code ?? ''}
                                change={value => handleVariableChange('address_zip_code', value)}
                                mask={`convertToCep`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Logradouro`}
                                value={variableValue?.address_street ?? ''}
                                change={value => handleVariableChange('address_street', value)}
                            />
                        </div>
                        <div className="col-3">
                            <Input 
                                label={`Número`}
                                value={variableValue?.address_number ?? ''}
                                change={value => handleVariableChange('address_number', value)}
                            />
                        </div>
                        <div className="col-3">
                            <Input 
                                label={`Complemento`}
                                value={variableValue?.address_complement ?? ''}
                                change={value => handleVariableChange('address_complement', value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Input 
                                label={`Cidade`}
                                value={variableValue?.address_city ?? ''}
                                change={value => handleVariableChange('address_city', value)}
                            />
                        </div>
                        <div className="col-3">
                            <Input 
                                label={`Bairro`}
                                value={variableValue?.address_neigh ?? ''}
                                change={value => handleVariableChange('address_neigh', value)}
                            />
                        </div>
                        <div className="col-3">
                            <InputSelect
                                label={`Estado`} 
                                options={states} 
                                value={variableValue?.address_state ?? ''} 
                                change={value => handleVariableChange('address_state', value)}
                            />
                        </div>
                    </div>
                </Box>

                <h2 className="section-title">Redes sociais</h2>
                <Box className={`no-margin-bottom`}>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Instagram`}
                                placeholder={`https://instagram.com/exemplo`}
                                value={variableValue?.instagram_url ?? ''}
                                change={value => handleVariableChange('instagram_url', value)}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Facebook`}
                                placeholder={`https://facebook.com/exemplo`}
                                value={variableValue?.facebook_url ?? ''}
                                change={value => handleVariableChange('facebook_url', value)}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Youtube`}
                                placeholder={`https://youtube.com/exemplo`}
                                value={variableValue?.youtube_url ?? ''}
                                change={value => handleVariableChange('youtube_url', value)}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Linkedin`}
                                placeholder={`https://linkedin.com/exemplo`}
                                value={variableValue?.linkedin_url ?? ''}
                                change={value => handleVariableChange('linkedin_url', value)}
                            />
                        </div>
                    </div>
                </Box>
                <h2 className="section-title">Outros</h2>
                <Box className={`no-margin-bottom`}>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Blog`}
                                placeholder={`https://exemplo.com/blog`}
                                value={variableValue?.blog_url ?? ''}
                                change={value => handleVariableChange('blog_url', value)}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Catálogo virtual`}
                                placeholder={`https://exemplo.com/catalogo`}
                                value={variableValue?.catalogo_url ?? ''}
                                change={value => handleVariableChange('catalogo_url', value)}
                            />
                        </div>
                    </div>
                </Box>
                <PageFooter>
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Salvar alterações`}
                        action={updateVariable}
                        loading={loading}
                    />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default Contact;