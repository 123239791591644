import React from "react";

export const Pagination = ({currentPage, lastPage, totalResults, setCurrentPage, hasNextPage, hasPrevPage}) => {
    let pages = [];
    let startPage = currentPage;

    for (let i = 0; i < 2; i++) {
        startPage = startPage > 1 ? startPage - 1 : startPage;
    }

    for (let i = 0; i < 5; i++) {
        let page = startPage + i;
        if(page <= lastPage){
            pages.push(page);
        }
    }


    return (
        <div className="pagination">
            <p>Página {currentPage} de {lastPage} &nbsp; &#8226; &nbsp; {totalResults} resultados</p>
            <ul>
                <li>
                    <button
                        onClick={() => setCurrentPage(1)}
                        disabled={currentPage === 1}
                    >
                        Primeira
                    </button>
                </li>
                <li>
                    <button 
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={!hasPrevPage}
                        className="active"
                    >
                        Anterior
                    </button>
                </li>

                {pages.map(page => (
                    <li key={page}>
                        <button 
                            onClick={() => setCurrentPage(page)}
                            className={`page${page === currentPage ? ' active' : ''}`}
                        >
                            {page}
                        </button>
                    </li>
                ))}

                <li>
                    <button
                        disabled={!hasNextPage}
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        Próxima
                    </button>
                </li>
                <li>
                    <button 
                        onClick={() => setCurrentPage(lastPage)}
                        disabled={currentPage === lastPage}
                        className="active"
                    >
                        Última
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Pagination;