import axios from "axios";
import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createFilterQueryString } from "../../../helpers/helpers";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";
import { convertToCnpj } from "../../../helpers/masks";

export const SelectCompany = ({label, nullable, selectedCompany, setSelectedCompany, isMulti, changeCompanies, fields}) => {
    fields = fields ?? ['trade_name', 'cnpj'];

    const [companies, setCompanies] = useState([]);
    const [search, setSearch] = useState('');
    const [noOptionsText, setNoOptionsText] = useState('');

    window.controller = null;

    const minSearch = 1;

    useEffect(() => {
        console.log('chcompanies', companies)
    }, [companies])

    useEffect(() => {
        searchCompanies();
    }, [search]);
    
    useEffect(() => {
        checkIfNeedToGet();
    }, [selectedCompany]);

    useEffect(() => {
        if(changeCompanies){
            changeCompanies(companies);
        }
    }, [companies]);

    const checkIfNeedToGet = () => {
        let companiesIds = isMulti ? selectedCompany : [selectedCompany];
        let arrayFilters = [];

        companiesIds.map(companyId => {
            if(companyId === null) return;
            if(!companies.some(company => company.id === companyId)){
                arrayFilters.push([
                    `[id][equal]=${companyId}`,
                ]);
            }
        });

        if(arrayFilters.length > 0){
            getCompanies(arrayFilters);
        } else {
            // Para forçar atualização da mensagem "Digite mais x caracteres"
            setSearch('');
        }
    }

    const clearOptions = () => {
        handleSetCompanies([], true)
    }

    const searchCompanies = () => {
        if(search.length < minSearch) {
            let diff = minSearch - search.length;
            setNoOptionsText(`Digite mais ${diff} ${diff === 1 ? 'caracter' : 'caracteres'}`);
            clearOptions();
            if(window.requestController){
                window.requestController.abort();
            };
            return;
        };

        let arrayFilters = [];

        fields.map(field => {
            arrayFilters.push([`[${field}][contain]=${search}`]);
        });

        getCompanies(arrayFilters);        
    }

    const getCompanies = arrayFilters => {
        let stringFilters = createFilterQueryString(arrayFilters);

        if(window.requestController){
            window.requestController.abort();
        };

        window.requestController = new AbortController();
        
        handleSetCompanies([]);
        setNoOptionsText('Carregando...');

        api.get(`/company?${stringFilters}`, {
            signal: window.requestController.signal
        }).then(res => {
            handleSetCompanies(res.data.resources);
        }).catch(error => {
            console.log(error, arrayFilters);
            if(error.code !== undefined){
                renderError(error);
            }
        });
    }

    const handleSetCompanies = (resources, fromClear = false) => {
        let currentSelectedCompanies = getSelectedCompanies();

        let toCompanies = resources.filter(resource => {
            return !currentSelectedCompanies.some(current => current.id === resource.id);
        });
        console.log('handle', [...currentSelectedCompanies, ...toCompanies]);
        setCompanies([...currentSelectedCompanies, ...toCompanies]);
        if(!fromClear){
            setNoOptionsText(toCompanies.length === 0 ? 'Nenhum resultado encontrado' : '');
        }
    }

    const getSelectedCompanies = () => {
        return companies.filter(company => {
            if(isMulti){
                return selectedCompany.includes(company.id);
            } else {
                return selectedCompany === company.id;
            }
        });
    }

    return (
        <InputSelect
            noOptionsText={noOptionsText}
            label={label ?? `Empresa`}
            options={createOptions(companies, fields, 'id', false)} 
            value={selectedCompany} 
            change={setSelectedCompany}
            isMulti={isMulti === true}
            setSearch={value => setSearch(value)}
            
        />
    );
}

export default SelectCompany;