import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import PageFooter from "../../components/PageFooter";
import PanelTemplate from "../../templates/Panel";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { Loading } from "../../components/Loading";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import { createOptions } from "../../helpers/selects";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import { ModalDelete } from "../../components/ModalDelete";
import { hasPermission } from "../../services/auth";

const interval = 99;

const types = [
    {label: 'Milheiro', value: 'thousand'},
    {label: 'Unidade/Intervalo', value: 'unit'}
];

const status = [
    {label: 'Inativa', value: false},
    {label: 'Ativa', value: true}
];

const multiplyColorsOptions = [
    {label: 'Não', value: false},
    {label: 'Sim', value: true}
];

const supplierTypes = [
    {label: 'Pessoa jurídica', value: 'PJ'},
    {label: 'Pessoa física', value: 'PF'}
];

const costTypes = [
    {label: 'Por unidade', value: 'unit'},
    {label: 'Por intervalo', value: 'interval'}
];

export const CustomizationEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [customers, setCustomers] = useState([]);
    
    const [customizationName, setCustomizationName] = useState('');
    const [customizationCalculationPer, setCustomizationCalculationPer] =  useState(null);
    const [customizationStatus, setCustomizationStatus] = useState(null);
    const [customizationCompanies, setCustomizationCompanies] = useState([]);
    const [customizationCustomers, setCustomizationCustomers] = useState([]);
    const [customizationMultiplyColors, setCustomizationMultiplyColors] = useState(null);
    
    const [showCreateCost, setShowCreateCost] = useState(false);

    const [costSupplierType, setCostSupplierType] = useState('PJ');
    const [costCompanyId, setCostCompanyId] = useState(null);
    const [costCustomerId, setCostCustomerId] = useState(null);
    const [costVariations, setCostVariations] = useState([]);

    const [customizationVariationToEdit, setCustomizationVariationToEdit] = useState(null);
    const [customizationVariationCost, setCustomizationVariationCost] = useState('');

    const [customizationVariationToDelete, setCustomizationVariationToDelete] = useState(null);
    const [companyToDelete, setCompanyToDelete] = useState(null);
    const [customerToDelete, setCustomerToDelete] = useState(null);

    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        getCustomization();
        getCompanies();
        getCustomers();
    }, []);

    useEffect(() => {
        getCostVariations();
    }, [costCompanyId, costCustomerId]);

    useEffect(() => {
        if(!showCreateCost) clean();
    }, [showCreateCost])

    useEffect(() => {
        clean();
    }, [costSupplierType])

    const getCompanies = () => {
        api.get(`/company?filters[0][type][contain]=customization`).then(res => {
            setCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        });
    }

    const getCustomers = () => {
        api.get(`/customer?filters[0][type][contain]=customization`).then(res => {
            setCustomers(res.data.resources);
        }).catch(error => {
            renderError(error);
        });
    }

    const getCustomization = () => {
        setLoading(true);

        api.get(`/customization/${id}`).then(res => {
            const customization = res.data.resource;
            setCustomizationName(customization.name);
            setCustomizationStatus(customization.status);
            setCustomizationMultiplyColors(customization.multiply_colors);
            setCustomizationCalculationPer(customization.calculation_per);
            getCustomizationCompanies(customization.companies);
            getCustomizationCustomers(customization.customers);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteCustomization = () => {
        setLoading(true);

        api.delete(`/customization/${id}`).then(res => {
            navigete(`/customization/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getCustomizationCompanies = companies => {
        let toCustomizationCompanies = [];

        companies.map(company => {
            if(toCustomizationCompanies.filter(c => c.id === company.id).length === 0){
                toCustomizationCompanies.push({
                    id: company.id,
                    cnpj: company.cnpj,
                    trade_name: company.trade_name,
                    costs: [company.pivot]
                });
            } else {
                toCustomizationCompanies.map((customizationCompany, index) => {
                    if(customizationCompany.id === company.id){
                        toCustomizationCompanies[index].costs.push(company.pivot);
                        toCustomizationCompanies[index].costs = toCustomizationCompanies[index].costs.sort((a, b) => {
                            if(customizationCalculationPer === 'thousand'){
                                return a.thousand - b.thousand;
                            } else {
                                return a.init_qtd - b.init_qtd;
                            }
                        });
                    }
                });
            }
        });

        setCustomizationCompanies(toCustomizationCompanies);
    }

    const getCustomizationCustomers = customers => {
        let toCustomizationCustomers = [];

        customers.map(customer => {
            if(toCustomizationCustomers.filter(c => c.id === customer.id).length === 0){
                toCustomizationCustomers.push({
                    id: customer.id,
                    cpf: customer.cpf,
                    name: customer.name,
                    costs: [customer.pivot]
                });
            } else {
                toCustomizationCustomers.map((customizationCustomer, index) => {
                    if(customizationCustomer.id === customer.id){
                        toCustomizationCustomers[index].costs.push(customer.pivot);
                        toCustomizationCustomers[index].costs = toCustomizationCustomers[index].costs.sort((a, b) => {
                            if(customizationCalculationPer === 'thousand'){
                                return a.thousand - b.thousand;
                            } else {
                                return a.init_qtd - b.init_qtd;
                            }
                        });
                    }
                });
            }
        });

        setCustomizationCustomers(toCustomizationCustomers);
    }

    const updateCustomization = () => {
        setLoading(true);

        api.put(`/customization/${id}`, {
            name: customizationName,
            status: customizationStatus,
            calculation_per: customizationCalculationPer,
            multiply_colors: customizationMultiplyColors
        }).then(res => {
            toast.success('Gravação atualizada com sucesso');
            getCustomization();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const createCost = () => {
        setShowCreateCost(true);
    }

    const storeCost = () => {
        setLoading(true);

        const variations = [];

        costVariations.map(costVariation => {
            if(customizationCalculationPer === 'thousand'){
                variations.push({
                    cost: convertMoenyToInt(costVariation.cost),
                    cost_type: null,
                    thousand: costVariation.thousand
                });
            } else {
                variations.push({
                    cost: convertMoenyToInt(costVariation.cost),
                    cost_type: costVariation.costType,
                    init_qtd: costVariation.startQuantity,
                    final_qtd: costVariation.endQuantity
                });
            }
        });

        const data = {
            supplier_type: costSupplierType,
            customer_id: costSupplierType === "PF" ?  costCustomerId : null,
            company_id: costSupplierType === "PJ" ?  costCompanyId : null,
            customization_id: id,
            type: customizationCalculationPer,
            variations
        }

        api.post(`/customization/variation`, data).then(res => {
            toast.success('Selo cadastrado com sucesso');
            setShowCreateCost(false);
            getCustomization();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const getCostVariations = () => {
        if(costCompanyId === null && costCustomerId === null){
            setCostVariations([]);
            return;
        }

        const variations = [];
        let costs = [];

        if(costSupplierType === "PJ"){
            const company = customizationCompanies.filter(c => c.id === costCompanyId)[0];
            costs = company?.costs ? company.costs : [];
        } else {
            const customer = customizationCustomers.filter(c => c.id === costCustomerId)[0];
            costs = customer?.costs ? customer.costs : [];
        }

        if(customizationCalculationPer === 'thousand'){
            const thousand = costs.length > 0 ? costs.at(-1).thousand + 1 : 1;
            variations.push({
                cost: 'R$ 0,00',
                costType: 'unit',
                thousand 
            });
        }
        if(customizationCalculationPer === 'unit'){
            const startQuantity = costs.length > 0 ? costs.at(-1).final_qtd + 1 : 1;
            variations.push({
                cost: 'R$ 0,00',
                costType: 'unit',
                startQuantity,
                endQuantity: startQuantity + interval
            });
        }
        setCostVariations(variations);
    }

    const clean = () => {
        setCostCustomerId(null);
        setCostCompanyId(null);
        setCostVariations([]);
    }

    const handleChangeCost = (index, value) => {
        const toCostVariations = costVariations;
        toCostVariations[index]['cost'] = value;
        setCostVariations([...toCostVariations]);
    }

    const handleChangeCostType = (index, value) => {
        const toCostVariations = costVariations;
        toCostVariations[index]['costType'] = value;
        setCostVariations([...toCostVariations]);
    }

    const deleteCostVariation = () => {
        let toCostVariations = costVariations;
        toCostVariations.splice(-1);
        setCostVariations([...toCostVariations]);
    }

    const handleChangeStartQuantity = (index, value) => {
        const toCostVariations = costVariations;
        toCostVariations[index]['startQuantity'] = value;
        setCostVariations([...toCostVariations]);
    }

    const handleChangeEndQuantity = (index, value) => {
        const toCostVariations = costVariations;
        toCostVariations[index]['endQuantity'] = value;
        setCostVariations([...toCostVariations]);
    }

    const addCostVariation = () => {
        const toCostVariations = costVariations;

        if(customizationCalculationPer === 'thousand'){
            const thousand = costVariations[costVariations.length - 1].thousand + 1;
            toCostVariations.push({
                cost: 'R$ 0,00',
                costType: null,
                thousand
            });
        }
        if(customizationCalculationPer === 'unit'){
            const last = costVariations[costVariations.length - 1].endQuantity;
            let endQuantity = 0;

            if(Number.isInteger(last)){
                endQuantity = last + 1;
            }

            const start = costVariations[costVariations.length - 1].thousand + 1;
            toCostVariations.push({
                cost: 'R$ 0,00',
                costType: 'unit',
                startQuantity: endQuantity,
                endQuantity: endQuantity + interval
            })
        }

        setCostVariations([...toCostVariations]);
    }

    const deleteCustomizationVariation = () => {
        setLoading(true);
        
        api.delete(`/customization/variation/${customizationVariationToDelete}`).then(res => {
            setCustomizationVariationToDelete(null);
            getCustomization();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteCompany = () => {
        setLoading(true);
        
        api.delete(`/customization/${id}/company/${companyToDelete}`).then(res => {
            setCompanyToDelete(null);
            getCustomization();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteCustomer = () => {
        setLoading(true);
        
        api.delete(`/customization/${id}/customer/${customerToDelete}`).then(res => {
            setCustomerToDelete(null);
            getCustomization();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editVariationCost = cost => {
        setCustomizationVariationCost( `R$ ${convertIntToMoney(cost.cost)}` );
        setCustomizationVariationToEdit(cost);
    }

    const storeVariationCost = () => {
        setLoading(true);

        api.put(`/customization/variation/${customizationVariationToEdit.id}`, {
            cost: convertMoenyToInt(customizationVariationCost)
        }).then(res => {
            toast.success('Custo atualizado com sucesso');
            getCustomization();
            setCustomizationVariationToEdit(null);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return(
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteCustomization}
                close={() => setConfirmDelete(false)}
                loading={loading}
            />
            <ModalDelete 
                show={companyToDelete !== null}
                action={deleteCompany}
                close={() => setCompanyToDelete(null)}
                loading={loading}
            />
            <ModalDelete 
                show={customerToDelete !== null}
                action={deleteCustomer}
                close={() => setCustomerToDelete(null)}
                loading={loading}
            />
            <ModalDelete 
                show={customizationVariationToDelete !== null}
                action={deleteCustomizationVariation}
                close={() => setCustomizationVariationToDelete(null)}
                loading={loading}
            />
            <Modal
                title={`Cadastrar custo`}
                show={showCreateCost}
                size={`medium`}
                close={() => setShowCreateCost(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeCost}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    {showCreateCost && // força abrir sem nenhuma opção selecionada
                        <>
                            <div className="row">
                                <div className="col">
                                        <InputSelect 
                                            label={`Tipo de fornecedor`}
                                            change={setCostSupplierType}
                                            value={costSupplierType}
                                            options={supplierTypes} 
                                        />
                                </div>
                            </div>
                            {costSupplierType === "PJ" &&
                                <div className="row">
                                    <div className="col">
                                            <InputSelect 
                                                label={`Fornecedor`}
                                                change={setCostCompanyId}
                                                value={costCompanyId}
                                                options={createOptions(companies, 'trade_name', 'id')} 
                                                />
                                    </div>
                                </div>
                            }
                            {costSupplierType === "PF" &&
                                <div className="row">
                                    <div className="col">
                                        <InputSelect 
                                            label={`Fornecedor`}
                                            change={setCostCustomerId}
                                            value={costCustomerId}
                                            options={createOptions(customers, 'name', 'id')} 
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {costVariations.map((costVariation, index) => (
                    <div key={index} className="section">
                        <div className="row">
                            {customizationCalculationPer === 'thousand' &&
                                <div className="col-6">
                                    <Input label={`Milheiro`} value={`${costVariation.thousand}º Milheiro`} disabled={true} />
                                </div>
                            }
                            {customizationCalculationPer === 'unit' &&
                                <>
                                    <div className="col">
                                        <Input 
                                            label={`Quant. inicial`}
                                            value={costVariation.startQuantity}
                                            mask={`convertToInt`}
                                            change={value => handleChangeStartQuantity(index, value)}
                                        />
                                    </div>
                                    <div className="col">
                                        <Input 
                                            label={`Quant. final`}
                                            value={costVariation.endQuantity}
                                            mask={`convertToInt`}
                                            change={value => handleChangeEndQuantity(index, value)}
                                        />
                                    </div>
                                </>
                            }
                            <div className="col">
                               <Input 
                                    label={`Custo`}
                                    // right={customizationCalculationPer === 'unit' ? <span>por unidade</span> : null}
                                    mask={`convertToMoney`} 
                                    value={costVariation.cost} 
                                    change={value => handleChangeCost(index, value)} 
                                />
                            </div>
                            {customizationCalculationPer === 'unit' &&
                                <div className="col">
                                    <InputSelect 
                                        label={`Cálculo por`}
                                        options={costTypes}
                                        value={costVariation.costType} 
                                        change={value => handleChangeCostType(index, value)} 
                                    />
                                </div>
                            }
                            <div className="col-1 col-customization-delete">
                                {index > 0 && index === (costVariations.length -1) &&
                                    <Button type={`delete`} svg={`delete-small`} action={deleteCostVariation} />
                                }
                            </div>
                        </div>
                    </div>
                ))}
                {costVariations.length > 0 &&
                    <Button 
                        type={`link`}
                        size={`small`}
                        text={`Cadastrar mais variações de custo`}
                        full={true}
                        action={addCostVariation}
                        loading={loading}
                    />
                }
            </Modal>
            <Modal
                title={`Alterar custo`}
                show={customizationVariationToEdit !== null}
                loading={loading}
                close={() => setCustomizationVariationToEdit(null)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Salvar alterações`}
                        svg={`save-white`}
                        action={storeVariationCost}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-5">
                            <Input 
                                label={`Custo`}
                                // right={customizationCalculationPer === 'unit' ? <span>por unidade</span> : null}
                                mask={`convertToMoney`} 
                                value={customizationVariationCost} 
                                change={setCustomizationVariationCost} 
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <PanelTemplate id="customization-edit">
                <Loading show={loading} />
                {!loading &&
                    <>
                        <Box>
                            <h2 className="section-title">Informações gerais</h2>
                            <div className="row">
                                <div className="col-3">
                                    <InputSelect label={`Status`} value={customizationStatus} change={setCustomizationStatus} options={status} />
                                </div>
                                <div className="col-3">
                                    <InputSelect isDisabled={true} label={`Cálculo por`} value={customizationCalculationPer} change={setCustomizationCalculationPer} options={types} />
                                </div>
                                <div className="col-3">
                                    <Input type={`text`} label={`Nome`} value={customizationName} change={setCustomizationName} />
                                </div>
                                <div className="col-3">
                                <InputSelect
                                    label={`Multiplicar pelas cores`}
                                    value={customizationMultiplyColors}
                                    change={setCustomizationMultiplyColors}
                                    options={multiplyColorsOptions}
                                />
                                </div>
                            </div>

                            <h2 className="section-title">Relação de custos</h2>
                            {hasPermission('edit_customization') &&
                                <Button type={`secondary`} size={`small`} svg={`plus-blue`} text={`Cadastrar custo`} action={createCost} />
                            }
                            <div className="row">
                                {customizationCompanies.map((customizationCompany, index) => (
                                    <Customization
                                        key={index}
                                        index={index}
                                        customizationCompany={customizationCompany}
                                        customizationCustomer={null}
                                        setCompanyToDelete={setCompanyToDelete}
                                        editVariationCost={editVariationCost}
                                        setCustomizationVariationToDelete={setCustomizationVariationToDelete}
                                        customizationCalculationPer={customizationCalculationPer}
                                    />
                                ))}
                                {customizationCustomers.map((customizationCustomer, index) => (
                                    <Customization
                                        key={index}
                                        index={index}
                                        customizationCompany={null}
                                        customizationCustomer={customizationCustomer}
                                        setCompanyToDelete={setCompanyToDelete}
                                        setCustomerToDelete={setCustomerToDelete}
                                        editVariationCost={editVariationCost}
                                        setCustomizationVariationToDelete={setCustomizationVariationToDelete}
                                        customizationCalculationPer={customizationCalculationPer}
                                    />
                                ))}
                            </div>
                        </Box>
                        <PageFooter spaceBetween={true}>
                            <>
                                {hasPermission('edit_customization') &&
                                    <Button type={`primary`} size={`small`} text={`Salvar alterações`} svg={`save-white`} action={updateCustomization} />
                                }
                                {hasPermission('delete_customization') &&
                                    <Button type={`secondary`} size={`small`} text={`Excluir`} svg={`delete-small`} action={() => setConfirmDelete(true)} />
                                }
                            </>
                        </PageFooter>
                    </>
                }
            </PanelTemplate>
        </>
    );
}

const Customization = ({
    index,
    customizationCompany, 
    customizationCustomer,
    setCompanyToDelete, 
    setCustomerToDelete,
    editVariationCost, 
    setCustomizationVariationToDelete,
    customizationCalculationPer
}) => {
    const costs = customizationCompany ? customizationCompany.costs : customizationCustomer.costs;

    return (
        <div key={index} className="col-4">
            <div className="cost-grouping">
                <div className="header">
                    <span>{customizationCompany ? customizationCompany.trade_name : customizationCustomer.name}</span>
                    {hasPermission('edit_customization') &&
                        <button
                            onClick={() => {
                                if(customizationCompany){
                                    setCompanyToDelete(customizationCompany.id);
                                } else {
                                    setCustomerToDelete(customizationCustomer.id);
                                }
                            }}
                        >
                            <img src={ require(`../../assets/images/svgs/delete-small.svg`).default} />
                        </button>
                    }
                </div>
                <ul>
                    {costs.map((cost, index) => (
                        <li key={index} className={index % 2 === 0 ? 'dark' : ''}>
                            <div className="cost-desc">
                                {customizationCalculationPer === 'thousand' &&
                                    <span>{cost.thousand}º Milheiro</span>
                                }
                                {customizationCalculationPer === 'unit' &&
                                    <span>{cost.init_qtd} a {cost.final_qtd}</span>
                                }
                                <span>
                                    R${convertIntToMoney(cost.cost)}
                                    {customizationCalculationPer === 'unit' &&
                                        <>
                                            {cost.cost_type === 'interval' &&
                                                `/interv`
                                            }
                                             {cost.cost_type !== 'interval' &&
                                                `/un`
                                            }
                                        </>
                                    }
                                </span>
                            </div>
                            {/* <button><img src={ require(`../../assets/images/svgs/edit.svg`).default} /></button> */}
                            {hasPermission('edit_customization') &&
                                <button
                                    onClick={() => editVariationCost(cost)}
                                >
                                    <img src={ require(`../../assets/images/svgs/edit.svg`).default} />
                                </button>
                            }
                            {hasPermission('edit_customization') &&
                                <button
                                disabled={index !== (costs.length - 1)}
                                onClick={() => setCustomizationVariationToDelete(cost.id)}
                                >
                                    <img src={ require(`../../assets/images/svgs/delete-small.svg`).default} />
                                </button>
                            }
                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
    );
}

export default CustomizationEdit;