import React, { useEffect, useState } from "react";
import moment from 'moment';
import PanelTemplate from "../../templates/Panel";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import Select from "react-select";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import Modal from "../../components/Modal";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { createOptions } from "../../helpers/selects";
import Input from "../../components/Input";
import { DndContext, PointerSensor, closestCenter, useSensor } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import { toast } from "react-toastify";
import { generateUniqueId } from "../../helpers/helpers";
import SelectCustomerCompany from "../../components/selects/SelectCustomerCompany";
import { ModalCreateCompany } from "../../components/ModalCreateCompany";
import { ModalCreateCustomer } from "../../components/ModalCreateCustomer";
import SelectCustomer from "../../components/selects/SelectCustomer";
import InputSelect from "../../components/InputSelect";
import { convertToMoney } from "../../helpers/masks";
import InputDate from "../../components/InputDate";
import { Link } from "react-router-dom";
import temperatureOptions from "../../seeds/crm/temperatures";
import statusOptions from "../../seeds/crm/status";
import segmentsOptions from "../../seeds/segments";
import states from "../../seeds/states";
import SelectCompany from "../../components/selects/SelectCompany";

const orderByOptions = [
    {label: 'Mais tempo parada na etapa', value: 'entered_stage_at,asc'},
    {label: 'Menos tempo parada na etapa', value: 'entered_stage_at,desc'},
    {label: 'Maior valor', value: 'amount,asc'},
    {label: 'Menor valor', value: 'amount,desc'},
    {label: 'Previsão de fechamento mais longe', value: 'closing_date,desc'},
    {label: 'Previsão de fechamento mais perto', value: 'closing_date,asc'},
    {label: 'Data da criação do card mais recente', value: 'created_at,desc'},
    {label: 'Data da criação do card mais antiga', value: 'created_at,asc'}
];

const originOptions = [
    {label: 'Todas', value: ''},
    {label: 'Site', value: 'site'},
    {label: 'Sistema', value: 'sistema'}
];

const emailStatusOptions = [
    {label: 'Todos', value: ''},
    {label: 'Somente lidos', value: 'read'},
    {label: 'Não lidos', value: 'unread'}
];

const activityStatusOptions = [
    {label: 'Todas', value: ''},
    {label: 'Com atividades em aberto', value: 'open'}
];

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '35px',
        height: '35px',
        borderRadius: '3px',
        boxShadow: state.isFocused ? null : null,
        fontSize: '14px',
        width: '180px'
    }),
}

const Card = ({ card, stage, moveCard, findCard, updateCard }) => {
    const [status, setStatus] = useState(card.status);

    const originalIndex = findCard(stage, card.id).index;

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: { id: card.id, originalIndex, stageId: stage.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (dropResult, monitor) => {
            // const { id: droppedId, originalIndex } = monitor.getCard();
            // const didDrop = monitor.didDrop();
            // if (!didDrop) {
            //     moveCard(droppedId, originalIndex, card.pipeline_stage_id);
            // }
        }
    });

    const opacity = isDragging ? 0 : 1;

    const updateCardParam = async (param, value) => {
        updateCard({
            ...card,
            [param]: value
        });
    }

    return (
        <div ref={drag} style={{ opacity }} className={`card ${card.color}${card?.updating === true ? ' updating' : ''}`}>
            <Link to={`/crm/card/${card.id}`}>
                {card.label &&
                    <div className="card-label" style={{ backgroundColor: card.label.background}}>
                        <span style={{ color: card.label.color}}>{card.label.name}</span>
                    </div>
                }
                {card.company &&
                    <div className="card-info">
                        {/* <img src={ require(`../../assets/images/svgs/card-company.svg`).default } /> */}
                        <span><b>{card.company.trade_name}</b></span>
                    </div>
                }
                {/* <div className="card-info">
                    <img src={ require(`../../assets/images/svgs/card-origin.svg`).default } />
                    <span>{card.origin}</span>
                </div> */}
                {card.customer &&
                    <div className="card-info">
                        {/* <img src={ require(`../../assets/images/svgs/card-user.svg`).default } /> */}
                        <span>{card.customer.name}</span>
                    </div>
                }
                {/* <div className="card-info">
                    <img src={ require(`../../assets/images/svgs/card-date-created-at.svg`).default } />
                    <span>{moment(card.created_at).format('DD/MM/YYYY HH:mm')}</span>
                </div> */}
                {/* <div className="card-info">
                    <img src={ require(`../../assets/images/svgs/card-date-updated-at.svg`).default } />
                    <span>{moment(card.updated_at).format('DD/MM/YYYY HH:mm')}</span>
                </div> */}
                <div className="card-info"  style={{ marginTop: 8, marginBottom: 5}}>
                    <img src={ require(`../../assets/images/svgs/card-user.svg`).default } />
                    <span>{card?.admin?.name ?? 'Sem responsável'}</span>
                </div>
                {card?.company?.state && card?.company?.city &&
                    <div className="card-info">
                        <img src={ require(`../../assets/images/svgs/card-location.svg`).default } />
                        <span>{`${card?.company?.city} / ${card?.company?.state}`}</span>
                    </div>
                }
                <div className="card-info">
                    <strong>{convertIntToMoney(card.amount, true)}</strong>
                </div>
                <span className="activity">2 dias para ligação</span>
                <hr/>
                <div className="card-info">
                    <span className="days">{`${card.days_in_stage} dia${card.days_in_stage !== 1 ? 's' : ''} aqui`}</span>
                </div>
                
            </Link>
            <select 
                value={status}
                onClick={event => event.stopPropagation()}
                onChange={event => {
                    let value = event.target.value;
                    setStatus(value)
                    updateCardParam('status', value);
                }}
            >
                {statusOptions.map((status, index) => (
                    <option key={index} value={status.value}>{status.label}</option>
                ))}
            </select>
        </div>
        
    );
};

const Column = ({ index, totals, stage, moveCard, findCard, updateCard }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'card',
        drop: (card, monitor) => {
            console.log('here', card, stage)
            moveCard(card.id, card.stageId, stage.id);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    return (
        <div ref={drop} className={`column ${isOver ? 'hovered' : ''}`}>
            <div className="column-title">
                <h2>{stage.name}</h2>
                <span>{convertIntToMoney(totals.totalStages[index]?.totalAmount ?? 0, true)}</span>
                <span>Contagem {totals.totalStages[index]?.totalCards ?? 0}</span>
            </div>
            {stage.cards.map(card => (
                <Card
                    key={card.id}
                    card={card}
                    stage={stage}
                    moveCard={moveCard}
                    findCard={findCard}
                    updateCard={updateCard}
                />
            ))}
        </div>
    );
};

export const CRMList = () => {
    const [option, setOption] = useState('');
    const [gettingPipelines, setGettingPipelines] = useState(true);

    const [pipelines, setPipelines] = useState([]);
    const [pipeline, setPipeline] = useState(undefined);

    const [pipelineToEdit, setPipelineToEdit] = useState(null);

    const [gettingStages, setGettingStages] = useState(false);
    const [stages, setStages] = useState([]);

    const [cardToCreate, setCardToCreate] = useState(null);
    const [showCreateCompany, setShowCreateCompany] = useState(false);
    const [showCreateCustomer, setShowCreateCustomer] = useState(false);

    const [creatingCard, setCreatingCard] = useState(false);
    const [updatingPipeline, setUpdatingPipeline] = useState(false);

    const [editFilter, setEditFilter] = useState(false);

    const [filter, setFilter] = useState({
        activity_status: null,
        company_id: null,
        customer_id: null,
        budget_code: '',
        created_at_start: '',
        created_at_final: '',
        closing_date_start: '',
        closing_date_final: '',
        amount_start: '',
        amount_final: '',
    });

    const [totals, setTotals] = useState({
        totalPipelineCards: 0,
        totalStages: []
    });

    useEffect(() => {
        getPipelines();
    }, []);

    useEffect(() => {
        if(pipeline?.id){
            getStages(pipeline.id);
        }
    }, [pipeline]);

    useEffect(() => {
        getTotals();
    }, [stages])

    const getTotals = () => {
        let totalPipelineCards = 0;
        const totalStages = stages.map(stage => {
            const totalCards = stage.cards.length;
            const totalAmount = stage.cards.reduce((acc, card) => acc + card.amount, 0);
            totalPipelineCards += totalCards;

            return {
                stageId: stage.id,
                totalCards: totalCards,
                totalAmount: totalAmount
            };
        });
        console.log({
            totalPipelineCards,
            totalStages
        })
        setTotals({
            totalPipelineCards,
            totalStages
        });
    }

    const getPipelines = () => {
        setGettingPipelines(true);

        api.get(`/pipeline`).then(res => {
            let toPipelines = res.data.resources;
            setPipelines(toPipelines);
            let pipelineIndex = toPipelines.findIndex(toPipeline => toPipeline.id === pipeline?.id);
            pipelineIndex = pipelineIndex !== -1 ? pipelineIndex : 0;
            setPipeline(toPipelines[pipelineIndex] ?? undefined);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingPipelines(false));
    }

    const getStages = pipelineId => {
        setStages([]);
        setGettingStages(true);

        api.get(`/pipeline-stage?pipeline_id=${pipelineId}`).then(res => {
            setStages(res.data.stages);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingStages(false));
    }

    const moveCard = (cardId, oldStageId, newStageId) => {
        if(oldStageId !== newStageId){
            const oldStageIndex = stages.findIndex(stage => stage.id === oldStageId);
            let card = stages[oldStageIndex].cards.find(card => card.id === cardId);

            card = {
                ...card,
                pipeline_stage_id: newStageId
            };

            updateCard(card);
        }  
    };

    const updateCard = async card => {
        const oldStages = JSON.parse(JSON.stringify(stages));

        let newStageIndex = null;
        let currentStage = null;
        let currentStageIndex = null;
        let currentCardIndex = null;
        let newCardIndex = null;
        let updatedStages = stages;

        stages.filter((mapStage, indexMapStage) => {
            if(mapStage.id === card.pipeline_stage_id){
                newStageIndex = indexMapStage;
            }

            mapStage.cards.map((mapCard, indexMapCard) => {
                if(mapCard.id === card.id){
                    currentStage = mapStage;
                    currentStageIndex = indexMapStage;
                    currentCardIndex = indexMapCard;
                }
            })
        });

        if(currentStage.id !== card.pipeline_stage_id){
            updatedStages[currentStageIndex].cards.splice(currentCardIndex, 1);
            updatedStages[newStageIndex].cards.unshift({...card, updating: true});
            newCardIndex = 0;
        } else {
            newCardIndex = currentCardIndex;
            updatedStages[newStageIndex].cards[newCardIndex] = {...card, updating: true};
        }

        updatedStages = JSON.parse(JSON.stringify(updatedStages));
        setStages( updatedStages );

        let res = await api.put(`/pipeline-stage-card/${card.id}`, {
            temperature: card.temperature,
            amount: card.amount,
            customer_id: card.customer_id,
            company_id: card.company_id,
            pipeline_stage_id: card.pipeline_stage_id,
            admin_id: card.admin_id,
            status: card.status,
            origin: card.origin,
            closing_date: card.closing_date
        }).then(res => {
            updatedStages[newStageIndex].cards[newCardIndex] = {...res.data.resource, updating: false};
            updatedStages = JSON.parse(JSON.stringify(updatedStages));
            setStages(updatedStages );
            return true;
        }).catch(error => {
            setStages(oldStages);
            renderError(error);
            return false;
        });

        return res;
    } 

    const findCard = (stage, cardId) => {
        try {
            const card = stage.cards.find(card => card.id === cardId);
            return {
                card,
                index: stage.cards.indexOf(card)
            };
        } catch (error) {
            console.log('error', stage);
        }
    };

    const handleChangePipeline = option => {
        let toPipeline = pipelines.filter(pipeline => pipeline.id === option.value)[0];
        setPipeline(toPipeline);
    }

    const sensors = [useSensor(SmartPointerSensor)];

    const handleDragEnd = ({active, over}) => {
        let stages = [...pipelineToEdit.stages];
        const oldIndex = stages.findIndex(stage => stage.id === active.id);
        const newIndex = stages.findIndex(stage => stage.id === over.id);

        let newStages = arrayMove(stages, oldIndex, newIndex);

        setPipelineToEdit(prev => ({
            ...prev,
            stages: newStages
        }));
    }

    const updatePipeline = () => {
        setUpdatingPipeline(true);

        let pipeline = {
            ...pipelineToEdit,
            stages: pipelineToEdit.stages.map(stage => ({
                ...stage,
                id: stage.id.includes('previd') ? null : stage.id
            }))
        }
        api[pipeline.id ? 'put' : 'post'](`/pipeline/${pipelineToEdit.id ?? ''}`, pipeline).then(res => {
            getPipelines();
            getStages(pipelineToEdit.id);
            setPipelineToEdit(null);
            toast.success('Funil atualizado com sucesso');
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdatingPipeline(false));
    }

    const deletePipeline = () => {
        setUpdatingPipeline(true);
        api.delete(`/pipeline/${pipelineToEdit.id}`).then(res => {
            setPipeline(undefined);
            getPipelines();
            toast.success('Funil excluído com sucesso');
            setPipelineToEdit(null);
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdatingPipeline(false));
    }

    const deleteStage = stageId => {
        setPipelineToEdit(prev => ({
            ...prev,
            stages: prev.stages.filter(stage => stage.id !== stageId)
        }));
    }

    const addStage = () => {
        setPipelineToEdit(prev => ({
            ...prev,
            stages: [...prev.stages, {
                id: `previd-${generateUniqueId()}`, 
                name: '', 
                max_days_in_stage: '1', 
                // can_return: true
            }]
        }));
    }

    const updateStageParam = (stageId, param, value) => {
        setPipelineToEdit(prev => ({
            ...prev,
            stages: prev.stages.map(stage => {
                if(stage.id === stageId){
                    return {
                        ...stage,
                        [param]: value
                    };
                } else {
                    return stage;
                }
            })
        }));
    }

    const createPipeline = () => {
        setPipelineToEdit({
            id: null,
            name: '',
            stages: []
        });
    }

    const createCard = () => {
        setCardToCreate({
            temperature: "warm",
            customer_id: null,
            company_id: null,
            pipeline_stage_id: null,
            status: "open",
            origin: "sistema",
            amount_formatted: "R$ 0,00",
            closing_date: null
        });
    }

    const updateFromStoredCompany = company => {
        setCardToCreate(prev => ({
            ...prev,
            company_id: company.id
        }));
        setShowCreateCompany(false);
    }

    const updateFromStoredCustomer = customer => {
        setCardToCreate(prev => ({
            ...prev,
            customer_id: customer.id
        }));
        setShowCreateCustomer(false);
    }

    const storeCard = () => {
        setCreatingCard(true);
        api.post(`/pipeline-stage-card`, {
            ...cardToCreate,
            amount: convertMoenyToInt(cardToCreate.amount_formatted),
            closing_date: cardToCreate.closing_date ? moment(cardToCreate.closing_date).format('YYYY-MM-DD') : null
        }).then(res => {
            toast.success('Card criado com sucesso');
            setCardToCreate(null);
            getStages(pipeline.id);
        }).catch(error => {
            renderError(error);
        }).then(() => setCreatingCard(false));
    }

    const handleFilterChange = (param, value) => {
        setFilter(prev => ({
            ...prev,
            [param]: value
        }));
    }

    const handleClear = () => {

    }

    const handleFilter = () => {

    }

    return (
        <PanelTemplate id="crm">
            <ModalCreateCustomer
                show={showCreateCustomer}
                close={() => setShowCreateCustomer(false)}
                customerStored={updateFromStoredCustomer}
                showSuccess={false}
                type={['customer']}
                companies={cardToCreate?.company_id ? [cardToCreate.company_id] : null}
                hideCompany={true}
            />
            <ModalCreateCompany
                show={showCreateCompany}
                close={() => setShowCreateCompany(false)}
                companyStored={updateFromStoredCompany}
                showSuccess={false}
                type={['customer']}
            />
            {editFilter &&
                <Modal
                    show={true}o
                    close={() => setEditFilter(false)}
                    title={`Filtrar e ordenar`}
                    size={`big`}
                    footer={
                        <>
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Limpar filtros`}
                                action={handleClear}
                            />
                            <Button
                                type={`primary`}
                                size={`small`}
                                text={`Filtrar resultados`}
                                action={handleFilter}
                            />
                            
                        </>
                    }
                >
                    <div className="section">
                        <h2 className="section-title" style={{ marginBottom: 15}}>Ordenar</h2>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Ordernar por`} options={orderByOptions} value={filter?.order_by} change={value => handleFilterChange('order_by', value)} />
                            </div>
                            {/* <div className="col-4">
                                <InputSelect label={`Ordem`} options={orderDirOptions} value={filter?.order_dir} change={value => handleFilterChange('order_dir', value)} />
                            </div> */}
                        </div>

                        <h2 className="section-title" style={{ marginBottom: 15}}>Filtrar</h2>
                        <div className="row">
                            {/* <div className="col-4">
                                <InputSelect 
                                    label={`Origem`} 
                                    options={originOptions} 
                                    value={filter?.origin} 
                                    change={value => handleFilterChange('origin', value)} 
                                />
                            </div> */}
                            <div className="col-6">
                                <InputSelect 
                                    label={`Status das atividades`} 
                                    options={activityStatusOptions} 
                                    value={filter?.activity_status} 
                                    change={value => handleFilterChange('activity_status', value)} 
                                />
                            </div>
                            <div className="col-6">
                                <Input 
                                    label={`Núm. do orçamento`} 
                                    value={filter?.budget_code} 
                                    change={value => handleFilterChange('budget_code', value)}
                                />
                            </div>
                            {/* <div className="col-4">
                                <InputSelect 
                                    label={`Status dos e-mails`} 
                                    options={emailStatusOptions} 
                                    value={filter?.email_status} 
                                    change={value => handleFilterChange('email_status', value)}
                                />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <SelectCompany
                                    selectedCompany={filter.company_id}
                                    setSelectedCompany={value => handleFilterChange('company_id', value)}
                                />
                            </div>
                            <div className="col-6">
                                <SelectCustomer
                                    selectedCustomer={filter.customer_id}
                                    setSelectedCustomer={value => handleFilterChange('customer_id', value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-4">
                                <InputSelect 
                                    label={`Segmento`} 
                                    options={segmentsOptions} 
                                    value={filter?.segment} 
                                    change={value => handleFilterChange('segment', value)}
                                />
                            </div> */}
                            {/* <div className="col-4">
                                <InputSelect 
                                    label={`Local da entrega`} 
                                    options={states} 
                                    value={filter?.segment} 
                                    change={value => handleFilterChange('segment', value)}
                                />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="filter-group-label">Data da criação do card</label>
                                <div className="row">
                                    <div className="col-6">
                                        <InputDate
                                            label={`A partir de`}
                                            format={`YYYY-MM-DD`} 
                                            value={filter.created_at_start}
                                            change={value => handleFilterChange('created_at_start', value)}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputDate
                                            label={`Até`}
                                            format={`YYYY-MM-DD`} 
                                            value={filter.created_at_final}
                                            change={value => handleFilterChange('created_at_final', value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <label className="filter-group-label">Previsão de fechamento</label>
                                <div className="row">
                                    <div className="col-6">
                                        <InputDate
                                            label={`A partir de`}
                                            format={`YYYY-MM-DD`} 
                                            value={filter.closing_date_start}
                                            change={value => handleFilterChange('closing_date_start', value)}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputDate
                                            label={`Até`}
                                            format={`YYYY-MM-DD`} 
                                            value={filter.closing_date_final}
                                            change={value => handleFilterChange('closing_date_final', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label className="filter-group-label">Valor total do card</label>
                                <div className="row">
                                    <div className="col-6">
                                        <Input
                                            label={`A partir de`}
                                            value={filter.amount_start} 
                                            change={value => handleFilterChange('amount_start', value)}
                                            mask={`convertToMoney`}
                                        />
                                    </div>
                                    {/* <div className="col-1 filter-group-divisor">até</div> */}
                                    <div className="col-6">
                                        <Input 
                                            label={`Até`}
                                            value={filter.amount_final} 
                                            change={value => handleFilterChange('amount_final', value)}
                                            mask={`convertToMoney`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {cardToCreate !== null &&
                <Modal
                    show={!showCreateCompany && !showCreateCustomer}
                    close={() => setCardToCreate(null)}
                    title={`Criar card`}
                    size={`small`}
                    footer={<Button
                        type={`primary`}
                        size={`small`}
                        text={`Salvar alterações`}
                        svg={`save-white`}
                        action={storeCard}
                        loading={creatingCard} 
                    />}
                >
                    <div className="section">
                        <InputSelect 
                            label={`Etapa do funil *`} 
                            options={createOptions(pipeline.stages, 'name', 'id')}
                            value={cardToCreate?.pipeline_stage_id ?? null}
                            change={value => {
                                setCardToCreate(prev => ({
                                    ...prev,
                                    pipeline_stage_id: value
                                }));
                            }}
                        />

                        {/* <InputSelect 
                            label={`Temperatura *`} 
                            options={temperatureOptions}
                            value={cardToCreate?.temperature ?? null}
                            change={value => {
                                setCardToCreate(prev => ({
                                    ...prev,
                                    temperature: value
                                }));
                            }}
                        /> */}

                        <Input 
                            label={`Previsão do preço final`} 
                            value={cardToCreate?.amount_formatted ?? null}
                            change={value => {
                                setCardToCreate(prev => ({
                                    ...prev,
                                    amount_formatted: convertToMoney(value)
                                }));
                            }}
                        />

                        <InputDate
                            value={cardToCreate?.closing_date ?? null}
                            change={value => {
                                setCardToCreate(prev => ({
                                    ...prev,
                                    closing_date: value
                                }));
                            }}
                            label={`Previsão de fechamento`}
                        />

                        <div className="row">
                            <div className="col">
                                <SelectCustomerCompany
                                    selectedCustomerCompany={cardToCreate.company_id}
                                    setSelectedCustomerCompany={value => {
                                        setCardToCreate(prev => ({
                                            ...prev,
                                            company_id: value
                                        }));
                                    }}
                                />
                                <a 
                                    href="#" 
                                    className="link-pos-input"
                                    onClick={e => {
                                        e.preventDefault();
                                        setShowCreateCompany(true);
                                    }}
                                >
                                    Cadastrar nova empresa
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <SelectCustomer
                                    selectedCustomer={cardToCreate.customer_id}
                                    setSelectedCustomer={value => {
                                        setCardToCreate(prev => ({
                                            ...prev,
                                            customer_id: value
                                        }));
                                    }}
                                    companyId={cardToCreate?.companyId ?? null}
                                    relatedWithCompany={cardToCreate?.companyId ? true : false}
                                />
                                <a 
                                    href="#" 
                                    className="link-pos-input"
                                    onClick={e => {
                                        e.preventDefault();
                                        setShowCreateCustomer(true);
                                    }}
                                >
                                    Cadastrar nova pessoa
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {pipelineToEdit !== null &&
                <Modal
                    show={true}
                    close={() => setPipelineToEdit(null)}
                    title={`${pipelineToEdit.id ? 'Editar' : 'Criar'} funil`}
                    size={`big`}
                    footer={
                        <>
                            {pipelineToEdit.id &&
                                <Button
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete-small`}
                                    text={`Excluir funil`}
                                    action={() => deletePipeline()}
                                    loading={updatingPipeline}
                                />
                            }
                            <Button
                                type={`primary`}
                                size={`small`}
                                text={`Salvar alterações`}
                                svg={`save-white`}
                                action={updatePipeline}
                                loading={updatingPipeline} 
                            />
                        </>
                    }
                >
                    <div className="section">
                        <Input label={`Nome do funil`} value={pipelineToEdit.name} change={value => setPipelineToEdit(prev => ({...prev, name: value}))} />
                    </div>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext 
                            items={pipelineToEdit.stages.map(stage => stage.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            {pipelineToEdit?.stages && pipelineToEdit.stages.map((stage, index) => (
                                <SortableCard
                                    key={stage.id} 
                                    index={index} 
                                    totals={totals} 
                                    stage={stage} 
                                    deleteStage={deleteStage} 
                                    updateStageParam={updateStageParam} 
                                    editing={pipelineToEdit.id !== null}
                                />
                            ))}
                        </SortableContext>
                        <Button 
                            type={`link`}
                            size={`small`}
                            full={true}
                            text={`Adicionar nova etapa`}
                            action={addStage}
                            loading={updatingPipeline}
                        />
                    </DndContext>
                </Modal>
            }
            <PageHeader
                left={
                    <>
                        <Select options={createOptions(pipelines, 'name', 'id')} value={pipeline ? {label: pipeline.name, value: pipeline.id} : null} onChange={handleChangePipeline} styles={customStyles} />
                        {pipelines.length > 0 &&
                            <>
                                <Button
                                    type={`primary`}
                                    size={`small`}
                                    svg={`plus-white`}
                                    text={`Card`}
                                    action={createCard}
                                />
                                <Button
                                    type={`primary`}
                                    size={`small`}
                                    svg={`edit-white`}
                                    text={`Editar funil`}
                                    action={() => setPipelineToEdit(pipeline)}
                                />
                            </>
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            svg={`plus-white`}
                            text={`Funil`}
                            action={createPipeline}
                        />
                        {/* <Button
                            type={`primary`}
                            size={`small`}
                            svg={`export`}
                            text={`Exportar`}
                            action={() => null}
                        />
                        <Button
                            type={`primary`}
                            size={`small`}
                            svg={`import`}
                            text={`Importar`}
                            action={() => null}
                        /> */}
                    </>
                }

                right={
                    <>
                        <p>{totals.totalPipelineCards} card{totals.totalPipelineCards === 1 ? '' : 's'} encontrado{totals.totalPipelineCards === 1 ? '' : 's'}</p>
                        <Button
                            type={`primary`}
                            size={`small`}
                            svg={`filter-white`}
                            text={`Filtrar e ordenar`}
                            action={() => setEditFilter(true)}
                        />
                    </>
                }
            />

            <div className="columns">
                {gettingStages &&
                    <div className="column">
                        <p style={{ marginLeft: 10, marginTop: 20, marginBottom: 20}}>Carregando...</p>
                    </div>
                }
                {!gettingStages && stages.map((stage, index) => (
                    <Column
                        key={stage.id}
                        index={index}
                        stage={stage}
                        totals={totals}
                        moveCard={moveCard}
                        findCard={findCard}
                        updateCard={updateCard}
                    />
                ))}
            </div>
        </PanelTemplate>
    );
}

const  SortableCard = ({index, totals, stage, deleteStage, updateStageParam, editing}) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: stage.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      listStyle: 'none'
    };
    
    let totalCards = totals?.totalStages[index]?.totalCards ?? 0;

    return (
      <div className="section" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div className="row">
            <div className="col-1">
                <div className="input-group">

                <Button type={`drag`} svg={`drag`} />
                </div>
            </div>
            <div className="col">
                <Input label={`Nome da etapa`} value={stage.name} change={value => updateStageParam(stage.id, 'name', value)} />
            </div>
            <div className="col-3">
                <Input label={`Limite de dias`} value={stage.max_days_in_stage} change={value => updateStageParam(stage.id, 'max_days_in_stage', value)} mask={`convertToInt`} />
            </div>
            {/* <div className="col-2">
                <InputSelect 
                    label={`Pode voltar`} 
                    value={stage.can_return} 
                    options={[{ label: 'Sim', value: true }, { label: 'Não', value: false}]}
                    change={value => updateStageParam(stage.id, 'can_return', value)}
                />
            </div> */}
            {editing &&
                <div className="col-2">
                    <Input label={`Cards`} value={`${totalCards}`} disabled={true} />
                </div>
            }
            <div className="col-1 delete-stage">
                <Button type={`delete`} svg={`delete-small`} action={() => deleteStage(stage.id)} />
            </div>
        </div>
    </div>
    );
}

export class SmartPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: "onPointerDown",
            handler: ({ nativeEvent: event }) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target)
                ) {
                    return false;
                }

                return true;
            },
        },
    ];
}

function isInteractiveElement(element) {
    return !element.classList.contains('drag');
}


export default CRMList;
