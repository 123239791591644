import React, { useEffect, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";
import Input from "../Input";
import InputSelect from "../InputSelect";
import api from "../../services/api";
import { toast } from "react-toastify";
import { renderError } from "../../helpers/errors";
import { hasPermission } from "../../services/auth";
import origins from "../../seeds/origins";
import SelectCustomerCompany from "../selects/SelectCustomerCompany";

const types = [
    {label: 'Cliente', value: 'customer'}
];


if(hasPermission('company_supplier')){
    types.push({label: 'Fornecedor de gravação', value: 'customization'});
    types.push({label: 'Fornecedor de produto', value: 'product'});
}
if(hasPermission('company_billing')){
    types.push({label: 'Faturamento', value: 'billing'});
}


export const ModalCreateCustomer = ({show, close, type, companies, hideCompany, showSuccess, customerStored}) => {
    const [loading, setLoading] = useState(false);

    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerCompanies, setCustomerCampanies] = useState([]);
    const [customerType, setCustomerType] = useState([]);

    useEffect(() => {
        if(!show) clean();
    }, [show]);

    const storeCustomer = () => {
        setLoading(true);

        api.post(`/customer`, {
            name: customerName,
            email: customerEmail,
            phone: customerPhone,
            company_ids: companies ?? customerCompanies,
            type: type ?? customerType
            // admin_id: customerAdmin,
        }).then(res => {
            {showSuccess !== false &&
                toast.success('Pessoa cadastrada com sucesso');
            }
            if (typeof customerStored === 'function') {
                customerStored(res.data.resource);
            }
            setLoading(false);
            clean();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setCustomerName('');
        setCustomerEmail('');
        setCustomerPhone('');
        setCustomerCampanies([]);
        setCustomerType([]);
    }

    return (
        <Modal
            title={`Cadastrar pessoa`}
            show={show}
            close={close}
            footer={
                <Button 
                    type={`primary`}
                    size={`small`}
                    text={`Cadastrar`}
                    svg={`save-white`}
                    action={storeCustomer}
                    loading={loading}
                />
            }
        >
            <div className="section">
                <div className="row">
                    <div className="col">
                        <Input type={`text`} label={`Nome`} change={setCustomerName} value={customerName}/>
                    </div>
                </div>   
                <div className="row">
                    <div className="col">
                        <Input type={`text`} label={`E-mail`} change={setCustomerEmail} value={customerEmail} />
                    </div>
                </div>   
                <div className="row">
                    <div className="col">
                        <Input type={`text`} label={`Telefone`} change={setCustomerPhone} value={customerPhone} mask={`convertToPhone`} />
                    </div>
                </div>   
                {!companies && hideCompany !== true &&
                    <div className="row">
                        <div className="col">
                            <SelectCustomerCompany
                                isMulti={true}
                                selectedCustomerCompany={customerCompanies}
                                setSelectedCustomerCompany={setCustomerCampanies}
                                />
                        </div>
                    </div>   
                }
                {!type &&
                    <div className="row">
                        <div className="col">
                            <InputSelect label={`Marcadores`} options={types} value={customerType} change={setCustomerType} isMulti={true} />
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
}

export default Modal;