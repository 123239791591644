import React, { useEffect, useState } from "react";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import InputSelect from "../../../../components/InputSelect";
import { createOptions } from "../../../../helpers/selects";

const ConditionalPercentageOptions = ({options, setOptions, params, relationalParam}) => {
    const [relationalOptions, setRelationalOptions] = useState([]);

    const base = {
        value: '0,0000 %',
        related_percentage: ''
    };

    useEffect(() => {
        if(options.length === 0){
            setOptions([base]);
        }
    }, []);

    useEffect(() => {
        getRelationalOptions();
    }, [relationalParam]);

    const getRelationalOptions = () => {
        let filteredParams = params.filter(param => param.id === relationalParam);
        let toOptions = [];

        if(filteredParams.length > 0){
            if(filteredParams[0].options.length > 0){
                toOptions = filteredParams[0].options;
            }
        }

        setRelationalOptions(toOptions);
    }

    const addOptions = () => {
        let toOptions = [...options];
        toOptions.push(base);
        setOptions(toOptions);
    }

    const deleteOption = index => {
        let toOptions = [];
        options.map((option, i) => {
            if(index !== i) toOptions.push(option);
        });
        setOptions(toOptions);
    }

    return (
        <>
            {options.map((option, index) => (
                <div key={index} className="section">
                    <div className="row">
                        <div className="col-6">
                            {relationalOptions.length > 0 &&
                                <InputSelect 
                                label={`Porcentagem relacional`}
                                options={createOptions(relationalOptions, 'description', 'value')}
                                value={option.related_percentage}
                                change={value => setOptions(prev => {
                                    prev[index].related_percentage = value;
                                    return [...prev];
                                })}
                                />
                            }
                            {relationalOptions.length === 0 &&
                                <p>Nenhuma porcentagem relacional cadastrada.</p>
                            }
                        </div>
                        <div className="col-5">
                            <Input 
                                type={`text`}
                                label={`Porcentagem`} 
                                value={option.value} 
                                change={value => setOptions(prev => {
                                    prev[index].value = value;
                                    return [...prev];
                                })}
                                mask={`convertToPercentage`} 
                            />
                        </div>
                        <div className="col-1">
                            <Button type={`delete`} svg={`delete-small`} action={() => deleteOption(index)} />
                        </div>
                    </div>
                </div>
            ))}

            <Button 
                type={`link`}
                size={`small`}
                text={`Cadastrar mais opções`}
                full={true}
                action={addOptions}
            />
        </>
    );
}

export default ConditionalPercentageOptions;