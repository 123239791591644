import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createFilterQueryString } from "../../../helpers/helpers";
import { createOptions, createProductOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectProduct = ({selectedProduct, setSelectedProduct, isMulti, changeProducts}) => {
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [noOptionsText, setNoOptionsText] = useState('');

    window.controller = null;

    const minSearch = 1;

    useEffect(() => {
        searchProducts();
    }, [search]);
    
    useEffect(() => {
        checkIfNeedToGet();
    }, [selectedProduct]);

    useEffect(() => {
        if(changeProducts){
            changeProducts(products);
        }
    }, [products]);

    const checkIfNeedToGet = () => {
        let productsIds = isMulti ? selectedProduct : [selectedProduct];
        let term = '';

        productsIds.map(productId => {
            if(productId === null) return;
            if(!products.some(product => product.id === productId)){
                term = productId;
            }
        });

        if(term){
            getProducts(term);
        } else {
            // Para forçar atualização da mensagem "Digite mais x caracteres"
            setSearch('');
        }
    }

    const clearOptions = () => {
        handleSetProducts([], true)
    }

    const searchProducts = () => {
        if(search.length < minSearch) {
            let diff = minSearch - search.length;
            setNoOptionsText(`Digite mais ${diff} ${diff === 1 ? 'caracter' : 'caracteres'}`);
            clearOptions();
            if(window.requestController){
                window.requestController.abort();
            };
            return;
        };

        getProducts(search);        
    }

    const getProducts = (term) => {
        if(window.requestController){
            window.requestController.abort();
        };

        window.requestController = new AbortController();
        
        handleSetProducts([]);
        setNoOptionsText('Carregando...');

        api.get(`/product/search?term=${term}`, {
            signal: window.requestController.signal
        }).then(res => {
            handleSetProducts(res.data.resources.data);
        }).catch(error => {
            if(error.code !== undefined){
                renderError(error);
            }
        });
    }

    const handleSetProducts = (resources, fromClear = false) => {
        let currentSelectedProducts = getSelectedProducts();

        let toProducts = resources.filter(resource => {
            return !currentSelectedProducts.some(current => current.id === resource.id);
        });

        setProducts([...currentSelectedProducts, ...toProducts]);
        if(!fromClear){
            setNoOptionsText(toProducts.length === 0 ? 'Nenhum resultado encontrado' : '');
        }
    }

    const getSelectedProducts = () => {
        return products.filter(product => {
            if(isMulti){
                return selectedProduct.includes(product.id);
            } else {
                return selectedProduct === product.id;
            }
        });
    }

    return (
        <InputSelect
            noOptionsText={noOptionsText}
            label={`Produto`}
            options={createProductOptions(products, 'name', 'id')} 
            value={selectedProduct} 
            change={setSelectedProduct}
            isMulti={isMulti === true}
            setSearch={value => setSearch(value)}
        />
    );
}

export default SelectProduct;