import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonCheckbox from "../ButtonCheckbox";

export const Table = ({header, rows, loading, checks, selectedIds, setSelectedIds}) => {
    const navigate = useNavigate();

    const [allIsChecked, setAllIsChecked] = useState(false);

    useEffect(() => {
        if(rows?.length && rows.length > 0 && checks === true){
            getAllIsChecked();
        }
    }, [rows, selectedIds]);

    const handleOnClick = (event, row) => {
        if(row.action){
            row.action();
            return;
        }

        if(row.to){
            if (
                event.ctrlKey || 
                event.shiftKey || 
                event.metaKey || // apple
                (event.button && event.button == 1) // middle click, >IE9 + everyone else
            ){
                window.open(row.to);
            } else {
                navigate(row.to);
            }
        }
    }

    const handleSelectId = id => {
        let toSelectedIds = [...selectedIds];
        if(selectedIds.includes(id)){
            toSelectedIds = selectedIds.filter(selectedId => selectedId !== id);
        } else {
            toSelectedIds.push(id);
        }

        setSelectedIds(toSelectedIds);
    }

    const handleSelectAllIds = () => {
        let toSelectedIds = [...selectedIds];

        if(allIsChecked){
            toSelectedIds = [];
        } else {
            rows.map(row => {
                if(!selectedIds.includes(row.id)){
                    toSelectedIds.push(row.id);
                }
            });
        }

        setSelectedIds(toSelectedIds);
    }

    const getAllIsChecked = () => {
        let toAllIsChecked = true;
        rows.map(row => {
            if(!selectedIds.includes(row.id)){
                toAllIsChecked = false;
            }
        });

        setAllIsChecked(toAllIsChecked);
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    {checks &&
                        <th style={{ width: 35}}>
                            <ButtonCheckbox 
                                checked={allIsChecked}
                                onClick={handleSelectAllIds}
                            />
                        </th>
                    }
                    {header.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {!loading && rows && rows.map((row, index) => (
                    <tr key={index} className={row?.disable === true ? 'tr-disable' : ''}>
                        {checks &&
                            <td>
                                {row.id !== null &&
                                    <ButtonCheckbox
                                    checked={selectedIds.includes(row.id)}
                                    onClick={() => handleSelectId(row.id)}
                                    />
                                }
                            </td>
                        }
                        {row.data.map((item, index) => (
                            <td key={index} onClick={e => handleOnClick(e, row)} className={row.to || row.action? 'has-link' : ''}>{item}</td>
                        ))}
                        {row.buttons && row.buttons.map((button, index) => (
                            <td className="td-btn" key={index}>{button}</td>
                        ))}
                    </tr>
                ))}
                {!loading && rows && rows.length === 0 &&
                    <tr>
                        <td colSpan={header.length} className="no-data">Nenhum resultado encontrado</td>
                    </tr>
                }
                {loading &&
                    <tr>
                        <td colSpan={header.length} className="no-data">Carregando...</td>
                    </tr>
                }
                
            </tbody>
        </table>
    );
}

export default Table;