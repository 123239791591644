import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import { renderError } from "../../../../helpers/errors";
import moment from "moment";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { getUser } from "../../../../services/auth";
import { ModalDelete } from "../../../../components/ModalDelete";
import InputSelect from "../../../../components/InputSelect";
import InputDate from "../../../../components/InputDate";
import Input from "../../../../components/Input";
import InputTextarea from "../../../../components/InputTextarea";

const typeOptions = [
    {label: "Ligação", value: "call"},
    {label: "Reunião", value: "meeting"},
    {label: "E-mail", value: "email"},
    {label: "Tarefa", value: "task"}
];

const statusOptions = [
    {label: "Pendente", value: "pending"},
    {label: "Concluída", value: "completed"}
];

const taskPendingDesc = {
    call: <><b>Ligar</b> para o contato</>,
    meeting: <><b>Reunião</b> com contato</>,
    email: <><b>E-mail</b> para o contato</>,
    task: <>Realizar <b>tarefa</b></>,
};

const taskCompletedDesc = {
    call: <><strong className="completed">Ligação realizada</strong></>,
    meeting: <><strong className="completed">Reunião realizada</strong></>,
    email: <><strong className="completed">E-mail enviado</strong></>,
    task: <><strong className="completed">Tarefa realizada</strong></>,
};

export const CRMTasks = ({cardId}) => {
    const [tasks, setTasks] = useState([]);
    const [gettingTasks, setGettingTasks] = useState(true);

    const [taskToEdit, setTaskToEdit] = useState(null);
    const [updatingTask, setUpdatingTask] = useState(false);
    const [taskIdToDelete, setTaskIdToDelete] = useState(null);

    const user = getUser();

    useEffect(() => {
        if(cardId){
            getTasks();
        }
    }, [cardId]);

    const getTasks = () => {
        setGettingTasks(true);

        api.get(`/pipeline-stage-card-task?pipeline_stage_card_id=${cardId}`).then(res => {
            setTasks(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingTasks(false));
    }

    const createTask = () => {
        setTaskToEdit({
            id: null,
            pipeline_stage_card_id: cardId,
            type: 'call',
            status: 'pending',
            content: '',
            admin_id: user.id,
            admin_name: user.name,
            admin_email: user.email,
            date: moment().format('YYYY-MM-DD'),
            time: ''
        });
    }

    const updateTask = (updatedTask = null) => {
        updatedTask = updatedTask ?? taskToEdit;
        console.log(updatedTask);

        setUpdatingTask(true);

        api[updatedTask.id ? 'put' : 'post'](`/pipeline-stage-card-task/${updatedTask.id ?? ''}`, updatedTask).then(res => {
            setTaskToEdit(null);
            getTasks();
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdatingTask(false));
    }

    const deleteTask = () => {
        setUpdatingTask(true);

        api.delete(`/pipeline-stage-card-task/${taskIdToDelete}`).then(res => {
            setTaskIdToDelete(null);
            setTaskToEdit(null);
            getTasks();
        }).catch(error => {
            renderError(error)
        }).then(() => setUpdatingTask(false));
    }

    return (
        <>
            
            {taskToEdit !== null &&
                <Modal
                    show={true}
                    title={`${taskToEdit.id ? 'Editar' : 'Criar'} tarefa`}
                    close={() => setTaskToEdit(null)}
                    size={`small`}
                    footer={
                        <>
                            {taskToEdit.id &&
                                <Button
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete-small`}
                                    text={`Excluir`}
                                    action={() => setTaskIdToDelete(taskToEdit.id)}
                                    loading={updatingTask}
                                />
                            }
                            <Button 
                                type={`primary`}
                                size={`small`}
                                text={`Salvar alterações`}
                                svg={`save-white`}
                                action={updateTask}
                                loading={updatingTask}
                            />
                        </>
                    }
                >
                    <div className="section">
                        {taskToEdit.id &&
                            <InputSelect 
                                label={`Status`}
                                value={taskToEdit.status} 
                                options={statusOptions} 
                                change={value => setTaskToEdit(prev => ({...prev, status: value}))} 
                            />
                        }
                        <InputSelect 
                            label={`Tipo da terefa *`}
                            value={taskToEdit.type} 
                            options={typeOptions} 
                            change={value => setTaskToEdit(prev => ({...prev, type: value}))} 
                        />
                        <InputDate 
                            label={`Data *`}
                            value={taskToEdit.date} 
                            change={value => setTaskToEdit(prev => ({...prev, date: value}))} 
                            format={`YYYY-MM-DD`}
                        />
                        <Input 
                            label={`Hora *`} 
                            value={taskToEdit.time}
                            mask={`convertToHour`}
                            change={value => setTaskToEdit(prev => ({...prev, time: value}))} 
                            placeholder={`00:00`}
                        />
                        <InputTextarea 
                            label={`Observações`}
                            value={taskToEdit.content}  
                            change={value => setTaskToEdit(prev => ({...prev, content: value}))} 
                        />
                    </div>
                </Modal>
            }
            <ModalDelete
                show={taskIdToDelete !== null}
                close={() => setTaskIdToDelete(null)}
                action={deleteTask}
                loading={updatingTask}
            />
            <div className="tasks">
                <div className="actions">
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`plus-white`}
                        text={`Tarefa`}
                        action={createTask}
                    />
                </div>
                <div className="list">
                    {gettingTasks &&
                        <p>Buscando informações...</p>
                    }
                    {!gettingTasks && tasks.length === 0 &&
                        <p>Nenhum registro encontrado.</p>
                    }
                    {!gettingTasks && tasks.map(task => (
                        <div key={task.id} className="task">
                            <img className="icon" src={ require(`../../../../assets/images/svgs/task-${task.type}-${task.status}.svg`) } />
                            <div className="details">
                                <div className="header">
                                    <div className="data">
                                        {task.status === 'pending' &&
                                            <span className="desc">{taskPendingDesc[task.type]}</span>
                                        }
                                        {task.status === 'completed' &&
                                            <span className="desc">{taskCompletedDesc[task.type]} por <b>{task.admin_name}</b></span>
                                        }
                                        <span className="date">{moment(task.date).format('DD/MM/YYYY')} às {moment(task.time, 'HH:mm:ss').format('HH:mm')}</span>
                                    </div>
                                    <div className="actions">
                                        <a href="#" onClick={e => {
                                            e.preventDefault();
                                            setTaskIdToDelete(task.id);
                                        }}>Excluir</a>
                                        <a href="#" onClick={e => {
                                            e.preventDefault();
                                            setTaskToEdit(task);
                                        }}>Editar</a>
                                        {task.status === 'pending' &&
                                            <a href="#" className="mark-completed" onClick={e => {
                                                e.preventDefault();
                                                updateTask({
                                                    ...task,
                                                    status: 'completed',
                                                    date: moment().format('YYYY-MM-DD'),
                                                    time: moment().format('HH:mm')
                                                });
                                            }}><img src={ require('../../../../assets/images/svgs/task-completed.svg').default } />Marcar como realizada</a>
                                        }
                                    </div>
                                </div>

                                <div className="content">
                                    <p>{task.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default CRMTasks;