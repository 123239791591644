import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import { convertIntToMoney } from "../../../helpers/conversions";
import { renderError } from "../../../helpers/errors";
import api from "../../../services/api";

const BankSlipTab = ({order}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({rows: [], header:[]});
    const [showCreateBankslip, setShowCreateBankslip] = useState(false);

    useEffect(() => {
        generateData();
    }, [order]);

    const generateData = () => {
        const header = ['Cadastrado em', 'Documento'];
        const rows = [];
        
        order.bankslips.map(bankslip => {
            rows.push({
                data: [
                    moment(bankslip.created_at).format('DD/MM/YYYY HH:mm'),
                    <a href="#">Download</a>
                ],
                action: () => window.open(bankslip.file_url)
            });
        });

        setData({header, rows});
    }

    const storeBankslip = () => {
        let form = new FormData();
        let el = document.getElementById('file');
        let file = el.files[0];

        if(file === undefined){
            alert('Você precisa informar um arquivo');
            return;
        }

        form.append('order_id', order.id);
        form.append('file', file);
        
        api.post(`/order/bankslip`, form, { 
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            window.location.reload();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            <Modal 
                show={showCreateBankslip}
                close={() => setShowCreateBankslip(false)}
                title={`Cadastrar boleto`}
                footer={
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={'Cadastrar'}
                            svg={`save-white`}
                            action={storeBankslip}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <input type="file" id="file" />
                </div>
            </Modal>
            <div className="row">
                <div className="col">
                    <Button 
                        type={`secondary`}
                        size={`small`}
                        text={`Cadastar boleto`}
                        action={() => setShowCreateBankslip(true)}
                    />
                    <br />
                </div>
            </div>
            <Table rows={data.rows} header={data.header} loading={loading} />
        </>
    );
} 

export default BankSlipTab;