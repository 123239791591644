import React from "react";

export const PageHeader = ({left, right}) => {
    return (
        <div className="page-header">
            <div className="left">
                {left}
            </div>
            <div className="right">
                {right}
            </div>
        </div>
    );
}

export default PageHeader;