import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';
import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import InputColor from "../../components/InputColor";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputFile from "../../components/InputFile";
import { toast } from "react-toastify";
import DotColor from "../../components/DotColor";
import { ModalDelete } from "../../components/ModalDelete";
import { hasPermission } from "../../services/auth";

const types = [
    {label: 'Imagem', value: 'image'},
    {label: 'Cor', value: 'color'}
];


export const TagList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [tags, setTags] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateTag, setShowCreateTag] = useState(false);
    const [showEditTag, setShowEditTag] = useState(false);

    const [tagId, setTagId] = useState(null);
    const [tagType, setTagType] = useState('');
    const [tagText, setTagText] = useState('');
    const [tagColor, setTagColor] = useState('');
    const [tagImageBase64, setTagImageBase64] = useState('');
    const [tagImageName, setTagImageName] = useState('');

    const [tagToDelete, setTagToDelete] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getTags();
    }, [filters]);

    useEffect(() => {
        if(!showCreateTag) clean();
    }, [showCreateTag]);

    useEffect(() => {
        if(!showEditTag) clean();
    }, [showEditTag]);

    useEffect(() => {
        generateData();
    }, [tags]);

    const generateData = () => {
        const header = ['Imagem ou cor', 'Texto', 'Cadastrado em', 'Última atualização'];
        const rows = [];

        tags.map(tag => {
            const imageOrColor = tag.type === 'image' ? <img src={tag.image_url.small} /> : <DotColor size={25} color={tag.color} />;
            rows.push({
                data: [
                    imageOrColor, tag.text, moment(tag.created_at).format('DD/MM/YYYY HH:mm'), moment(tag.updated_at).format('DD/MM/YYYY HH:mm')
                ],
                action: () => editTag(tag)
            })
        });

        setData({header, rows});
    }

    const editTag = tag => {
        setTagId(tag.id);
        setTagText(tag.text);
        setTagType(tag.type);
        if(tag.type === 'image'){
            setTagImageName(tag.image);
        }
        if(tag.type === 'color'){
            setTagColor(tag.color);
        }
        setShowEditTag(true);
    }

    const getTags = () => {
        setTags([]);
        setLoading(true);

        api.get(`/tag?${filters}`).then(res => {
            setTags(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const storeTag = () => {
        setLoading(true);

        let data = {
            type: tagType,
            text: tagText
        };

        if(tagType === 'color'){
            data.color = tagColor;
        }
        if(tagType === 'image'){
            data.image = tagImageBase64;
        }

        api.post(`/tag`, data).then(res => {
            toast.success('Selo cadastrado com sucesso');
            setShowCreateTag(false);
            getTags();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateTag = () => {
        setLoading(true);

        let data = {
            type: tagType,
            text: tagText
        };

        if(tagType === 'color'){
            data.color = tagColor;
        }
        if(tagType === 'image' && tagImageBase64){
            data.image = tagImageBase64;
        }

        api.put(`/tag/${tagId}`, data).then(res => {
            toast.success('Selo atualizado com sucesso');
            setShowEditTag(false);
            getTags();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setTagType('');
        setTagText('');
        setTagColor('');
        setTagImageBase64('');
        setTagImageName('');
    }

    const deleteTag = () => {
        setLoading(true);
    
        api.delete(`/tag/${tagToDelete}`).then(res => {
            setTagToDelete(null);
            setShowEditTag(false);
            getTags();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            
            <Modal
                title={`Cadastrar selo`}
                size={`big`}
                show={showCreateTag}
                close={() => setShowCreateTag(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeTag}
                        loading={loading} 
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Texto *`} value={tagText} change={setTagText} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo *`} value={tagType} change={setTagType} options={types} />
                        </div>
                    </div>
                    <div className="row">
                        {tagType === 'image' &&
                            <div className="col-6">
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={tagImageBase64}
                                    changeBase64={setTagImageBase64}
                                    name={tagImageName}
                                    changeName={setTagImageName}
                                    description={`Tamanho ideal: 80px por 80px`}
                                />
                            </div>
                        }
                        {tagType === 'color' &&
                            <div className="col-6">
                                <InputColor label={`Cor`} value={tagColor} change={setTagColor} />
                            </div>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                title={`Editar selo`}
                size={`big`}
                show={showEditTag}
                close={() => setShowEditTag(false)}
                footer={
                    <>
                        {hasPermission('delete_tag') &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setTagToDelete(tagId)}
                                loading={loading} 
                            />
                        }
                        {hasPermission('edit_tag') &&
                            <Button
                                type={`primary`}
                                size={`small`}
                                text={`Salvar alterações`}
                                svg={`save-white`}
                                action={updateTag}
                                loading={loading} 
                            />
                        }
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Texto *`} value={tagText} change={setTagText} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo *`} value={tagType} change={setTagType} options={types} />
                        </div>
                    </div>
                    <div className="row">
                        {tagType === 'image' &&
                            <div className="col-6">
                                <InputFile 
                                    label={`Imagem`} 
                                    base64={tagImageBase64}
                                    changeBase64={setTagImageBase64}
                                    name={tagImageName}
                                    changeName={setTagImageName}
                                    description={`Tamanho ideal: 80px por 80px`}
                                    />
                            </div>
                        }
                        {tagType === 'color' &&
                            <div className="col-6">
                                <InputColor label={`Cor`} value={tagColor} change={setTagColor} />
                            </div>
                        }
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={tagToDelete !== null}
                close={() => setTagToDelete(null)}
                action={deleteTag}
                loading={loading}
            />
            <PanelTemplate id="tag-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Texto', key: '[text]', type: 'text' },
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            {hasPermission('create_tag') &&
                                <Button type={`primary`} size={`small`} text={`Cadastrar selo`} svg="plus-white" action={() => setShowCreateTag(true) }  />
                            }
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading}/>
                </Box>
            </PanelTemplate>
        </>
    );
}

export default TagList;