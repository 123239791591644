import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import { renderError } from "../../../../helpers/errors";
import moment from "moment";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { getUser } from "../../../../services/auth";
import { ModalDelete } from "../../../../components/ModalDelete";
import { Link } from "react-router-dom";

export const CRMTimeline = ({cardId}) => {
    const [activities, setActivities] = useState([]);
    const [gettingActivities, setGettingActivities] = useState(true);

    const [activityToEdit, setActivityToEdit] = useState(null);
    const [updatingActivity, setUpdatingActivity] = useState(false);
    const [activityIdToDelete, setActivityIdToDelete] = useState(null);

    const user = getUser();

    useEffect(() => {
        if(cardId){
            getActivities();
        }
    }, [cardId]);

    const getActivities = () => {
        setGettingActivities(true);

        api.get(`/pipeline-stage-card-activity?pipeline_stage_card_id=${cardId}`).then(res => {
            setActivities(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingActivities(false));
    }

    const createActivity = (type) => {
        setActivityToEdit({
            id: null,
            pipeline_stage_card_id: cardId,
            type: type,
            content: '',
            admin_id: user.id,
            admin_name: user.name,
            admin_email: user.email
        });
    }

    const updateActivity = () => {
        setUpdatingActivity(true);

        api[activityToEdit.id ? 'put' : 'post'](`/pipeline-stage-card-activity/${activityToEdit.id ?? ''}`, activityToEdit).then(res => {
            setActivityToEdit(null);
            getActivities();
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdatingActivity(false));
    }

    const deleteActivity = () => {
        setUpdatingActivity(true);

        api.delete(`/pipeline-stage-card-activity/${activityIdToDelete}`).then(res => {
            setActivityIdToDelete(null);
            setActivityToEdit(null);
            getActivities();
        }).catch(error => {
            renderError(error)
        }).then(() => setUpdatingActivity(false));
    }

    return (
        <>
            
            {activityToEdit?.type === 'comments' &&
                <Modal
                    show={true}
                    title={`${activityToEdit.id ? 'Editar' : 'Criar'} observação`}
                    close={() => setActivityToEdit(null)}
                    size={`small`}
                    footer={
                        <>
                            {activityToEdit.id &&
                                <Button
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete-small`}
                                    text={`Excluir`}
                                    action={() => setActivityIdToDelete(activityToEdit.id)}
                                    loading={updatingActivity}
                                />
                            }
                            <Button 
                                type={`primary`}
                                size={`small`}
                                text={`Salvar alterações`}
                                svg={`save-white`}
                                action={updateActivity}
                                loading={updatingActivity}
                            />
                        </>
                    }
                >
                    <div className="section">
                        <textarea 
                            rows={10} 
                            value={activityToEdit.content} 
                            onChange={e => setActivityToEdit(prev => ({...prev, content: e.target.value}))} 
                            style={{ width: '100%', marginBottom: 15, padding: 15}}
                            autoFocus={true}
                        />
                    </div>
                </Modal>
            }
            <ModalDelete
                show={activityIdToDelete !== null}
                close={() => setActivityIdToDelete(null)}
                action={deleteActivity}
                loading={updatingActivity}
            />
            <div className="timeline">
                <div className="actions">
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`plus-white`}
                        text={`Observação`}
                        action={() => createActivity('comments')}
                    />
                    {/* <Button
                        type={`primary`}
                        size={`small`}
                        svg={`plus-white`}
                        text={`Orçamento`}
                        action={() => null}
                    />
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`plus-white`}
                        text={`Pedido`}
                        action={() => null}
                    /> */}
                </div>
                {/* <input className="search" type="text" placeholder="Buscar no histórico..." /> */}
                <div className="activities">
                    {gettingActivities &&
                        <p>Buscando informações...</p>
                    }
                    {!gettingActivities && activities.length === 0 &&
                        <p>Nenhum registro encontrado.</p>
                    }
                    {!gettingActivities && activities.map(activity => (
                        <div key={activity.id} className="activity">
                            <img className="icon" src={ require(`../../../../assets/images/svgs/activity-${activity.type}.svg`) } />
                            <div className="details">
                                <div className="header">
                                    <div className="data">
                                        {activity.type === 'comments' &&
                                            <span className="desc"><b>{activity.admin_name}</b> escreveu uma observação:</span>
                                        }
                                        {activity.type === 'budget' &&
                                            <span className="desc"><b><Link to={`/quote/edit/${activity.budget_id}`}>Orçamento {activity.budget_code}</Link>  {activity.admin_name}</b> cadastrado</span>
                                        }
                                        <span className="date">{moment(activity.created_at).format('DD/MM/YYYY [às] HH:mm')}</span>
                                    </div>
                                    <div className="actions">
                                        {activity.type === 'comments' &&
                                            <a href="#" onClick={e => {
                                                e.preventDefault();
                                                setActivityIdToDelete(activity.id);
                                            }}>Excluir</a>
                                        }
                                        {activity.type === 'comments' &&
                                            <a href="#" onClick={e => {
                                                e.preventDefault();
                                                setActivityToEdit(activity);
                                            }}>Editar</a>
                                        }
                                    </div>
                                </div>

                                <div className="content">
                                    <p>{activity.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default CRMTimeline;