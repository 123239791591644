import React, { useState, useEffect } from "react";
import DotColor from "../DotColor";
import InputCheckbox from "../InputCheckbox";

const Group = ({group, selecteds, setSelecteds}) => {
    const [active, setActive] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        let items = group.colors.filter(c => selecteds.includes(c.id));
        setCount(items.length);
    }, [selecteds]);
    

    return (
        <li className={active ? 'active' : ''}>
            <button onClick={() => setActive(prev => !prev)}>
                <DotColor color={group.hexadecimal} type={group.type} image={group.image_url?.small} size={20} />
                {group.name}
                {count > 0 &&
                    <span>{count} {count !== 1 ? 'cores' : 'cor'} {count !== 1 ? 'selecionadas' : 'selecionada'}</span>
                }
                <img src={ require('../../assets/images/svgs/arrow-gray.svg').default} />
            </button>
            <ul>
                {group.colors.map((color, index) => (
                    <Color 
                        key={index} 
                        color={color}
                        selecteds={selecteds} 
                        setSelecteds={setSelecteds}
                    />
                ))}
            </ul>
        </li>
    );
}

const Color = ({color, selecteds, setSelecteds}) => {
    const handleChange = () => {
        if(selecteds.includes(color.id)){
            setSelecteds(prev => prev.filter(c => c !== color.id));
        } else {
            setSelecteds(prev => [...prev, color.id]);
        }
    }

    return (
        <li>
            <button onClick={handleChange}>
                <div className="color-desc">
                    <InputCheckbox active={selecteds.includes(color.id)} />
                    <DotColor color={color.hexadecimal} type={color.type} image={color.image_url?.small} size={20} /> 
                    {color.name}
                </div>
                <span className="color-sku">{color.sku}</span>
            </button>
        </li>
    );
}

export const InputColors = ({colorGroups, selecteds, setSelecteds}) => {
    return (
        <div className="input-colors-group">
            <ul>
                {colorGroups.map((group, index) => (
                    <Group 
                        key={index} 
                        group={group} 
                        selecteds={selecteds} 
                        setSelecteds={setSelecteds}
                    />
                ))}
            </ul>
        </div>
    );
}

export default InputColors;