import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import api from "../../../services/api";
import { renderError } from "../../../helpers/errors";
import { toast } from "react-toastify";
import MultipleValueOptions from "./MultipleValueOptions";
import MultiplePercentageOptions from "./MultiplePercentageOptions";
import { ModalDelete } from "../../../components/ModalDelete";
import { createOptions } from "../../../helpers/selects";
import ConditionalPercentageOptions from "./ConditionalPercentageOptions";
import Extra from "./Extra";
import { convertToInt } from "../../../helpers/masks";
import InputTextarea from "../../../components/InputTextarea";
import InputCheckboxUnique from "../../../components/InputCheckboxUnique";

const variableTypeOptions = [
    { label: 'Múltiplos valores', value: 'multiple_value'},
    { label: 'Múltiplos valores com quantidade', value: 'multiple_value_with_quantity'},
    { label: 'Múltiplas porcentagens', value: 'multiple_percentage'},
    { label: 'Porcentagem', value: 'percentage'},
    { label: 'Porcentagem condicional', value: 'conditional_percentage'},
    { label: 'Quantidade e preço', value: 'quantity_and_price'},
    { label: 'Extra', value: 'extra'}
];

const ModalProductVariable = ({close, params, getParams, loading, setLoading, paramToEdit, users, roles}) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [options, setOptions] = useState([]);
    const [position, setPosition] = useState(0);
    const [relationalParam, setRelationalParam] = useState('');
    const [formulaTotalReal, setFormulaTotalReal] = useState('');
    const [formulaTotalQuote, setFormulaTotalQuote] = useState('');
    const [required, setRequired] = useState(false);
    const [value, setValue] = useState('0,0000 %');
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [confirmDeleteParam, setConfirmDeleteParam] = useState(false);

    useEffect(() => {
        getParam();
    }, []);

    useEffect(() => {
        if(paramToEdit === null){
            setOptions([]);
        }
    }, [type]);

    const getParam = () => {
        if(paramToEdit !== null){
            setId(paramToEdit.id);
            setName(paramToEdit.name);
            setPosition(paramToEdit.position);
            setType(paramToEdit.type);
            setOptions(paramToEdit.options);
            setRelationalParam(paramToEdit.param_id ?? '');
            setFormulaTotalReal(paramToEdit.formula_total_real ?? '');
            setFormulaTotalQuote(paramToEdit.formula_total_quote ?? '');
            setRequired(paramToEdit.required);
            setValue(paramToEdit.value ?? '0,0000 %');
            setSelectedRoles(paramToEdit.roles ?? []);
        }
    }

    const storeParam = () => {
        setLoading(true);

        api.post(`/param`, {
            name,
            type,
            options,
            param_id: relationalParam,
            position,
            formula_total_real: formulaTotalReal,
            formula_total_quote: formulaTotalQuote,
            required,
            value: type === 'percentage' ? value : null,
            roles: type === 'percentage' ? selectedRoles : null
        }).then(res => {
            toast.success('Variável cadastrada com sucesso');
            getParams();
            close();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateParam = () => {
        setLoading(true);

        api.put(`/param/${id}`, {
            name,
            type,
            options,
            param_id: relationalParam,
            position,
            formula_total_real: formulaTotalReal,
            formula_total_quote: formulaTotalQuote,
            required,
            value: type === 'percentage' ? value : null,
            roles: type === 'percentage' ? selectedRoles : null
        }).then(res => {
            toast.success('Variável atualizada com sucesso');
            getParams();
            close();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteParam = () => {
        setLoading(true);

        api.delete(`/param/${id}`).then(res => {
            toast.success('Variável excluída com sucesso');
            getParams();
            close();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleChangeRole = roleId => {
        let toSelectedRoles = [];
        if(selectedRoles.includes(roleId)){
            toSelectedRoles = selectedRoles.filter(selectedRole => selectedRole !== roleId);
        } else {
            toSelectedRoles = [...selectedRoles, roleId];
        }
        console.log(toSelectedRoles);
        setSelectedRoles(toSelectedRoles);
    }

    return (
        <>
            {confirmDeleteParam &&
                <ModalDelete
                    show={true}
                    close={() => setConfirmDeleteParam(false)}
                    action={deleteParam}
                    loading={loading}
                />
            }
            {!confirmDeleteParam &&
                <Modal
                    close={close}
                    show={true}
                    title={`${paramToEdit !== null ? 'Editar' : 'Cadastrar'} variável`}
                    footer={
                        <>
                            {paramToEdit !== null &&
                                <Button 
                                    type={`secondary`} 
                                    size={`small`} 
                                    text={`Excluir`} 
                                    svg={`delete-small`} 
                                    action={() => setConfirmDeleteParam(true)}
                                />
                            }
                            <Button 
                                type={`primary`} 
                                size={`small`} 
                                text={paramToEdit !== null ? 'Salvar alterações' : 'Cadastrar'} 
                                svg={`save-white`} 
                                action={() => paramToEdit !== null ? updateParam() : storeParam()}
                                loading={loading}
                            />
                        </>
                    }
                >
                    <div className="section">
                        {type === 'multiple_percentage' &&
                            <div className="row">
                                <div className="col">
                                <InputCheckboxUnique label={`Preenchimento obrigatório`} change={setRequired} checked={required} />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Nome`} value={name} change={setName} />
                            </div>
                            <div className="col-6">
                                <InputSelect label={`Tipo`} isDisabled={paramToEdit !== null} options={variableTypeOptions} value={type} change={setType} />
                            </div>
                        </div>
                        <div className="row">
                            {type === 'conditional_percentage' &&
                                    <div className="col-6">
                                        <InputSelect 
                                            label={`Variável relacional`}
                                            isDisabled={paramToEdit !== null}
                                            options={createOptions(
                                                params.filter(p => p.type === 'multiple_percentage'),
                                                'name',
                                                'id'
                                                )}
                                                value={relationalParam}
                                                change={setRelationalParam} />
                                    </div>
                            }
                            {type === 'percentage' &&
                                <div className="col-6">
                                    <Input type={`text`} label={`Valor padrão`} value={value} mask={`convertToPercentage`} change={v => setValue(v)} />
                                </div>
                            }
                            <div className="col-6">
                                <Input type={`text`} label={`Posição`} value={position} change={value => setPosition( convertToInt(value) )} />
                            </div>
                        </div>
                    </div>

                    {type === 'percentage' &&
                        <>
                            <div className="modal-section-title">
                                <div className="left">
                                    <h2>Quais níveis podem ver e editar</h2>
                                </div>
                            </div>
                            <div className="section">
                                <div className="row">
                                    <div className="col">
                                        {roles.map((role, index) => (
                                            <InputCheckboxUnique 
                                                key={index} 
                                                label={role.name} 
                                                change={() => handleChangeRole(role.id)} 
                                                checked={selectedRoles.includes(role.id)} 
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    
                    

                    {(type === 'multiple_value' || type === 'multiple_value_with_quantity') &&
                        <MultipleValueOptions options={options} setOptions={setOptions} />
                    }

                    {type === 'multiple_percentage' &&
                        <MultiplePercentageOptions users={users} options={options} setOptions={setOptions} />
                    }

                    {type === 'conditional_percentage' &&
                        <ConditionalPercentageOptions 
                            params={params} 
                            relationalParam={relationalParam} 
                            options={options} 
                            setOptions={setOptions}
                        />
                    }

                    {type === 'extra' &&
                        <Extra
                            options={options} 
                            setOptions={setOptions}
                        />
                    }

                </Modal>
            }
        </>
    );
}

export default ModalProductVariable;