import React from "react";
import { convertIntToMoney } from "../../../helpers/conversions";
import { convertToMoney } from "../../../helpers/masks";
import Box from "../../Box";

export const BoxMoney = ({text, value}) => {
    return (
        <Box>
          <div className="box-money">
            <div className="value">
              <span className="currency">R$</span>
              <span className="cash">{convertIntToMoney(value)}</span>
            </div>
            <span className="description">{text}</span>
          </div>
        </Box>
    )
}

export default BoxMoney;