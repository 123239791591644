import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";

const EmailTemplateList = () => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    useEffect(() => {
        getEmailTemplates();
    }, []);

    useEffect(() => {
        generateData();
    }, [emailTemplates]);

    const getEmailTemplates = () => {
        api.get('/email-template').then(res => {
            setEmailTemplates(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Tipo do e-mail', 'Assunto do e-mail'];
        const rows = [];
        
        emailTemplates.map(emailTemplate => {
            rows.push({
                data: [
                    emailTemplate.type === 'budget' ? 'Orçamento' : 'Pedido',
                    emailTemplate.subject
                ],
                to: `/email-template/edit/${emailTemplate.id}`
            })
        });

        setData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="email-template-list">
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default EmailTemplateList;