import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";

const data = {
    header: ['Título', 'Slug'],
    rows: []
}

const PageList = () => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    useEffect(() => {
        getPages();
    }, []);

    useEffect(() => {
        generateData();
    }, [pages]);

    const getPages = () => {
        api.get('/page').then(res => {
            setPages(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Título', 'Slug'];
        const rows = [];
        
        pages.map(page => {
            rows.push({
                data: [
                    page.title,
                    page.slug
                ],
                to: `/page/edit/${page.id}`
            })
        });

        setData({header, rows});
    }

    return (
        <>
            <PanelTemplate id="page-list">
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default PageList;